import React, { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import styles from "./MyProfileNavbar.module.css";
import Footerr from "../Footer";
import Avatar from "../../../../assets/logo.png"; // Default avatar
import MainHeader from "shared/components/MainScreen/Index";
import { FaRegEdit } from "react-icons/fa";
import { IoCameraReverseOutline } from "react-icons/io5";
import ThankyouModel from "shared/components/ThankyouModel";

import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
const MyProfileNavbar: React.FC = () => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [showPasswordFields, setShowPasswordFields] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>(Avatar);
  const [showThankyouModal, setShowThankyouModal] = useState(false);

  const handleEditClick = () => {
    setIsEditable(true);
    setShowPasswordFields(true);
  };

  const handleSaveClick = () => {
    setIsEditable(false);
    setShowPasswordFields(false);
    setShowThankyouModal(true);
  };
  return (
    <div>
      <MainHeader
        mainHeading="My Profile"
        breadcrumb={["Home", "My Profile"]}
      />

      <div className={styles.myProfileNavbarContainer}>
        <div className={styles.profileCard}>
          <div className={styles.profileImageContainer}>
            <img
              src={profileImage}
              alt="Wajahat Khan"
              className={styles.profileImage}
            />
            {isEditable && (
              <>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        setProfileImage(reader.result as string);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  className={styles.fileInput}
                  id="file-input"
                  style={{ display: "none" }}
                />
                <label htmlFor="file-input" className={styles.customFileInput}>
                  <IoCameraReverseOutline className={styles.editIcon} />
                </label>
              </>
            )}
          </div>

          <div className={styles.editContainer}>
            <FaRegEdit className={styles.editIcon} onClick={handleEditClick} />
          </div>

          <div className={styles.profileDetails}>
            <input
              type="text"
              defaultValue="Wajahat Khan"
              className={styles.profileName}
              readOnly={!isEditable}
            />
            <input
              type="text"
              defaultValue="0300 1234567"
              className={styles.profileInfo}
              readOnly={!isEditable}
            />
            <input
              type="mail"
              placeholder="Wajahtkhan@gmail.com"
              className={styles.profileInfo}
              readOnly
            />
            {!isEditable && (
              <div>
                <p>
                  Wants to leave Meditour?{" "}
                  <Link
                    to="/services/deleteAccount"
                    style={{
                      color: "#007bff",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                  >
                    Learn More
                  </Link>
                </p>
                <div
                  className={classNames(commonstyle.flx, commonstyle.flxCenter)}
                  style={{
                    margin: "10px 0",
                  }}
                >
                  <button className={styles.deletbtn}>Delete Account</button>
                </div>
              </div>
            )}
            {isEditable && showPasswordFields && (
              <div className={styles.profileDetails}>
                <input
                  type="password"
                  placeholder="Old Password"
                  className={styles.profileInfo}
                />
                <input
                  type="password"
                  placeholder="New Password"
                  className={styles.profileInfo}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  className={styles.profileInfo}
                />
                <div className={styles.showMoreContainer}>
                  <button
                    onClick={handleSaveClick}
                    className={styles.showMoreButton}
                  >
                    Save
                    {/* <span className={styles.icon}>
                      <IoMdArrowForward />
                    </span> */}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {showThankyouModal && (
        <ThankyouModel
          mainMessageLine1="Your Profile has been"
          mainMessageLine2="Successfully Updated"
          subMessage=""
          footerMessage="Thank you."
        />
      )}

      <Footerr />
    </div>
  );
};

export default MyProfileNavbar;
