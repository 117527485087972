import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../../../assets/logo.png";
import styles from "./BookigHotelDetail.module.css";
import DownloadImagelogo from "../../../../../assets/images/DownloadImagelogo.png";
import LocationIcon from "../../../../../assets/images/LocationIcon12.png";
import Styles from "./InsuranceBokkingDetail.module.css";
import PaymentMyAppointMent from "../PaymentMyAppointMent";
import { ClassNames } from "@emotion/react";
import { FaDownload } from "react-icons/fa6";
import ThankyouModel from "shared/components/ThankyouModel";
interface ItemWithDetail {
  name: string;
  detail?: string;
  link?: string;
}

interface Section {
  section?: string;
  items?: ItemWithDetail[];
  description?: string;
}

const InsuranceBookingDetail = React.memo((props) => {
  const [showModal, setShowModal] = useState(false);

  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const [insuranceDetails] = useState<Section[]>([
    {
      items: [
        { name: "ICU / CCU" },
        {
          name: "Additional Limits for Accidental Emergencies",
          detail: "Rs 10000",
        },
        { name: "Ambulance Service Coverage" },
        {
          name: "Coverage of Specialized Investigations",
          detail: "Covered (Sub limit - Rs. 10,000)",
        },
        { name: "Waiting Period", detail: "2 weeks" },
        { name: "Maternity", detail: "Pay additional Rs 6,188 and get" },
      ],
    },

    {
      section: "More Features",
      description:
        "When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    },
  ]);
  const [insuranceInfo, setInsuranceInfo] = useState(insuranceDetails);
  const handleFileClick = () => {
    setShowModal(true);
  };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Booking
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            My Booking
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Detail
          </p>
        </div>
      </div>

      <div className={classNames(commonstyles.container)}>
        <div className={classNames(commonstyles.flx, commonstyles.flxBetween)}>
          {/* Left Column */}
          <div
            className={classNames(
              commonstyles.col5,
              commonstyles.colsm12,
              Styles.insuranceContainer
            )}
          >
            <div>
              {/* Insurance Heading */}
              <div>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs24,
                    commonstyles.semiBold
                  )}
                >
                  Insurance
                </p>

                {/* Section 1: Medical Benefits */}
                <section>
                  <div className={Styles.leftColumnHeading}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs18,
                        commonstyles.semiBold
                      )}
                    >
                      1- Medical Benefits
                    </p>
                  </div>
                  <ul>
                    <li>ICU / CCU</li>
                    <li>
                      Additional Limits for Accidental Emergencies (Rs 10000)
                    </li>
                    <li>Ambulance Service Coverage</li>
                    <li>
                      Coverage of Specialized Investigations (Covered (Sub limit
                      - Rs. 10,000))
                    </li>
                    <li>Waiting Period (2 weeks)</li>
                    <li>Maternity (Pay additional Rs 6,188 and get)</li>
                  </ul>
                </section>

                {/* Section 2: Policy Documents */}
                <section>
                  <div className={Styles.leftColumnHeading}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs18,
                        commonstyles.semiBold
                      )}
                    >
                      2- Policy Documents
                    </p>
                  </div>
                  <p className={Styles.text}>Download Policy Document</p>
                </section>

                {/* Section 3: More Features */}
                <section>
                  <div className={Styles.leftColumnHeading}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs18,
                        commonstyles.semiBold
                      )}
                    >
                      3- More Features
                    </p>
                  </div>
                  <p className={Styles.text}>
                    When an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release of Letraset sheets
                    containing Lorem Ipsum passages, and more recently with
                    desktop publishing software like Aldus PageMaker including
                    versions of Lorem Ipsum.
                  </p>
                </section>
              </div>
            </div>
          </div>

          {/* Right Column */}

          <div
            className={classNames(
              commonstyles.col5,
              commonstyles.colsm12,
              Styles.insuranceContainer
            )}
          >
            <div className={Styles.policyDetails}>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs16
                )}
              >
                Insurance Duration: 1 year
              </p>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs16
                )}
              >
                Total Amount: 12,504
              </p>

              <div className={Styles.downloadSection}>
                <label>
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16
                    )}
                  >
                    Insurance File
                  </p>
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    fontSize: "14px",
                    color: "#0e54a3",
                    backgroundColor: "#E3EBED",
                    padding: "5px",
                    border: "1px dotted #0e54a3",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleFileClick}
                >
                  <p>file name.pdf</p>
                  <FaDownload />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <ThankyouModel
          mainMessageLine1="Your File has been"
          mainMessageLine2="Successfully Downloaded"
        />
      )}

      <Footerr />
    </div>
  );
});

export default InsuranceBookingDetail;
