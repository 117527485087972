import React from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./DetailStyle.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import Fly from "assets/images/flys.png";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Footerr from "pages/Home/HomeNavBar/Footer";

const flightsData = [
  {
    flightName: "Fly Emirates 104",
    imgSrc: Fly,
    departureCity: "Lahore",
    departureTime: "16:30",
    arrivalCity: "Dubai",
    arrivalTime: "18:30",
    travelTime: "16:00",
    date: "20 Dec, Wed",
    amenities: [
      "Winglets",
      "Basic Web Browsing (Free)",
      "Streaming Entertainment",
      "Light meal",
    ],
    baggageDetails: {
      handbags: 2,
      baggageWeight: "20 KG",
    },
  },
  {
    flightName: "Fly Emirates 104",
    imgSrc: Fly,
    departureCity: "Lahore",
    departureTime: "16:30",
    arrivalCity: "Dubai",
    arrivalTime: "18:30",
    travelTime: "16:00",
    date: "20 Dec, Wed",
    amenities: [
      "Winglets",
      "Basic Web Browsing (Free)",
      "Streaming Entertainment",
      "Light meal",
    ],
    baggageDetails: {
      handbags: 2,
      baggageWeight: "20 KG",
    },
  },
];
const flightCancel = [
  { label: "Cancelation Duration", value: "Before flight 4hr", color: "gray" },
  { label: "Cancelation Deduction", value: "20%", color: "gray" },
  {
    label: "Total Price for Traveler",
    value: "150,000 pkr",
    color: "blue",
    isBold: true,
  },
];
const TravelDetail = () => {
  const Navigate = useNavigate();
  const handleAccept = () => {
    Navigate("/services/myRequest/TravelAccept");
  };

  return (
    <div>
      <MainHeader mainHeading="Request" breadcrumb={["Home", "Request"]} />

      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <p
          className={classNames(
            commonstyle.colorBlue,
            commonstyle.semiBold,
            commonstyle.fs32
          )}
        >
          Ahan Travel & Tour
        </p>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.semiBold,
                commonstyle.fs24
              )}
            >
              Flight Details
            </p>
            <>
              {flightsData.map((flight, index) => (
                <div key={index} className={classNames(style.flightcontainer)}>
                  <div className={classNames(style.imgconatiner)}>
                    <img src={flight.imgSrc} alt="" className={style.flyimg} />
                    <p
                      className={classNames(
                        commonstyle.fs16,
                        commonstyle.colorBlue
                      )}
                    >
                      {flight.flightName}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      commonstyle.flx,
                      commonstyle.flxCenter
                    )}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "90%",
                        position: "relative",
                        color: "#0e54a3",
                      }}
                    >
                      {/* Left City */}
                      <div style={{ marginRight: "10px", fontSize: "18px" }}>
                        <p>{flight.departureCity}</p>
                        <p>{flight.departureTime}</p>
                      </div>

                      {/* Plane Icon and Time */}
                      <div
                        style={{
                          position: "relative",
                          flexGrow: 1,
                          height: "1px",
                          borderTop: "2px dotted #0e54a3",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BiSolidPlaneAlt
                          style={{
                            position: "absolute",
                            top: "-33px",
                            fontSize: "24px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "15px", // Position below the dotted line
                            fontSize: "14px",
                            color: "#0e54a3",
                          }}
                        >
                          {flight.travelTime}
                        </div>
                      </div>

                      {/* Right City */}
                      <div style={{ marginLeft: "10px", fontSize: "18px" }}>
                        <p>{flight.arrivalCity}</p>
                        <p>{flight.arrivalTime}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                        >
                          {flight.departureCity}
                        </p>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs14
                          )}
                        >
                          {flight.date}
                        </p>
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold,
                            commonstyle.flx,
                            commonstyle.flxEnd
                          )}
                        >
                          {flight.arrivalCity}
                        </p>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs14
                          )}
                        >
                          {flight.date}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: "10px 0",
                      }}
                    >
                      <p
                        className={classNames(
                          commonstyle.fs16,
                          commonstyle.colorBlue,
                          commonstyle.semiBold
                        )}
                      >
                        Amenities
                      </p>
                    </div>
                    <div>
                      {flight.amenities.map((amenity, i) => (
                        <p
                          key={i}
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {amenity}
                        </p>
                      ))}
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        No. of Handbag:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight.baggageDetails.handbags}
                        </span>
                      </p>
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        Baggage Weight:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight.baggageDetails.baggageWeight}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </div>
          <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.semiBold,
                commonstyle.fs24
              )}
            >
              Policies
            </p>
            <div className={classNames(style.flightcontainer)}>
              {flightCancel.map((item, index) => (
                <div
                  key={index}
                  className={
                    index === 2
                      ? classNames(
                          commonstyle.flx,
                          commonstyle.flxBetween,
                          commonstyle.flxWrap
                        )
                      : undefined
                  }
                >
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      item.color === "gray"
                        ? commonstyle.colorGray
                        : commonstyle.colorBlue,
                      item.isBold ? commonstyle.semiBold : undefined
                    )}
                  >
                    {item.label}
                  </p>
                  <p
                    className={classNames(
                      commonstyle.fs14,
                      item.color === "gray"
                        ? commonstyle.colorGray
                        : commonstyle.colorBlue,
                      item.isBold ? commonstyle.semiBold : undefined
                    )}
                  >
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
            <div className={classNames(style.btncontainer)}>
              <button className={classNames(style.firstbtn)}>Decline</button>
              <button
                className={classNames(style.secoundbtn)}
                onClick={handleAccept}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footerr/>
    </div>
  );
};

export default TravelDetail;
