import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import styles from "./style.module.css";
import TravelCards from "./TravelCards";
import Footerr from "pages/Home/HomeNavBar/Footer";
import TravelFlight from "./TravelFlight";
import { getUpcomingTours } from "shared/services";

const TravelServices: React.FC = () => {
  const [activeTab, setActiveTab] = useState("tour");
  const [data, setdata] = useState<any>([]);
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (activeTab === "tour") {
      allUpcoming();
    }
  }, [activeTab]);
  const allUpcoming = () => {
    getUpcomingTours(1)
      .then((res: any) => {
        console.log(res?.data);
        setdata(res?.data?.upcomingSchedules);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {});
  };
  return (
    <div>
      <MainHeader
        mainHeading="Travel Agencies"
        breadcrumb={["Home", "Services", "Travel Agencies"]}
      />
      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <div className={styles.tabContainer}>
          <div
            className={classNames(styles.tab, {
              [styles.tabActive]: activeTab === "tour",
              [styles.tabInactive]: activeTab !== "tour",
            })}
            onClick={() => handleTabClick("tour")}
          >
            Tour
          </div>
          <div
            className={classNames(styles.tab, {
              [styles.tabActive]: activeTab === "flight",
              [styles.tabInactive]: activeTab !== "flight",
            })}
            onClick={() => handleTabClick("flight")}
          >
            Flight
          </div>
        </div>

        {activeTab === "tour" ? (
          <TravelCards data={data} />
        ) : (
          <div>
            <TravelFlight />
          </div>
        )}
      </div>
      <Footerr />
    </div>
  );
};

export default TravelServices;
