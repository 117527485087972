import React from 'react'
import hstyle from "./styles.module.css";
import classNames from 'classnames';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import commonstyles from "shared/utils/common.module.css";

import { IoMdArrowForward } from 'react-icons/io';
import { FaRegHeart } from 'react-icons/fa6';
import { FaRegCheckCircle } from 'react-icons/fa';
const BnbHotel = ({ CarImage, name, location, price, cancel, handledetails }: { CarImage?: any, name?: any, location?: any, price?: any, cancel?: any, handledetails?: any }) => {
    return (
        <div>
            <div className={classNames(hstyle.cardWrapper)}>
                <div
                    className={classNames(hstyle.cardContainer)}
                >
                    <div className={classNames(hstyle.cardImageWrapper)}>
                        <img
                            src={CarImage}
                            className={classNames(hstyle.cardImage)}
                        />
                    </div>

                    <div className={classNames(hstyle.cardDetails)}>
                        <div
                            className={classNames(
                                commonstyles.flx,
                                commonstyles.flxBetween
                            )}
                        >
                            <p
                                className={classNames(
                                    commonstyles.colorBlue,
                                    commonstyles.fs24,
                                    commonstyles.semiBold
                                )}
                            >
                                {name}
                            </p>
                            <FaRegHeart
                                className={classNames(
                                    commonstyles.colorBlue,
                                    commonstyles.fs16
                                )}
                            />
                        </div>

                        <span>
                            <div className={classNames(commonstyles.flx)}>
                                <HiOutlineLocationMarker
                                    className={classNames(
                                        commonstyles.colorBlue,
                                        commonstyles.fs16,
                                        commonstyles.semiBold
                                    )}
                                    style={{ margin: "0 10px 0 0" }}
                                />
                                {/* <p
                                    className={classNames(
                                        commonstyles.colorGray,
                                        commonstyles.fs16
                                    )}
                                >
                                </p> */}

                                <p
                            className={classNames(
                                commonstyles.colorBlue,
                                commonstyles.fs16
                            )}
                        >
                            {location}
                        </p>
                            </div>
                        </span>

                      

                        <div
                            className={classNames(
                                commonstyles.flx,
                                commonstyles.flxBetween
                            )}
                        >
                            <div
                                className={classNames(
                                    commonstyles.flx,
                                    commonstyles.flxBetween
                                )}
                                style={{ width: "100%" }}
                            >
                                <p
                                    className={classNames(
                                        commonstyles.colorBlue,
                                        commonstyles.fs15
                                    )}
                                >
                                    Started Price: <strong>{price}</strong>
                                </p>
                                <div className={classNames(commonstyles.flx,commonstyles.flxCenter)}>
                                    <FaRegCheckCircle
                                        className={classNames(
                                            commonstyles.fs16,
                                            commonstyles.semiBold
                                        )}
                                        style={{ color: "#006838" }}
                                    />
                                    <span
                                        style={{ color: "#006838", marginLeft: "5px" }}
                                    >
                                        {cancel}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={hstyle.cardshowMoreContainer}>
                            <button
                                className={hstyle.showMoreButton}
                                onClick={handledetails}
                            >
                                See Availability
                                <span className={hstyle.icon}>
                                    <IoMdArrowForward />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* {visibleCount < cardDataArray.length && ( // Only show button if not all cards are visible
          <div className={hstyle.cardshowMoreContainer}>
            <button
              className={hstyle.showMoreButton}
              onClick={handleShowMoreClick}
            >
              Load more
              <span className={hstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        )} */}
            </div>
        </div>
    )
}

export default BnbHotel