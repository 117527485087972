import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom"; // Import useParams
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./EduStyle.module.css";
import { CiHeart } from "react-icons/ci";
import cardimg from "assets/images/education.png";
import cardlogo from "assets/images/edulogo.png";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import { IoMdHeartEmpty } from "react-icons/io";
import { getPackages } from "shared/services";

const DonationEdu = () => {
  const { state } = useLocation();
  let item = state?.items;
  const [data, setData] = useState<any>([]);
  const [visibleCards, setVisibleCards] = useState(3);
  const [activeCardId, setActiveCardId] = useState<number | null>(1);

  const cardsData = [
    {
      id: 0,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
    {
      id: 1,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
    {
      id: 2,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
    {
      id: 3,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
    {
      id: 4,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
    {
      id: 5,
      title: "The Citizen Foundation",
      cardImage: cardimg,
      cardLogo: cardlogo,
      overlayText: "Educate a Child KG-to-Metric",
      subtitle: "TCF has served 30,000 people for education.",
    },
  ];

  const handleShowMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3);
  };
  const navigate = useNavigate();
  const handleCardClick = (items: any) => {
    // setActiveCardId(id);

    navigate(`/services/donation/DonationPackeg`, { state: { items } });
  };
  useEffect(() => {
    PackagesCompany();
  }, []);
  const PackagesCompany = () => {
    let params = {
      criteriaName: item?.criteriaName,
      page: 2,
    };
    console.log(params, ".parmas");
    getPackages(params)
      .then((res: any) => {
        console.log(res?.data?.packages, ".........res?.data?.packages");
        setData(res?.data?.packages);
      })
      .catch((err: any) => {
        console.log(err, "..........error");
      })
      .finally(() => {});
  };

  return (
    <div>
      {/* Navbar and Header */}
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Donation
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Donation
          </p>
        </div>
      </div>

      {/* Cards Section */}
      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <p className={classNames(dstyle.cardTitle)}>
          {/* {data?.donationTitle} */}
        </p>

        <div className={dstyle.cardContainer}>
          {data.slice(0, visibleCards).map((item: any) => (
            <div className={dstyle.card} onClick={() => handleCardClick(item)}>
              <div className={dstyle.cardTextOverlay}>
                <p className={dstyle.cardOverlayText}>{item.donationTitle}</p>
                <IoMdHeartEmpty className={dstyle.heartIcon} />
              </div>
              <div className={dstyle.cardOverlay}>
                <img
                  src={
                    item?.images?.[0] ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                  }
                  alt="Card"
                  className={dstyle.cardImg}
                />
              </div>
              <div className={dstyle.cardInnerBody}>
                <div className={dstyle.cardLogoTextContainer}>
                  <img
                    src={
                      item?.donationId?.logo ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                    }
                    alt="Logo"
                    className={dstyle.cardLogo}
                  />
                  <div className={dstyle.textarea}>
                    <p className={dstyle.cardTitle}>
                      {" "}
                      {item?.donationId?.name}
                    </p>
                    <p className={dstyle.cardSubtitle}> {item?.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Show More Button */}
        {data.length > visibleCards && (
          <div className={dstyle.showMoreContainer}>
            <button onClick={handleShowMore} className={dstyle.showMoreButton}>
              Show More
              <span className={dstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        )}
      </div>

      {/* Footer */}
      <Footerr />
    </div>
  );
};

export default DonationEdu;
