import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  systemType: "",
  token: null,
  isLoggedIn: false,
  mainSelectedRoute: "/",
  medicalSelectedRoute: "doctor/login",
  homeServiceSelectedRoute: "ambulanceservices/login",
  travelSelectedRoute: "hotel/login",
  cart: [],
  fcmToken: "",
  obj: null,
  hotelDetail: {},
  user: null,
  paidAmount: null,
  paymentParams: null,
  user_id: null,
  hospitalId: null,
  userAge: "",
  location: {
    latitude: null,
    longitude: null,
  },
  doctorFormData: {
    type: "",
    name: "",
    cnicNumber: "",
    cnicImage: "",
    cnicExpiry: "",
    qualification: "",
    speciality: "",
    clinicName: "",
    experience: "",
    pmdcNumber: "",
    pmdcImage: "",
    pmdcExpiry: "",
    address: "",
    lat: "",
    lng: "",
    city: "",
    fbUrl: "",
    instaUrl: "",
    twitterUrl: "",
    webUrl: "",
  },
  hospitalFormData: {
    name: "",
    logo: "",
    registrationNo: "",
    registrationImage: "",
    registrationExpiry: "",
    ownerFirstName: "",
    ownerLastName: "",
    emergencyNumber: "",
    cnicNumber: "",
    cnicImage: "",
    cnicExpiryDate: "",
    address: "",
    lat: "",
    lng: "",
    city: "",
    openTime: "",
    closeTime: "",
    fbUrl: "",
    instaUrl: "",
    twitterUrl: "",
    webUrl: "",
  },

  is_EmailVerified: false,
  is_PhoneVerified: false,
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    setSystemType: (state, action) => {
      state.systemType = action.payload;
    },
    setCart: (state, action) => {
      state.cart = action.payload;
    },
    setFcmToken: (state, action) => {
      state.fcmToken = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setMedicalSelectedRoute: (state, action) => {
      state.medicalSelectedRoute = action.payload;
    },
    setmainSelectedRoute: (state, action) => {
      state.mainSelectedRoute = action.payload;
    },
    setHomeServiceSelectedRoute: (state, action) => {
      state.homeServiceSelectedRoute = action.payload;
    },
    setTravelSelectedRoute: (state, action) => {
      state.travelSelectedRoute = action.payload;
    },
    setDoctorFormData: (state, action) => {
      state.doctorFormData = action.payload;
    },
    setHospitalFormData: (state, action) => {
      state.hospitalFormData = action.payload;
    },
    set_IsEmailVerified: (state, action) => {
      state.is_EmailVerified = action.payload;
    },
    set_IsPhoneVerified: (state, action) => {
      state.is_PhoneVerified = action.payload;
    },
    set_User: (state, action) => {
      state.user = action.payload;
    },
    set_User_ID: (state, action) => {
      state.user_id = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setHotelDetail: (state, action) => {
      state.hotelDetail = action.payload;
    },
    setObj: (state, action) => {
      state.obj = action.payload;
    },
    setPaymentParams: (state, action) => {
      state.paymentParams = action.payload;
    },
    setPaidAmount: (state, action) => {
      state.paidAmount = action.payload;
    },
    setHospitalId: (state, action) => {
      state.hospitalId = action.payload;
    },
    setUserAge: (state, action) => {
      state.userAge = action.payload;
    },
  },
});

export const {
  setFcmToken,
  setSystemType,
  setToken,
  setIsLoggedIn,
  setMedicalSelectedRoute,
  setmainSelectedRoute,
  setHomeServiceSelectedRoute,
  setTravelSelectedRoute,
  setDoctorFormData,
  setHospitalFormData,
  set_IsEmailVerified,
  setCart,
  set_IsPhoneVerified,
  set_User,
  set_User_ID,
  setLocation,
  setObj,
  setPaymentParams,
  setPaidAmount,
  setHospitalId,
  setHotelDetail,
  setUserAge,
} = commonReducer.actions;

export default commonReducer.reducer;
