import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CardStyles from "./Cards.module.css";
import CommonStyles from "shared/utils/common.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import { CiShoppingCart } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { getMedicineNear } from "shared/services";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "shared/redux";
import toast from "react-hot-toast";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { RingLoader } from "shared/components";
const PharmacyCards = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [quantities, setQuantities] = useState<any>(1);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState<any>('');
  const [refreshing, setRefreshing] = useState(false);
  const [count, setCount] = useState(0);
  const { cart } = useSelector((state: any) => state.root.common);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [medicineData, setMedicineData] = useState<any>(null);



  const handleIncrement = () => {
    setQuantities(quantities + 1,);
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (quantities > 0) {
      setQuantities(quantities - 1,);
      setCount(count - 1);
    }
  };


  const openModal = (i: any) => {
    setMedicineData(i);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    console.log("Closing modal");

  };
  console.log(cart, '.......cartOpen');
  const handleSelect = () => {
    if (!medicineData) return;

    const quantity = quantities;
    const updatedCart = Array.isArray(cart) ? [...cart] : []; 
    const existingIndex = updatedCart.findIndex(
      (item) => item._id === medicineData._id
    );

    if (existingIndex !== -1) {
      // Create a new object with the updated quantity
      updatedCart[existingIndex] = {
        ...updatedCart[existingIndex],
        quantity: updatedCart[existingIndex].quantity + quantity,
      };
    } else {
      updatedCart.push({ ...medicineData, quantity });
    }

    dispatch(setCart(updatedCart));
    toast.success("Added to cart successfully!");
    closeModal();
  };



  useEffect(() => {
    getMedicine()
  }, [])
  const getMedicine = (keyword = '') => {
    setLoading(true);
    const params = { keyword };
    getMedicineNear(params)
      .then((res: any) => {
        setData(res?.data?.products || []);
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  const debouncedSearch = _.debounce((value: string) => {
    getMedicine(value);
  }, 500);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };
  return (
    <>
      <div className={classNames(CommonStyles.mb28, CommonStyles.mt28)}>
        <div
          className={classNames(CommonStyles.container)}
          style={{ backgroundColor: "#FDFDFD" }}
        >
          {/* Tabs Section */}
          <div
            className={classNames(
              CommonStyles.flx,
              CommonStyles.flxWrap,
              CommonStyles.flxBetween,
              CardStyles.main
            )}
          >
            <div
              className={classNames(
                CardStyles.tabContainer,
                CommonStyles.flx,
                CommonStyles.col6,
                CommonStyles.colsm12
              )}
            >

            </div>
            <div
              className={classNames(
                CardStyles.searchBarContainer,
                CommonStyles.col6,
                CommonStyles.colsm12
              )}
            >
              <IoSearchSharp className={CardStyles.searchIcon} />
              <input
                type="text"
                placeholder="Search..."
                className={CardStyles.searchInput}
                value={searchText}
                onChange={handleSearchChange}
              />
            </div>
          </div>    
          {data && data.length > 0 ? (<div className={CardStyles.cardContainer}>
            {data.map((card: any) => (
              <div className={CardStyles.cardWrapper}>
                <div className={CardStyles.cardBody} onClick={
                  () => openModal(card)
                  // openModal
                }>
                  {" "}
                  {/* Open modal on click */}
                  <p
                    className={classNames(
                      CommonStyles.colorBlue,
                      CommonStyles.fs16,
                      CommonStyles.semiBold
                    )}
                  >
                    {card.productName}
                  </p>
                  <div className={CardStyles.cardDetails}>
                    <div>{card.brand}</div>
                    <div>{card.strength}</div>
                  </div>
                  <div className={CardStyles.cardDetails}>
                    <div>{card.tpPrice}/-</div>

                  </div>
                </div>
              </div>
            ))}

          </div>) : (
            <div>{!loading && <PhysiotheristsEmpty />}</div>
          )}
        </div>
        {loading && (
          <div className={CommonStyles.loader}>
            <RingLoader color={"#0D47A1"} size={30} />
          </div>)}
        {/* Modal */}
        {isModalOpen && (
          <div className={CardStyles.modal} onClick={closeModal}>
            <div
              className={CardStyles.modalContent}
              onClick={(e) => e.stopPropagation()}

            >
              <p className={CardStyles.viewCardTitle}>{medicineData?.productName}</p>
              <div
                className={classNames(
                  CommonStyles.flx,
                  CommonStyles.flxBetween,
                  CommonStyles.fs12,
                  CommonStyles.colorGeay
                )}
              >
                <p className={CardStyles.viewCardBrand}>{medicineData?.brand}</p>
                <p className={CardStyles.viewCardSize}>{medicineData?.strength}</p>
              </div>
              <div
                className={classNames(
                  CommonStyles.flx,
                  CommonStyles.flxBetween,
                  CommonStyles.fs12
                )}
              >
                <p className={CardStyles.viewCardDosage}>{medicineData?.tpPrice}</p>
              </div>

              <div
                className={classNames(
                  CommonStyles.flx,
                  CommonStyles.flxBetween,
                  CommonStyles.fs16,
                  CommonStyles.colorBlue,
                  CardStyles.viewCardQuantity
                )}
              >
                <p className={CardStyles.viewCardQuantityTitle}> Quantity</p>
                <div className={classNames(CardStyles.viewQuantitybox)}>
                  <button
                    className={CardStyles.decrementButton}
                    onClick={handleDecrement}
                  >
                    -
                  </button>
                  <span className={CardStyles.quantity}>{quantities}</span>
                  <button
                    className={CardStyles.incrementButton}
                    onClick={handleIncrement}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className={CardStyles.viewCardButtons}>
                <button
                  className={CardStyles.viewCancelButton}
                  onClick={closeModal}
                >
                  CANCEL
                </button>
                <button
                  className={CardStyles.viewSelectButton}
                  onClick={handleSelect}
                >
                  SELECT
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PharmacyCards;
