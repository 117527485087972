import React, { useState } from "react";
import classNames from "classnames";
import CommonStyles from "shared/utils/common.module.css";
import LoginStyles from "./Uselogin.module.css";
import { CustomInput } from "shared/components";
import { PrimaryButton } from "shared/components";
import Logo from "assets/images/UserForget.png";
import { useNavigate } from "react-router-dom";
import VerifyModal from "shared/components/VerifyModal"; // Adjust the import path as needed

const Forget_Password = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    // Update the URL with a "?" at the end
    const currentUrl = window.location.href;
    const newUrl = `${currentUrl}?`;
    window.history.pushState({}, "", newUrl);

    // Show the modal when the "Next" button is clicked
    setShowModal(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(false);
      navigate("/reset-password"); // Navigate to the reset password page
    }, 2000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSendCodeToEmail = () => {
    console.log("Resend code to email");
  };

  return (
    <div
      className={classNames(
        LoginStyles.paretns,
        CommonStyles.flx,
        CommonStyles.flxWrap
      )}
    >
      <div
        className={classNames(
          LoginStyles.imgBackgrond,
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12
        )}
      >
        <div className={classNames(LoginStyles.centerContent)}>
          <img src={Logo} alt="Logo" className={LoginStyles.logoImage} />
        </div>
      </div>
      <div
        className={classNames(
          CommonStyles.col6,
          CommonStyles.colmd12,
          CommonStyles.colsm12,
          LoginStyles.centerContent
        )}
      >
        <form className={LoginStyles.loginFormContainer}>
          <p
            className={classNames(
              CommonStyles.fs28,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Forgot
          </p>
          <p
            className={classNames(
              CommonStyles.fs16,
              CommonStyles.semiBold,
              CommonStyles.colorBlue
            )}
          >
            Please enter your email to receive a verification code
          </p>

          <div className={CommonStyles.mt14}>
            <CustomInput
              placeholder="Please enter Email"
              id="Email"
              name="Email"
              type="text"
            />
          </div>

          <div className={classNames(CommonStyles.mt24)}>
            <PrimaryButton
              type="button" // Ensure the button is not a submit button
              children={"Next"}
              colorType={"blue"}
              onClick={handleNext} // Handle the "Next" button click
            />
          </div>
          <div
            className={classNames(
              CommonStyles.mt24,
              CommonStyles.flx,
              CommonStyles.flxBetween
            )}
          >
            <p className={classNames(CommonStyles.regular, CommonStyles.fs14)}>
              Already signed up?
            </p>
            <a
              className={classNames(
                CommonStyles.colorBlue,
                CommonStyles.fs16,
                CommonStyles.Bold,
                LoginStyles.cursor
              )}
              onClick={() => navigate("/login")}
            >
              Login
            </a>{" "}
          </div>
        </form>
      </div>

      <VerifyModal
        showModal={showModal}
        hanldeCloseModal={handleCloseModal}
        handleSubmit={handleSubmit}
        code={code}
        setCode={setCode}
        codeError={codeError}
        setCodeError={setCodeError}
        loading={loading}
        minutes={2}
        seconds={0}
        handleSendCodeToEmail={handleSendCodeToEmail}
        successMessage={""}
      />
    </div>
  );
};

export default Forget_Password;
