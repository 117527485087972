import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "../../Footer";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import Ambulance from "./AmbulanceRequest/Ambulance";
import TravelRequest from "./TravelandTour";
import img from "assets/images/nothing.png";
import { getAllFlights, getUserAmbulanceReq } from "shared/services";

const RequestAllow = () => {
  const [activeTab, setActiveTab] = useState<string>("Flight");
  const [isTabClicked, setIsTabClicked] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    setIsTabClicked(true);
  };
  // console.log("Active Tab:", activeTab);

  useEffect(() => {
    if (activeTab == "Ambulance") {
      getRequestUser();
    } else {
      flightsRequest();
    }
  }, [activeTab]);

  const getRequestUser = () => {
    let data = {
      page: 1,
    };
    getUserAmbulanceReq(data)
      .then((res: any) => {
        // console.log("Ambulance Request:", res?.data);
        setData(res.data.userRequests);
      })

      .catch((err: any) => {
        console.log(err, ".........err");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const flightsRequest = () => {
    let data = {
      page: 1,
    };
    getAllFlights(data)
      .then((res: any) => {
        const newFlightData = res.data.flightRequests || [];
        // console.log(".......newFlightData", newFlightData);
        setData(res.data.flightRequests);
      })
      .catch((err: any) => {
        console.log(err, "..........error from flights");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <MainHeader mainHeading="Request" breadcrumb={["Home", "Request"]} />
      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <DoubleButton
          tab1Label="Flight"
          tab2Label="Ambulance"
          onTabChange={handleTabChange}
        />

        {/* Conditionally render the content based on whether a tab has been clicked */}
        {isTabClicked ? (
          activeTab === "Ambulance" ? (
            <Ambulance data={data} />
          ) : (
            <TravelRequest data={data} />
          )
        ) : (
          // Show this message until a button is clicked
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxCenter,
              commonstyle.mt16
            )}
          >
            <div className={classNames()}>
              <img src={img} alt="No request" />
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs16,
                  commonstyle.semiBold
                )}
              >
                You haven't requested anything yet for flight
              </p>
            </div>
          </div>
        )}
      </div>

      <Footerr />
    </div>
  );
};

export default RequestAllow;
