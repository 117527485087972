import React, { useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { FaBath, FaBeerMugEmpty, FaRegHeart, FaTv } from "react-icons/fa6";
import { TbAirConditioning } from "react-icons/tb";
import { MdOutlineBalcony } from "react-icons/md";
// import hstyle from "./Hotel.module.css";
import { LiaHotTubSolid } from "react-icons/lia";
import hstyle from "./Hotel.module.css";
import astyle from "./AvabilityStyle.module.css";
import ViewModel from "shared/components/ViewModel/ViewModel";
import { log } from "console";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RoomComponent } from "shared/components";
import ContinueButton from "shared/components/ContinueButton";

const icons = [
  { icon: <TbAirConditioning />, name: "Inverter AC" },
  { icon: <FaBath />, name: "BathTub" },
  { icon: <FaTv />, name: " TV" },
  { icon: <LiaHotTubSolid />, name: "Spa tub" },
  { icon: <FaBeerMugEmpty />, name: " Kettle" },
  { icon: <MdOutlineBalcony />, name: "Gym" },
];




const HotelAvability = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState<any>("");
  const [selectedRooms, setSelectedRooms] = useState<any>([]);
  const { hotelDetail } = useSelector((state: any) => state.root.common)
  const navigate = useNavigate();
  const [error, setError] = useState<any>('')
  const { state } = useLocation()
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const item = state?.data
  // console.log("get Items", items);
  console.log(hotelDetail?.selectedOption, '....hotelDetail?.selectedOption ')

  const handleShowModal = (roomId: any) => {
    setSelectedRoomId(roomId);
    setShowModal(true);
  };
  const calculateTotalPrice = () => {
    return selectedRooms.reduce((total: any, room: any) => {
      const roomData =
        item.rooms?.find((r: any) => r._id === room.id) ||
        item.apartments?.find((r: any) => r._id === room.id);
      const roomPrice =
        roomData?.pricePerNight ||
        roomData?.basePricePerNight ||
        item?.basePricePerNight ||
        0;
      return total + roomPrice * room.quantity;
    }, 0);
  };
  const handleSelectRoom = (roomId: any, quantity: any) => {
    const updatedRooms: any = [...selectedRooms];
    const roomIndex = updatedRooms.findIndex((room: any) => room.id === roomId);

    if (roomIndex >= 0) {
      updatedRooms[roomIndex].quantity = quantity;
    } else {
      updatedRooms.push({ id: roomId, quantity });
    }
    setSelectedRooms(updatedRooms);
  };
  const totalPrice = calculateTotalPrice();
  console.log("selectedCard....", selectedCard);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handlePayClick = () => {

    setShowModal(false);
  };

  const createSelectedRoomsObject = () => {
    return selectedRooms.map((room: any) => ({
      id: room.id,
      quantity: room.quantity,
    }));
  };

  console.log("selectedCard...", selectedCard);
  const bookNow = () => {
    if (totalPrice === 0) {
      setError("Please choose and customize a room.");
    } else {
      navigate("/services/hotel/HotelBooking", {
        state: {
          item: item,
          totalPrice: totalPrice,
          Type: hotelDetail?.selectedOption,
          selectedRooms: createSelectedRoomsObject(),
          roomValue: selectedRooms,
          actualAmount: totalPrice,
        }
      });

    }
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Hotel
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Hotel
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Details
          </p>
        </div>
      </div>
      <div className={classNames(classNames(commonstyles.container))}>
        <p
          className={classNames(
            commonstyles.fs24,
            commonstyles.colorBlue,
            commonstyles.semiBold
          )}
        >
          Park Lane Hotel Lahore
        </p>
        {hotelDetail?.selectedOption === 'Hotel' ? (
          item?.rooms?.map((room: any, index: any) => (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <RoomComponent
                name={room?.roomName}
                item={item}
                price={`PKR ${room?.pricePerNight}`}
                value1={`No Of Bed: ${room?.noOfBeds}`}
                rooms={`Home Type: ${room?.roomType}`}
                size={`Size ${room?.roomSize}`}
                dinningRoom={`Discription: ${room?.roomDescription}`}
                onClick={() => handleShowModal(room._id)}

              />
            </div>))) : hotelDetail?.selectedOption === 'Home'? (
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            <RoomComponent
              name={item?.homeName}
              price={`PKR ${item?.basePricePerNight}`}
              value1={`No Of Bed: ${item?.numberOfBedroom}`}
              item={item}
              rooms={`Home Type: ${item?.homeType}`}
              size={`Size ${item?.homeSize}`}
              dinningRoom={`Number Of DiningRooms: ${item?.numberOfDiningrooms}`}
              onClick={() => handleShowModal(item._id)}
            />
          </div>
        ):(
          item?.rooms?.map((room: any, index: any) => (
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
              <RoomComponent
                name={room?.appartmentName}
                item={item}
                price={`PKR ${room?.basePricePerNight}`}
                value1={`No Of Bed: ${room?.numberOfBedroom}`}
                rooms={`Apartment No :${room?.appartmentNo} `}
                size={`Size ${room?.appartmentSize}`}
                dinningRoom={`Bathroom: ${room?.numberOfBathroom}`}
                onClick={() => handleShowModal(item._id)}

              />
            </div>))
        )}
        <span style={{ fontSize: 14, fontWeight: '500', }}>
          PKR {totalPrice}{' '}
          <span style={{ fontSize: 14, fontWeight: '500', }}>
            {selectedRooms.length === 0
              ? '0 room'
              : selectedRooms.reduce(
                (total: any, room: any) => total + room.quantity,
                0,
              ) > 0
                ? `${selectedRooms.reduce(
                  (total: any, room: any) => total + room.quantity,
                  0,
                )} rooms`
                : selectedRooms.map((item: any) => item?.quantity)}
          </span>
        </span>
        {error && <p style={{ color: "red" }}>{error}</p>}
        <ContinueButton buttonText="BookNow" onClick={bookNow} />
      </div>
      {showModal && (
        <ViewModel
          subtitle="Number of rooms"
          buttonText="Choose"
          onButtonClick={handlePayClick}
          handleCloseModal={handleCloseModal}
          selectedRooms={selectedRooms}
          totalPrice={totalPrice}
          item={
            item.rooms?.find((room: any) => room._id === selectedRoomId) ||
            item.apartments?.find((room: any) => room._id === selectedRoomId) ||
            item
          }
          setSelectedRooms={handleSelectRoom}
        />
      )}
    </div>
  );
};

export default HotelAvability;
