import { ENDPOINTS, HTTP_CLIENT } from "shared/utils";

export const user_login = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGIN, params);
};

export const getAll_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_DOCTOR}?doctorType=` +
      data?.doctorType +
      "&page=" +
      data.page +
      "&lat=" +
      data?.lat +
      "&long=" +
      data?.long +
      "&search=" +
      data?.search +
      "&city=" +
      data?.city +
      "&speciality=" +
      data?.speciality +
      "&filter=" +
      data?.filter
  );
};

export const getSignle_Doctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_DOCTOR}?doctorId=` +
      data.doctorId +
      "&type=" +
      data.type
    // data.type +
    // '&doctorType=' +
  );
};

export const getAvailability_Doctors = (data: any) => {
  // console.log(
  //   `${ENDPOINTS.GET_AVAILABILITY_DOCTOR}?doctorId=` +
  //     data.doctorId +
  //     "&type=" +
  //     data.type +
  //     "&hospitalId=" +
  //     data.hospitalId
  // );

  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_AVAILABILITY_DOCTOR}?doctorId=` +
      data.doctorId +
      "&type=" +
      data.type +
      "&hospitalId=" +
      data.hospitalId
  );
};
export const getUpcoming_Doctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.UPCOMING_APPOINTMENT}?page=` + data?.page
  );
};

export const GetAll_Records = (data?: any) => {
  // console.log(
  //   `${ENDPOINTS.GET_ALL_RECORDS}?page=` + data?.page,
  //   ".........endpints"
  // );

  return HTTP_CLIENT.get(`${ENDPOINTS.GET_ALL_RECORDS}?page=` + data?.page);
};

export const getMedicineNear = (data?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SEARCHDOCTOR_KEYWORD}?keyword=` + data?.keyword
  );
};

export const getAll_RentACar = (data: any, pagenoo: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_RENT_A_CAR}?page=${pagenoo}` +
      "&lat=" +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&name=" +
      data.name +
      "&search=" +
      data?.search
  );
};
export const getAll_CarsDetails = (data: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_CARS_DETAILS}?id=` + data.id);
};
export const getSingle_CarDetail = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_SINGLE_CARS_DETAILS}?vehicleId=` + data.vehicleId
  );
};
export const GetAllRentACarReview_Rating = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_RENT_A_CAR_RATINGS_REVIEWS}?vendorId=` +
      data?.vendorId,
    data
  );
};
export const flightsRequest = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_ALL_FLIGHTS_REQUEST, data);
};
export const getUpcomingTours = (page: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.ALL_UPCOMING_TOURS}?page=` + page);
};
export const userLogoutPerson = () => {
  console.log(ENDPOINTS.USER_LOGOUT, "..........ENDPOINTS.USER_LOGOUT");
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGOUT);
};
export const getDonation_Companies = () => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_COMPANIES}`);
};
export const getDonation_Package = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PACKAGE_DETAILS}?packageId=` + data?.packageId
  );
};
export const getPackages = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PACKAGES_DONATION}?criteriaName=` +
      data?.criteriaName +
      "&params" +
      data?.page
  );
};
export const getRecentDonor = (data: any) => {
  return HTTP_CLIENT.get(`${ENDPOINTS.GET_RECENT_DONORS}?page=` + data?.page);
};
export const getPeople_Donation = () => {
  return HTTP_CLIENT.get(ENDPOINTS.GET_NEED_PEOPLE);
};
export const get_Hospital_Price = (id: any, doc_Id?: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_HOSPITAL_PRICE}?hospitalId=${id}` + "&doctorId=" + doc_Id
  );
};

export const getAll_Hospitals = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_HOSPITAL}?lat=` +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&search=" +
      data.search
  );
};

export const getSingle_UserHospital = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_HOSPITAL}?hospitalId=` + data.hospitalId
  );
};

export const getHospital_Departments = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_DEPARTMENTS}?hospitalId=` + data.hospitalId
  );
};

export const getHospital_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_HOSPITAL_DOCTORS}?hospitalId=` + data.hospitalId
  );
};

export const getDepartment_Doctors = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_DEPARTMENT_DOCTORS}?departmentId=` + data.departmentId
  );
};
export const add_Appointment_Doctors = (data: any, id: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_BOOK_APPOINTMENT}?doctorId=${id}`,
    data
    // {appointmentType: data?.appointmentType, hospital: data?.hospital},
  );
};

export const remaining_Appointment_Doctors = (body: any) => {
  // console.log(
  //   ENDPOINTS.REMAIN_DOCTOR_PAYMENT,
  //   body,
  //   "........ENDPOINTS.REMAIN_DOCTOR_PAYMENT, body"
  // );
  return HTTP_CLIENT.put(ENDPOINTS.REMAIN_DOCTOR_PAYMENT, body);
};

export const Preception_Details = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_PATIENT_DATA}?appointmentId=` + data?.appointmentId
  );
};

export const getReferralDoctor = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_REFERRAL}?appointmentId=` + data?.appointmentId
  );
};

export const postDonationAmount = (body: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_DONATION}?packageId=${params.packageId}&companyId=${params.companyId}`,
    body
  );
};
export const addPharmacyOrder = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_MEDICINE_ORDER, data);
};
export const bookingTours = (params?: any, body?: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.ADD_TOUR_BOOKING}?tourId=` + params?.tourId,
    body
  );
};

export const add_File = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_ADD_FILE, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
};

export const saveUploadResulsUser = (id: any, params: any) => {
  return HTTP_CLIENT.put(
    `${ENDPOINTS.SAVE_RESULTS}?appointmentId=${id}`,
    params
  );
};

export const confirmInsurance = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CONFIRM_INSURANCE, data);
};
// .........................User-Laboratory Flow.........................../
export const getUser_Laboratory = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.USER_LABORATORY}?lat=` +
      data.lat +
      "&long=" +
      data.long +
      "&page=" +
      data.page +
      "&search=" +
      data.search
  );
};

export const getSingle_UserLaboratory = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.SINGLE_USER_LABORATORY}?labId=` +
      data.labId +
      "&lat=" +
      data.lat +
      "&long=" +
      data.long
  );
};

export const getAll_AdminTestCategories = () => {
  return HTTP_CLIENT.get(ENDPOINTS.ADMIN_TEST_CATEGORIES);
};

export const getAllOrders_History = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_ALL_ORDER_HISTORY}?page=` +
      data.page +
      "&type=" +
      data?.type
  );
};

export const getAll_Tests = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.ALL_TESTS}?categoryName=` +
      data.categoryName +
      "&labId=" +
      data?.labId
  );
};

export const confirm_Booking = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CONFIRM_BOOKING, data);
};
export const addAllSearchHotel = (parmas: any, data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.SEARCH_HOTEL_FILTER}?page=` + parmas?.page,
    data
  );
};
export const addBookingRoom = (data: any) => {
  // console.log(
  //   '.......ENDPOINTS.BOOKING_ROOM_DETAILS, data',
  //   ENDPOINTS.BOOKING_ROOM_DETAILS,
  //   data,
  // );

  return HTTP_CLIENT.post(ENDPOINTS.BOOKING_ROOM_DETAILS, data);
};

export const DetailsInfoHotel = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.DETAILS_INFO_HOTEL}?id=` +
      data.id +
      "&serviceType=" +
      data.serviceType
  );
};

export const getAllFlights = (data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.GET_ALL_FLIGHTS_REQUEST}?page=` + data?.page
  );
};

export const postInsuranceFlight = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.POST_TRAVEL_INSURANCE}?page=` + data?.page,
    params
  );
};
export const postInsuranceFamily = (data: any, params: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.INSURANCE_FAMILY}?page=` + data.page,
    params
  );
};
export const getInsuranceDetails = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_INSURANCE_DETAILS}?insuranceId=` +
      data.insuranceId +
      "&type=" +
      data.type
  );
};
// .........................User-Ambulance Flow.........................../

export const Ambulance_Flow = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.ADD_AMBULANCE_PUSH, data);
};
export const postDetails = (data: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.POST_RENTACAR_DETAIL, data);
};

export const getUserAmbulanceReq = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.GET_USER_AMBULANCE_REQUEST}?page=` + data?.page
  );
};

export const bidReqGet = (data: any) => {
  return HTTP_CLIENT.get(
    `${ENDPOINTS.FETCH_BID_REQUEST}?requestId=` + data?.requestId
  );
};

export const deleteRequest = (data: any) => {
  return HTTP_CLIENT.delete(
    `${ENDPOINTS.DELETE_USER_REQUEST}?requestId=` + data?.requestId
  );
};

export const acceptAmbulanceReq = (id: any, body?: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.ACCEPT_BID_REQUEST}?bidRequestId=` + id,
    body
  );
};
export const declineAmbulance = (data: any) => {
  return HTTP_CLIENT.post(
    `${ENDPOINTS.DECLINE_REQUEST}?requestId=` + data?.requestId
  );
};

export const logoutAll = () => {
  return HTTP_CLIENT.post(ENDPOINTS.USER_LOGOUT);
};

export const creatPaymentIntent = (params: any) => {
  return HTTP_CLIENT.post(ENDPOINTS.CREATE_PAYMENT_INTENT, params);
};
