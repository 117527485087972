import React, { useEffect, useState } from "react";
import classNames from "classnames";
import CommonStyles from "shared/utils/common.module.css";
import LoginStyles from "./Uselogin.module.css";
import { CustomInput } from "shared/components";
import { PrimaryButton } from "shared/components";
import Logo from "assets/images/Frame.png";
import VerifyModal from "shared/components/VerifyModal";
import { FaChevronLeft } from "react-icons/fa6";

const Email_Verify = () => {
  const [showModal, setShowModal] = useState(false);
  const [code, setCode] = useState("");
  const [codeError, setCodeError] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleVerifyClick = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = () => {
    setLoading(true);

    // Simulate a verification process
    setTimeout(() => {
      setSuccessMessage("Verification Successful!");
      setLoading(false);
      setShowModal(false);
    }, 5000); // Simulating an API call
  };

  const handleSendCodeToEmail = () => {
    setMinutes(2);
    setSeconds(0);
  };

  return (
    <>
      <div
        className={classNames(
          LoginStyles.paretns,
          CommonStyles.flx,
          CommonStyles.flxWrap
        )}
      >
        {!isMobile && (
          <>
            {" "}
            <div
              className={classNames(
                CommonStyles.col6,
                CommonStyles.colmd12,
                CommonStyles.colsm12,
                LoginStyles.centerContent
              )}
            >
              <form
                className={LoginStyles.loginFormContainer}
                style={{
                  marginTop: "10%",
                  marginBottom: "10%",
                }}
              >
                <div
                  className={classNames(CommonStyles.flx)}
                  onClick={() => {}}
                >
                  <FaChevronLeft className={LoginStyles.BackIcon} />
                  <p
                    className={classNames(
                      CommonStyles.fs16,
                      // CommonStyles.semiBold,
                      CommonStyles.colorBlue
                    )}
                  >
                    Back
                  </p>
                </div>
                <p
                  className={classNames(
                    CommonStyles.fs28,
                    CommonStyles.semiBold,
                    CommonStyles.colorBlue
                  )}
                  style={{ marginTop: "32px" }}
                >
                  Email verification
                </p>
                <p
                  className={classNames(
                    CommonStyles.fs16,
                    CommonStyles.semiBold,
                    CommonStyles.colorBlue
                  )}
                >
                  Your Journey Begins Here
                </p>
                <div className={CommonStyles.mt24}>
                  <CustomInput
                    placeholder="Please enter Email"
                    id="Email"
                    name="Email"
                    type="text"
                  />
                </div>
                <div className={CommonStyles.mt16}>
                  <div className={classNames(CommonStyles.flxBetween)}></div>
                  <div className={classNames(CommonStyles.mb16)}>
                    <PrimaryButton
                      children={"Verify"}
                      colorType={"blue"}
                      onClick={handleVerifyClick}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div
              className={classNames(
                LoginStyles.imgBackgrond,
                CommonStyles.col6,
                CommonStyles.colmd12,
                CommonStyles.colsm12
              )}
            >
              <div className={classNames(LoginStyles.centerContent)}>
                <img src={Logo} alt="Logo" />
              </div>
            </div>
            {showModal && (
              <VerifyModal
                loading={loading}
                showModal={showModal}
                handleSubmit={handleSubmit}
                code={code}
                setCode={setCode}
                codeError={codeError}
                setCodeError={setCodeError}
                minutes={minutes}
                seconds={seconds}
                handleSendCodeToEmail={handleSendCodeToEmail}
                successMessage={successMessage}
              />
            )}
          </>
        )}
      </div>

      {isMobile && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "30px  0",
            }}
          >
            <form
              className={LoginStyles.loginFormContainer}
              style={{
                marginTop: "10%",
                marginBottom: "10%",
              }}
            >
              <div className={classNames(CommonStyles.flx)} onClick={() => {}}>
                <FaChevronLeft className={LoginStyles.BackIcon} />
                <p
                  className={classNames(
                    CommonStyles.fs16,
                    // CommonStyles.semiBold,
                    CommonStyles.colorBlue
                  )}
                >
                  Back
                </p>
              </div>
              <div
                className={classNames(
                  CommonStyles.flx,
                  CommonStyles.flxBetween
                )}
              >
                <p
                  className={classNames(
                    CommonStyles.fs28,
                    CommonStyles.semiBold,
                    CommonStyles.colorBlue
                  )}
                  style={{ marginTop: "32px", whiteSpace: "nowrap" }}
                >
                  Email verification
                </p>

                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    display: "flex",
                    // justifyContent: "flex-end",
                    width: "80px",
                  }}
                />
              </div>

              <p
                className={classNames(
                  CommonStyles.fs16,
                  CommonStyles.semiBold,
                  CommonStyles.colorBlue
                )}
              >
                Your Journey Begins Here
              </p>
              <div className={CommonStyles.mt24}>
                <CustomInput
                  placeholder="Please enter Email"
                  id="Email"
                  name="Email"
                  type="text"
                />
              </div>
              <div className={CommonStyles.mt16}>
                <div className={classNames(CommonStyles.flxBetween)}></div>
                <div className={classNames(CommonStyles.mb16)}>
                  <PrimaryButton
                    children={"Verify"}
                    colorType={"blue"}
                    onClick={handleVerifyClick}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default Email_Verify;
