import React, { useMemo } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./Detail.module.css";
import { IoMdArrowForward } from "react-icons/io";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import moment from "moment";



const TravelDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  let item = state.item;
  const withBooking = item?.tourId;

  const handleBooking = () => {
    if (item?.remainingSeats === 0) {
      alert("We're Sorry. No seats available");
    } else {
      navigate("/services/travel/TravelBooking", { state: { item: item } });
    }
  };

  const formattedDateTime = useMemo(
    () => moment(item?.returnDate).format('DD MMM YYYY'),
    [item?.returnDate],
  );
  const format = useMemo(
    () =>
      moment(item?.departDate || withBooking?.departDate).format('DD MMM YYYY'),
    [item?.departDate, withBooking?.departDate],
  );
  const travelDetails = [
    {
      heading: "From",
      detail: item?.from,
    },
    {
      heading: "To",
      detail: item?.to,
    },
    {
      heading: "Departure",
      detail: [
        format,
        ' ',
        item?.departTime || withBooking?.departTime,
      ],
    },
    {
      heading: "Duration",
      detail: item?.packageDuration || withBooking?.packageDuration,
    },
    {
      heading: "Return",
      detail: [
        formattedDateTime,
        ' ',
        item?.destinationTime || withBooking?.destinationTime,
      ],
    },
  ];
  const travelmeals = [
    {
      heading: "BreakFast",
      detail: item?.breakfastQuantity ||
        withBooking?.breakfastQuantity,
    },
    {
      heading: "Lunch",
      detail: item?.lunchQuantity || withBooking?.lunchQuantity,
    },
    {
      heading: "Dinner",
      detail: item?.dinnerQuantity || withBooking?.dinnerQuantity,
    },
  ];
  return (
    <div>
      <MainHeader
        mainHeading="Travel Agencies"
        breadcrumb={["Home", "Services", "Travel Agencies", "Tour", "Details"]}
      />

      <div
        className={classNames(commonstyle.container)}
        style={{ marginBottom: "32px" }}
      >
        <p
          className={classNames(
            commonstyle.colorBlue,
            commonstyle.fs24,
            commonstyle.semiBold
          )}
        >
          {item?.packageName}
        </p>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div className={classNames(commonstyle.col7, commonstyle.colsm12)}>
            <div
              className={classNames(
                commonstyle.flx,
                commonstyle.flxBetween,
                commonstyle.flxWrap
              )}
            >
              <p
                className={classNames(
                  commonstyle.colorGreen,
                  commonstyle.fs16,
                  commonstyle.semiBold
                )}
              >
                {`${item?.limitedSeats} Seats -${item?.className}`}
              </p>
              <p
                className={classNames(
                  commonstyle.colorGreen,
                  commonstyle.fs16,
                  commonstyle.semiBold
                )}
              >
                Remian {`${item?.remainingSeats}`}
              </p>
            </div>
            <div>
              <div className={classNames(style.imgconatiner)}>
                <img src={item?.images?.[0] || "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"}
                  alt="detail img" />
              </div>
            </div>
            <div className={classNames()} style={{ marginTop: "10px" }}>
              <p
                className={classNames(
                  commonstyle.fs18,
                  commonstyle.colorBlue,
                  commonstyle.semiBold
                )}
              >
                Travel Summary
              </p>

            </div>
          </div>
          <div className={classNames(commonstyle.col4, commonstyle.colsm12)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              Price
            </p>
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <div>
                <p
                  className={classNames(
                    commonstyle.colorGreen,
                    commonstyle.fs16,
                    commonstyle.semiBold
                  )}
                >
                  Per Couple -{' '}
                  {item?.pricePerCouple || withBooking?.pricePerCouple}/-
                </p>
                <p
                  className={classNames(
                    commonstyle.colorGreen,
                    commonstyle.fs16,
                    commonstyle.semiBold
                  )}
                >
                  Per Head -{' '}
                  {item?.pricePerHead || withBooking?.pricePerHead}
                  /-
                </p>
              </div>
              <button className={style.showMoreButton} onClick={handleBooking}>
                Book Now
                <span className={style.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          {travelDetails.map((item, index) => (
            <div key={index}>
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs18,
                  commonstyle.semiBold
                )}
              >
                {item.heading}
              </p>
              <p
                className={classNames(commonstyle.colorBlue, commonstyle.fs18)}
              >
                {item.detail}
              </p>
            </div>
          ))}
        </div>

        <div className={classNames(commonstyle.col4, commonstyle.colsm12)}>
          <p
            className={classNames(
              commonstyle.fs18,
              commonstyle.colorBlue,
              commonstyle.semiBold
            )}
          >
            Plans
          </p>
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween,
              commonstyle.flxWrap
            )}
          >
            {travelmeals.map((item, index) => (
              <div key={index}>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs18,
                    commonstyle.semiBold
                  )}
                >
                  {item.heading}
                </p>
                <p
                  className={classNames(
                    commonstyle.colorBlue,
                    commonstyle.fs18
                  )}
                >
                  {item.detail}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className={classNames()} style={{ marginTop: "16px" }}>
          <p
            className={classNames(
              commonstyle.fs18,
              commonstyle.colorBlue,
              commonstyle.semiBold
            )}
          >
            Policies
          </p>

          <p className={classNames(commonstyle.fs16, commonstyle.colorBlue)}>
            {item?.recentTourPolicy || withBooking?.recentTourPolicy}
          </p>
        </div>
      </div>
      <Footerr />
    </div>
  );
};

export default TravelDetail;
