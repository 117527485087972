import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import style from "./style.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import CustomSelect from "shared/components/CustomSelect";
import { IoMdArrowForward } from "react-icons/io";
import img from "assets/images/insuranceboth.png";

interface InsuranceFormProps {
  insuranceOptions: string[];
  onSelectPlan: (selectedOption: string) => void;
  title: string;
  subtitle: string;
  formtitle: string;
  id?: number;
  formType: "health" | "travel";
}

const InsuranceForm: React.FC<InsuranceFormProps> = ({
  insuranceOptions,
  onSelectPlan,
  title,
  subtitle,
  formtitle,
  formType,
}) => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
  const [passengerType, setPassengerType] = useState<string | null>(null);
console.log(selectedPlan,'........selectedPlan')
  const handleSelect = (selectedOption: string) => {
    setSelectedPlan(selectedOption);
    onSelectPlan(selectedOption);
  };

  const countries = [
    { id: 1, title: "Pakistan" },
    { id: 2, title: "USA" },
    { id: 3, title: "UK" },
    { id: 4, title: "Bangladesh" },
    { id: 5, title: "Italy" },
    { id: 6, title: "Spain" },
    { id: 7, title: "Portugal" },
    { id: 9, title: "Australia" },
    { id: 10, title: "South Africa" },
    { id: 11, title: "Sri Lanka" },
    { id: 12, title: "Spain" },
    { id: 13, title: "UAE" },
  ];

  const handleFormSubmit = () => {
    navigate("/services/insurance/InsuranceCards", {
      state: { 
        selectedPlan, 
        selectedCountry, 
        passengerType,
        formType 
      },
    });
  };
  
console.log(selectedCountry,'.........selectedCountry')
  return (
    <div>
      <div
        className={classNames(
          commonstyle.flx,
          commonstyle.flxBetween,
          commonstyle.flxWrap
        )}
      >
        <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
          <div>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              {title}
            </p>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              <span
                className={classNames(
                  commonstyle.colorOrange,
                  commonstyle.fs16,
                  commonstyle.semiBold
                )}
              >
                {subtitle}
              </span>
            </p>
          </div>
          <img src={img} alt="Insurance Plan" />
        </div>

        <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
          <div className={classNames(style.formcontainer)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs14,
                commonstyle.semiBold
              )}
            >
              {formtitle}
            </p>
            <CustomSelect
              options={insuranceOptions}
              placeholder="Please Select Best Plan"
              onSelect={handleSelect}
            />
            {selectedPlan &&
              (selectedPlan === "Single Trip" ||
                selectedPlan === "Multiple Trips") && (
                <>
                  <div className={classNames(style.additionalInfo)}>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs14,
                        commonstyle.semiBold
                      )}
                    >
                      Please Fill Form
                    </p>

                    <CustomSelect
                      options={countries.map((country) => country.title)}
                      placeholder="Select Country for Traveling"
                      onSelect={(country) => {
                        setSelectedCountry(country);
                      }}
                    />
                    <CustomSelect
                      options={["Individual Travel", "Family Travel"]}
                      placeholder="Select Passenger Type"
                      onSelect={(type) => {
                        setPassengerType(type);
                      }}
                    />
                  </div>
                </>
              )}
            <div>
              <button className={style.selectbutton} onClick={handleFormSubmit}>
                Select Plan <IoMdArrowForward />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceForm;
