import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import commonStyles from "shared/utils/common.module.css";
import styles from "../Request/PharmacyRequest.module.css";
import SearchBar from "shared/components/Searchbar";
import { LuCalendarDays } from "react-icons/lu";
import { BsClock } from "react-icons/bs";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { PrimaryButton, CustomModal, RingLoader } from "shared/components";
import { FaCheckCircle } from "react-icons/fa";
import { pharmacyAddBid, PharmacyGetRequest } from "shared/services";
import NewPagination from "shared/components/NewPagination/NewPagination";
import { TbRefresh } from "react-icons/tb";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const [selectedRequest, setSelectedRequest] = useState<any | null>(null);
  // console.log("🚀 ~ selectedRequest:", selectedRequest);
  const [loading, setLoading] = useState(false);
  const [bidLoading, setBidLoading] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [requests, setRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [length, setLength] = useState(0);
  const itemsPerPage = 10;
  const totalItems = length;
  const [selectedMed, setSelectedMed] = useState<any>([]);
  const [error, setError] = useState("");
  const [bidStatus, setBidStatus] = useState<Record<string, string>>({});

  const handleGoToDetail = (request: any): void => {
    setSelectedRequest(request);
  };

  const handleGoback = () => {
    setSelectedRequest(null);
  };

  const handleCheckboxChange = (id: any) => {
    setError("");
    setSelectedMed((prevSelectedIds: any) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId: any) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };

  const handleBid = () => {
    if (selectedMed.length === 0) {
      setError("Please select Medicine Availability");
      return;
    }

    const allIdsInArray = selectedRequest.medicineIds.every((obj: any) =>
      selectedMed.includes(obj._id)
    );
    const flag = allIdsInArray ? "full" : "partial";

    setBidLoading(true);
    const params = {
      requestId: selectedRequest?._id,
      availableMedIds: selectedMed,
      partialOrFull: flag,
    };
console.log(params,'....params')
    pharmacyAddBid(params)
      .then((res: any) => {
        console.log("Bid submitted for request:", res?.data);
        // Update bid status to 'Sent' for the selected request
        setBidStatus((prev) => ({ ...prev, [selectedRequest._id]: "Sent" }));
        setAcceptModal(true);
        setSelectedMed([]);
        setTimeout(() => {
          setAcceptModal(false);
          setSelectedRequest(!selectedRequest)
        }, 2000);
      })
      .catch((err: any) => {
        console.log("Bid submitted Error:", err?.response?.data);
        setError(err?.response?.data?.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      })
      .finally(() => setBidLoading(false));
  };

  const handleFetchRequest = (page: number) => {
    setLoading(true);
    PharmacyGetRequest(page)
      .then((res: any) => {
        // console.log("res......", res?.data?.medicineRequests);
        const fetchedRequests = res?.data?.medicineRequests || [];
        setRequests(fetchedRequests);
        setLength(res?.data?.requestsLength);

        // Initialize bid statuses based on fetched requests and existing bids
        const initialBidStatuses = fetchedRequests.reduce(
          (acc: any, req: any) => {
            // Assume `hasBid` is a boolean indicating if the bid was already made
            acc[req._id] = req.hasBid ? "Sent" : "Pending";
            return acc;
          },
          {}
        );
        setBidStatus(initialBidStatuses);
      })
      .catch((err: any) => {
        console.log("err......", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleFetchRequest(1);
  }, []);

  const handleNextPage = () => {
    let itemToRender = currentPage * itemsPerPage;

    if (length > itemToRender) {
      setCurrentPage(currentPage + 1);
      handleFetchRequest(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      handleFetchRequest(currentPage - 1);
    }
  };

  const handleRefresh = () => {
    setLoading(true);
    setCurrentPage(1);
    handleFetchRequest(1);
  };

  return (
    <div className={classNames(commonStyles.col12, styles.outer)}>
      <SearchBar />
      <div className={commonStyles.mr87}>
        <div className={commonStyles.outerContainer}>
          {!selectedRequest && (
            <div
              className={classNames(commonStyles.flxBetween, commonStyles.mb32)}
            >
              <div className={classNames(commonStyles.flx)}>
                <p
                  className={classNames(
                    commonStyles.fs22,
                    commonStyles.semiBold
                  )}
                >
                  ALL Requests
                </p>
                {loading ? (
                  <div className={styles.loader}>
                    <RingLoader color={"#0D47A1"} size={30} />
                  </div>
                ) : (
                  <TbRefresh
                    className={styles.refresh}
                    onClick={handleRefresh}
                  />
                )}
              </div>
              <div className={classNames(commonStyles.flx)}>
                <NewPagination
                  onNext={handleNextPage}
                  onPrevious={handlePreviousPage}
                  startItem={(currentPage - 1) * itemsPerPage + 1}
                  endItem={Math.min(currentPage * itemsPerPage, length)}
                  totalItems={totalItems}
                />
              </div>
            </div>
          )}

          {selectedRequest ? (
            <div>
              <div className={classNames(commonStyles.flx)}>
                <IoArrowBack className={styles.back} onClick={handleGoback} />
                <p
                  className={classNames(
                    commonStyles.fs24,
                    commonStyles.semiBold,
                    commonStyles.colorBlue
                  )}
                >
                  Request Details
                </p>
              </div>
              <div className={styles.card} style={{ marginTop: "20px" }}>
                <p
                  className={classNames(
                    commonStyles.fs16,
                    commonStyles.semiBold
                  )}
                >
                  <strong>Request ID:</strong> {selectedRequest.requestId}
                </p>
                <div className={classNames(commonStyles.col6)}>
                  <div
                    className={commonStyles.flx}
                    style={{ marginTop: "10px" }}
                  >
                    <p
                      className={classNames(
                        commonStyles.fs16,
                        commonStyles.semiBold
                      )}
                      style={{ width: "50%" }}
                    >
                      Availability
                    </p>
                    <p
                      className={classNames(
                        commonStyles.fs16,
                        commonStyles.semiBold
                      )}
                      style={{ width: "50%" }}
                    >
                      MEDICINE
                    </p>
                  </div>
                  <div style={{ marginLeft: "10px" }}>
                    {selectedRequest?.medicineIds?.map(
                      (medicine: any, index: number) => 
                        {
                        // console.log(medicine,'...........medicine')

                          return(
                        <div
                          key={index}
                          className={commonStyles.flx}
                          style={{ alignItems: "center", marginTop: "5px" }}
                        >
                          <div
                            style={{
                              width: "50%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedMed.includes(medicine?.id?._id)}
                              onChange={() =>
                                handleCheckboxChange(medicine?.id?._id)
                              }
                              style={{ marginRight: "10px" }}
                            />
                          </div>
                          <div
                            style={{
                              width: "50%",
                              marginLeft: "10px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <span>{medicine?.id?.productName}</span>
                          </div>
                        </div>)}
                      
                    )}
                  </div>
                </div>
              </div>

              {error && (
                <div className={classNames(commonStyles.error)}>*{error}</div>
              )}

              {!selectedRequest?.requestSent && (
                <div
                  style={{
                    width: "210px",
                    marginTop: "32px",
                    marginLeft: "auto",
                    textAlign: "right",
                  }}
                >
                  <PrimaryButton
                    disabled={bidLoading}
                    children={
                      bidLoading ? (
                        <RingLoader size={35} color={"#fff"} />
                      ) : (
                        "Bid"
                      )
                    }
                    colorType={"blue"}
                    onClick={handleBid}
                  />
                </div>
              )}
            </div>
          ) : (
            <div>
              {requests && requests.length > 0 ? (
                requests.map((val: any) => (
                  <div
                    className={styles.card}
                    key={val?._id}
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                  >
                    <div className={classNames(commonStyles.flx)}>
                      <div
                        style={{ width: "20%", marginLeft: "10px" }}
                        className={commonStyles.flx}
                      >
                        <p className={classNames(commonStyles.fs16)}>
                          {val?.requestId}
                        </p>
                      </div>
                      <div
                        style={{ width: "15%" }}
                        className={commonStyles.flx}
                      >
                        <LuCalendarDays className={styles.icon} />
                        <p className={classNames(commonStyles.fs16)}>
                          {new Date(val?.createdAt).toLocaleDateString(
                            "en-US",
                            {
                              month: "2-digit",
                              day: "2-digit",
                              year: "2-digit",
                            }
                          )}
                        </p>
                      </div>
                      <div
                        style={{ marginLeft: "32px", width: "15%" }}
                        className={commonStyles.flx}
                      >
                        <BsClock className={styles.icon} />
                        <p className={classNames(commonStyles.fs16)}>
                          {new Date(val.createdAt).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </p>
                      </div>
                      <div
                        className={classNames(commonStyles.flx)}
                        style={{ marginLeft: "auto", width: "30%" }}
                      >
                        <p
                          className={classNames(commonStyles.fs16, {
                            [styles.pending]: val?.status === "pending",
                            [styles.success]: val?.requestSent,
                          })}
                          style={{ margin: 0, marginRight: "10px" }}
                        >
                          {val?.requestSent ? "Bid Sent" : "Pending"}
                        </p>
                      </div>
                      <div
                        style={{
                          marginLeft: "auto",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          onClick={() => handleGoToDetail(val)}
                          className={classNames(
                            commonStyles.fs16,
                            commonStyles.semiBold
                          )}
                        >
                          View Detail
                        </span>
                        <IoArrowForward size={24} />
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <>{!loading && <p>No Medicine request yet</p>}</>
              )}
            </div>
          )}
        </div>
      </div>

      <CustomModal
      showModal={acceptModal}
      close={() => setAcceptModal(false)}
      // icon={<FaCheckCircle size={48} color="#4CAF50" />}
      children={<p>Your bid has been submitted successfully!</p>}
      />
    </div>
  );
};

export default Index;
