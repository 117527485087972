import classNames from "classnames";
import React, { useState } from "react";
import commonstyles from "shared/utils/common.module.css";
import styles from "./styles.module.css";
import SearchBar from "shared/components/Searchbar";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { CustomInput, CustomModal, PrimaryButton } from "shared/components";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "shared/components/CustomSelect";
import { addTestToLab, getAllTests } from "shared/services";
import { labAddTestInfo } from "shared/utils";

function AddTest() {
  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      testName: "",
      //   testCategory: "",
      // testAddTest_TestCode: "",
      testDuration: "",
      testDescription: "",
      testPrice: "",
      priceForMeditour: "",
    },
    validationSchema: Yup.object(labAddTestInfo),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    const currentData = formik.values;
    setLoading(true);

    let params = {
      testNameId: currentData?.testName,
      // categoryName: "Blood",
      testDescription: currentData?.testDescription,
      price: currentData?.testPrice,
      duration: currentData?.testDuration,
      priceForMeditour: currentData?.priceForMeditour,
    };
    addTestToLab(params)
      .then((res: any) => {
        if (res.data.auth) {
          navigate("/laboratory/test");
        }
      })
      .catch((err: any) => {
        console.log("send error.nnnnn..", err.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleGoback = () => {
    navigate(`/laboratory/test`);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      fetchAllTests(query);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion: any) => {
    setSearchQuery(suggestion?.name);
    formik.setFieldValue("testName", suggestion?._id);

    setSuggestions([]);
  };

  const handleValueChange = (name: string, value?: string) => {
    // setCost(text);
    formik.setFieldValue(name, value);
  };

  const fetchAllTests = (search: string) => {
    getAllTests(1, search)
      .then((res: any) => {
        // console.log("res....", res?.data?.data);
        setSuggestions(res?.data?.data);
      })
      .catch((err: any) => {});
  };
  return (
    <div className={classNames(commonstyles.col12)}>
      <SearchBar />
      <div className={classNames(commonstyles.flxBetween, commonstyles.mb32)}>
        <div className={classNames(commonstyles.flx)}>
          <div className={styles.backOuter}>
            <IoArrowBack className={styles.addIcon} onClick={handleGoback} />
          </div>
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Add Test
          </p>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={commonstyles.mr87}>
          <div className={classNames(commonstyles.col6)}>
            <div className={classNames(commonstyles.flxBetween)}>
              <p
                className={classNames(
                  commonstyles.fs16,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Available Tests
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorOrange
                )}
                //   onClick={() => setShowAddModal(true)}
                onClick={() => {}}
                style={{
                  marginLeft: "16px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Add Custom
              </p>
            </div>

            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Here"
              // className={classNames(styles.customInput)}
              style={{
                width: "100%",
                padding: "10px",
                marginBottom: "10px",
                border: "2px solid rgb(0, 39, 109)",
                borderRadius: "3px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            />

            {formik.touched.testName && formik.errors.testName ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.testName}
              </div>
            ) : null}

            {/* </div> */}

            {suggestions?.length > 0 && (
              <div
                className={styles.searchResults}
                style={{
                  backgroundColor: "white",
                  zIndex: 10,
                  marginTop: -10,
                }}
              >
                <ul
                // className={styles.suggestionList}
                >
                  {suggestions.map((suggestion: any, index) => (
                    <li
                      key={index}
                      className={styles.suggestionItem}
                      onClick={() => handleSuggestionClick(suggestion)}
                      style={{
                        listStyleType: "none",
                      }}
                    >
                      {suggestion?.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className={classNames(commonstyles.flx)}>
            {/* <div className={classNames(commonstyles.col2)}>
            <CustomInput
              placeholder="Test Code"
              id="cost"
              name="cost"
              type="text"
              onChange={(e: any) => handleCostChange(e.target.value)}
              value={cost}
            />

            
          </div> */}

            <div className={classNames(commonstyles.col4)}>
              <CustomInput
                placeholder="Test Duration"
                id="testDuration"
                name="testDuration"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("testDuration", e.target.value)
                }
                value={formik.values.testDuration}
              />

              {formik.touched.testDuration && formik.errors.testDuration ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testDuration}
                </div>
              ) : null}
            </div>
          </div>

          <div className={classNames(commonstyles.col4)}>
            <CustomInput
              placeholder="Test Description"
              id="testDescription"
              name="testDescription"
              type="text"
              onChange={(e: any) =>
                handleValueChange("testDescription", e.target.value)
              }
              value={formik.values.testDescription}
            />

            {formik.touched.testDescription && formik.errors.testDescription ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.testDescription}
              </div>
            ) : null}
          </div>

          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.colorBlue,
              commonstyles.semiBold,
              commonstyles.mt32
            )}
          >
            Test Price?
          </p>
          <div className={classNames(commonstyles.flx)} style={{ gap: "50px" }}>
            <div className={classNames(commonstyles.col2)}>
              <CustomInput
                placeholder="Actual Price"
                id="testPrice"
                name="testPrice"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("testPrice", e.target.value)
                }
                value={formik.values.testPrice}
              />

              {formik.touched.testPrice && formik.errors.testPrice ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.testPrice}
                </div>
              ) : null}
            </div>

            <div className={classNames(commonstyles.col2)}>
              <CustomInput
                placeholder="MediTour Price"
                id="priceForMeditour"
                name="priceForMeditour"
                type="text"
                onChange={(e: any) =>
                  handleValueChange("priceForMeditour", e.target.value)
                }
                value={formik.values.priceForMeditour}
              />

              {formik.touched.priceForMeditour &&
              formik.errors.priceForMeditour ? (
                <div className={classNames(commonstyles.error)}>
                  *{formik.errors.priceForMeditour}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "56px", width: "210px" }}>
          <PrimaryButton
            disabled={loading}
            children={loading ? "loading..." : "Save"}
            type="submit"
            colorType={"Linear"}
          />
        </div>
      </form>
      <CustomModal
        showModal={showAddModal}
        children={
          <AddCustomTest
            setShowAddModal={setShowAddModal}
            categories={categories}
          />
        }
      />
    </div>
  );
}

export default AddTest;

const AddCustomTest = (props: any) => {
  const { setShowAddModal, categories } = props;
  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().trim().required("Required"),
      name: Yup.string().trim().required("Required"),
    }),
    onSubmit: (values) => {
      //   handleSubmit();
    },
  });

  const handleSelect = (selectedOption: string) => {
    formik.setFieldValue("category", selectedOption);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
  };
  return (
    <div style={{ width: "500px" }}>
      <div className={styles.end}>
        <button className={styles.close} onClick={handleCloseModal}>
          &#10006;
        </button>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ width: "233px" }}>
          <CustomSelect
            options={categories}
            placeholder="Treatmen Category"
            onSelect={handleSelect}
          />
          {formik.touched.category && formik.errors.category ? (
            <div className={classNames(commonstyles.error)}>
              *{formik.errors.category}
            </div>
          ) : null}
        </div>
        <div style={{ marginTop: "56px" }} className={commonstyles.flxBetween}>
          <div style={{ width: "233px" }}>
            <CustomInput
              placeholder="Treatment Name"
              id="name"
              name="name"
              type="text"
              onChange={formik.handleChange}
              value={formik.values.name}
            />

            {formik.touched.name && formik.errors.name ? (
              <div className={classNames(commonstyles.error)}>
                *{formik.errors.name}
              </div>
            ) : null}
          </div>
        </div>

        <div style={{ marginTop: "56px", width: "210px" }}>
          <PrimaryButton children={"Save"} type="submit" colorType={"Linear"} />
        </div>
      </form>
    </div>
  );
};
