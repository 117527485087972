import React, { useState, useEffect } from "react";
import { IoMdArrowForward } from "react-icons/io";
import img from "assets/images/RentaCarcard.png";
import CardStyless from "../DoctarServices/Cards.module.css";
import classNames from "classnames";
import CommonStyless from "shared/utils/common.module.css";
import { Link, useNavigate } from "react-router-dom";
import Vector from "assets/images/Vector.png";
import { getAll_RentACar } from "shared/services";
import NewPagination from "shared/components/NewPagination/NewPagination";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import { LoadingModal, RingLoader } from "shared/components";
import { useSelector } from "react-redux";


const RentaCards = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    error: null,
  });
  const { isLoggedIn, hospitalId } = useSelector(
    (state: any) => state.root.common
  );

  const handleViewMoreClick = (item: any) => {
    if (isLoggedIn) {
      navigate(`/services/rentacar/RentaCarDetail`, { state: { item: item } });
    } else {
      navigate("/user/login");
    }
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
    fetchNartByCar(currentPage + 1);
  };
  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
    fetchNartByCar(currentPage - 1);
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  }, []);

  // Call API when location is updated
  useEffect(() => {
    if (location.latitude && location.longitude) {
      setLoading(true)
      fetchNartByCar(currentPage);
    }
  }, [location]);
  const fetchNartByCar = (pagenum: any) => {
    setLoading(true)
    let params = {
      search: '',
      lat: location?.latitude,
      long: location?.longitude,
    };
    // console.log('API Params:', params, pagenum);
    getAll_RentACar(params, pagenum)
      .then((res: any) => {
        setData(res?.data?.rentACars)
        setTotalItems(res?.data?.rentACars?.length);
        // console.log(res.data, '..........dataDetails');
      })
      .catch((err: any) => {
        console.log(err, '.Error');
      }).finally(() => {
        setLoading(false)
      })
  };
  return (
    <div className={classNames(CommonStyless.container)}>
      <div style={{ backgroundColor: "#FDFDFD" }}>
        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          <div className={CardStyless.cardContainer}>
            {data && data?.length > 0 ? (
              data.map((card: any) => {
                console.log(card, '.......card')
                return (
                  <div key={card.rentACar._id} className={CardStyless.cardWrapper}>
                    <div className={CardStyless.cardImageWrapper}>
                      <img src={card?.rentACar?.logo || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU'} alt="card img" className={CardStyless.cardImage} />
                    </div>
                    <div className={CardStyless.cardBody}>
                      {/* <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  > */}
                      <div
                        className={classNames(
                          CardStyless.cardName,
                          CommonStyless.colorBlue
                        )}
                      >
                        {card?.rentACar?.name}
                      </div>
                      <div
                        className={classNames(
                          CardStyless.cardName,
                          CommonStyless.colorGray
                        )}
                      >
                        {`${card?.distance} Km away from you`}
                      </div>
                      {/* <div>
                      <img
                        src={card.vector}
                        alt="Vector icon"
                        className={CardStyless.vectorIcon}
                      />
                    </div> */}
                      {/* </div> */}
                      <div className={CardStyless.cardtime}>
                        <span>{card.time}</span>
                      </div>
                      <div className={CardStyless.cardFooter}>
                        <span className={CardStyless.cardRating}>*****</span>
                        <span className={CardStyless.cardRatingValue}>
                          {card.averageRating}
                        </span>
                        <span
                          className={classNames(
                            CommonStyless.flx,
                            CardStyless.viewMore
                          )}
                          onClick={() => handleViewMoreClick(card)}
                        >
                          <Link to="#" className={CardStyless.cardViewMore}>
                            Details
                          </Link>
                          <span className={CardStyless.cardArrow}>
                            <IoMdArrowForward />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                )
              })) : (
              <div>{!loading && <PhysiotheristsEmpty />}</div>
            )}
          </div>
          {loading && <LoadingModal showModal={loading} />}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NewPagination
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
              startItem={(currentPage - 1) * itemsPerPage + 1}
              endItem={Math.min(currentPage * itemsPerPage, totalItems)}
              totalItems={totalItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentaCards;
