import Card from "./Card.module.css";
import React from "react";
import moment from "moment";
import { CiCalendar, CiClock2 } from "react-icons/ci";
const AvailabilityCard = ({ data, title, subTitle, source }: any) => {
  console.log("........data Hosp.......", data);

  return (
    <div className={Card.scheduleWrapper}>
      <div className={Card.header}>
        <div className={Card.title}>
          {source}
          <span>{subTitle || "Availabilty"}</span>
        </div>
        <div className={Card.price}>
          <p>{data?.price?.actualPrice}</p>
        </div>
      </div>
      <hr />
      {/* ...... */}
      <div className={Card.scheduleContainer}>
        {data?.availability?.map((item: any, index: any) => (
          <div key={index} className={Card.card}>
            <div className={Card.cardHeader}>
              {<CiCalendar />}
              <span>{moment().day(item?.dayOfWeek).format("dddd")}</span>
            </div>
            {item?.morning?.startTime || item?.morning?.endTime ? (
              <div className={Card.cardBody}>
                {<CiClock2 />}
                <span>
                  {" "}
                  {item?.morning?.startTime &&
                    moment(item?.morning?.startTime, "HH:mm").format("hh:mm A")}
                  {" -  "}
                  {item?.morning?.endTime &&
                    moment(item?.morning?.endTime, "HH:mm").format("hh:mm A")}
                </span>
              </div>
            ) : null}
            {item?.evening?.startTime || item?.evening?.endTime ? (
              <div className={Card.cardBody}>
                {<CiClock2 />}
                <span>
                  {item?.evening?.startTime &&
                    moment(item?.evening?.startTime, "HH:mm").format("hh:mm A")}
                  {" - "}
                  {"  "}
                  {item?.evening?.endTime &&
                    moment(item?.evening?.endTime, "HH:mm").format("hh:mm A")}
                </span>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailabilityCard;
