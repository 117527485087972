import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import style from "../AboutUs/aboutUs.module.css";
import classNames from "classnames";
import { IoIosArrowForward } from "react-icons/io";
import commonstyles from "shared/utils/common.module.css";
import commonStyle from "shared/utils/common.module.css";

import Footerr from "../Footer";

import styles from "./PaymentNavbar.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TestCard } from "shared/components";
import Checkout from "shared/services/stripe/checkout";
import { setPaidAmount, setPaymentParams } from "shared/redux";
import axios from "axios";
const PaymentNavbar = React.memo((props) => {
  const { state }: any = useLocation();
  const dispatch = useDispatch();
  const { obj, hotelDetail } = useSelector((state: any) => state?.root?.common);
  console.log(obj, ",,,,,,,,,,obj");
  let arryObj = state?.serviceName === "pharmacy" ? obj : [obj];
  console.log(arryObj, "..........arryObj");
  const [paymentType, setPaymentType] = useState("Full Payment");
  const [location, setLocation] = useState("International");

  const [stripeOpen, setStripeOpen] = useState(false);
  const [exchangeRate, setExchangeRate] = useState<any>(null);

  const [convertedAmount, setConvertedAmount] = useState<any>(null);

  const [calculateAmount, setCalculateAmount] = useState<any>(
    state?.actualAmount
  );

  let processingFee: number = location == "Pakistan" ? 0 : 0.2;
  let amountwithFee = Number(state?.actualAmount) + processingFee;
  let thirtyPercent: number = Number(state?.actualAmount) * 0.3;
  let thirtyPercent_WithFee: number = thirtyPercent + processingFee;
  let amount = convertedAmount + processingFee;

  const mapSelectedItems = () => {
    if (state?.serviceName == "Lab") {
      return obj?.map((item: any) => ({
        itemId: item?._id,
        quantity: 1,
      }));
    }
  };

  const formattedData = mapSelectedItems();

  console.log("....serviceName", state?.serviceName);
  useEffect(() => {
    handleAmount();
  }, [paymentType, location]);

  useEffect(() => {
    fetchExchangeRate();
  }, [calculateAmount]);

  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(
        `https://v6.exchangerate-api.com/v6/20d8f762235d76eb03fc8d3b/pair/PKR/USD`
      );
      const rate = response.data.conversion_rate;
      setExchangeRate(rate);
      setConvertedAmount(calculateAmount * rate);
      // setProcessFee(20 * rate);
    } catch (err: any) {}
  };
  const arivalDate = `${hotelDetail?.fromDate}  ${hotelDetail?.fromDate}`;
  const onPressBtn = () => {
    if (location == "Pakistan") {
      const transactionReferenceNumber = Math.floor(
        Math.random() * 100000
      ).toString();
      const invoiceNo = `MED-${transactionReferenceNumber}`;
      postRequest_Blink(invoiceNo);
    } else {
      dispatch(
        setPaymentParams({
          actualAmount: state?.actualAmount,
          paymentType: paymentType,
          processingFee: processingFee,
          appointmentType: state?.appointmentType || "",
          docId: state?.doctorId || "",
          item: formattedData || "",
          labId: state?.labId || "",
          ambulanceId: state?.ambulanceId || "",
        })
      );
      setStripeOpen(true);
    }
  };

  const postRequest_Blink = async (invoiceNo: any) => {
    let body = {
      ClientID: "dAS9bibLXf1q6Aj",
      ClientSecret: "E1VwKE2IfPXoYmX",
    };
    try {
      const response = await fetch("https://staging-api.blinq.pk/api/auth", {
        method: "POST",
        // mode: "no-cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      console.log("result", response?.headers);

      const token = response.headers.get("token");
      if (token) {
        // Proceed with using the token
      } else {
        console.log("Token not found in the response headers");
      }
      // const result = await response.json();

      // createInvoice(invoiceNo, token);
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const createInvoice = async (invoiceNo: any, token: any) => {
    let body = [
      {
        InvoiceNumber: invoiceNo,
        InvoiceAmount: 5000,
        InvoiceDueDate: "2025/04/25",
        InvoiceType: "Service",
        IssueDate: "2025/04/24",
        CustomerName: "Salim",
      },
    ];
    try {
      const response = await fetch(
        "https://staging-api.blinq.pk/invoice/create",
        {
          method: "POST",
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const result = await response.json();
      const url = result?.ResponseDetail[0]?.ClickToPayUrl;
      console.log(url, "url.............");
    } catch (error: any) {
      console.error("Error...:", error);
    }
  };

  const handleAmount = () => {
    if (paymentType == "Full Payment") {
      setCalculateAmount(amountwithFee);
    } else {
      setCalculateAmount(thirtyPercent_WithFee);
    }
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Payment
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Payment
          </p>
        </div>
      </div>
      {stripeOpen ? (
        <Checkout
          serviceName={state?.serviceName}
          convertedAmount={amount}
          paidAmount={calculateAmount}
        />
      ) : (
        <>
          {arryObj.map((profile: any, index: any) => {
            console.log(profile, "......profile");
            return state?.serviceName == "Lab" ||
              state?.serviceName == "prescription" ? (
              <TestCard item={profile} serviceName={state?.serviceName} />
            ) : (
              <div key={index} className={styles.profileCard}>
                <img
                  src={
                    profile?.doctorImage ||
                    profile?.item?.images?.[0] ||
                    profile?.images?.[0] ||
                    profile?.item?.packageLogo ||
                    profile?.ambulanceId?.logo ||
                    profile?.item?.propertyphoto?.[0]
                  }
                  alt={profile?.name}
                  className={styles.profileImage}
                />
                <div className={styles.profileInfo}>
                  <h3>
                    {profile?.name ||
                      profile?.item?.packageName ||
                      profile?.testNameId?.name ||
                      profile?.ambulanceName ||
                      profile?.productName ||
                      profile?.donationTitle ||
                      profile?.vehicleName ||
                      profile?.item?.propertyName}
                  </h3>
                  <p>
                    {profile?.qualifications
                      ? profile.qualifications
                      : profile?.item?.from && profile?.item?.to
                      ? `${profile.item.from} - ${profile.item.to}`
                      : profile?.brand
                      ? profile.brand
                      : profile?.description
                      ? profile?.description
                      : arivalDate}
                  </p>
                  <p>
                    {profile?.speciality
                      ? profile.speciality.join(", ")
                      : profile?.item?.returnDate
                      ? `Return Date: ${profile.item.returnDate}`
                      : profile?.quantity
                      ? `Quantity: ${profile.quantity}`
                      : profile?.totalDays && `Days: ${profile?.totalDays}`}
                  </p>
                  <p>{profile?.clinicExperience}</p>
                </div>
              </div>
            );
          })}

          <div className={styles.paymentContainer}>
            <h2>Payment</h2>
            <div className={styles.optionGroup}>
              <label>
                <input
                  type="radio"
                  name="location"
                  value="Pakistan"
                  checked={location === "Pakistan"}
                  onChange={() => setLocation("Pakistan")}
                />
                Pakistan
              </label>
              <label>
                <input
                  type="radio"
                  name="location"
                  value="International"
                  checked={location === "International"}
                  onChange={() => setLocation("International")}
                />
                International
              </label>
            </div>

            <h2>Payment Type</h2>
            <div className={styles.optionGroup}>
              <label>
                <input
                  type="radio"
                  name="paymentType"
                  value="Full Payment"
                  checked={paymentType === "Full Payment"}
                  onChange={() => setPaymentType("Full Payment")}
                />
                Full Payment
              </label>
              {state?.serviceName == "Lab" ||
              state?.serviceName == "prescription" ||
              state?.serviceName == "Ambulance" ? null : (
                <label>
                  <input
                    type="radio"
                    name="paymentType"
                    value="Partial Payment"
                    checked={paymentType === "Partial Payment"}
                    onChange={() => setPaymentType("Partial Payment")}
                  />
                  Partial Payment
                </label>
              )}
            </div>

            <div className={styles.amountDetails}>
              {location === "International" && (
                <div>
                  <span>Process Amount</span>
                  <span>
                    {location == "International" ? "$" : "PKR"} {processingFee}
                  </span>
                </div>
              )}
              <div>
                <span>Total Amount</span>
                <span>
                  {location == "International"
                    ? `${"$" + " " + convertedAmount?.toFixed(2)}`
                    : `${"PKR" + " " + calculateAmount}`}
                </span>
              </div>
              {location == "International" && (
                <div>
                  <span>Payable Amount</span>
                  <span>
                    {location == "International" &&
                      `${"$" + " " + amount?.toFixed(2)}`}
                  </span>
                </div>
              )}
            </div>

            <button onClick={onPressBtn} className={styles.paymentButton}>
              PAYMENT
            </button>
          </div>
        </>
      )}

      <Footerr />
    </div>
  );
});

export default PaymentNavbar;
