import React from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

interface RatingStarProps {
  rating: number;
  maxStars?: number;
}

const RatingStar: React.FC<RatingStarProps> = ({ rating, maxStars = 5 }) => {
  const stars = [];

  for (let i = 1; i <= maxStars; i++) {
    stars.push(
      i <= rating ? (
        <FaStar key={i} style={{ color: "#ffc107" }} /> 
      ) : (
        <FaRegStar key={i} style={{ color: "#e4e5e9" }} /> 
      )
    );
  }

  return <div>{stars}</div>;
};

export default RatingStar;
