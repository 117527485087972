import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CheckoutForm } from "./checkoutForm";

type Props = { serviceName?: any; convertedAmount?: any; paidAmount?: any };

const options: any = {
  mode: "payment",
  amount: 1099,
  currency: "usd",
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};

const VITE_STRIPE_PK =
  "pk_test_51PU65d1rwEL9hE4Z37XFQSQJrlvyhcozuGtFvME323uUZIK2rObxR4Mz3yYAxrmhChWZNec5qp0aC7wlMUqZwNK000NK1G3fyj";

const Checkout = (props: Props) => {
  const stripePromise = loadStripe(VITE_STRIPE_PK);
  const { serviceName, convertedAmount, paidAmount } = props;

  // import meta.env.VITE_STRIPE_PK is the publishable key you can either directly paste your stripe key here but not recommending if you are planning to upload the code on github as it should remain only available to you or save the key in .env file

  return (
    <div className="flex container mt-8">
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm
          serviceName={serviceName}
          convertedAmount={convertedAmount}
          paidAmount={paidAmount}
        />
      </Elements>
    </div>
  );
};

export default Checkout;
