import React, { useEffect, useState } from "react";
import NavBarr from "../../NavBarr";
import classNames from "classnames";
import style from "../../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import Footerr from "../../Footer";
import { useNavigate } from "react-router-dom";
import DownloadImagelogo from "../../../../../assets/images/DownloadImagelogo.png";
import loginLogo from "../../../../../assets/images/loginLogo.png";
import styles from "./orderHistory.module.css";
import DoubleButton from "shared/components/Buttons/DoubleButton";
import toast from "react-hot-toast";
import { getAllOrders_History } from "shared/services";
import { LoadingModal } from "shared/components";
import moment from "moment";
import Avatar from "../../../../../assets/logo.png";
const MyOrderHome = React.memo((props) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("Laboratory");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
    fetchOrders();
  }, [selected]);

  useEffect(() => {
    setLoading(true);
    fetchOrders();
  }, [selected]);

  const handleGoToOrderHistoryDetail = (item: any) => {
    navigate("/services/orderHistoryDetail", {
      state: { item: item },
    });
  };

  const onChange = (text: any) => {
    setSelected(text);
  };

  const fetchOrders = () => {
    let params: any = {
      page: 1,
      type: selected?.toLowerCase(),
    };

    getAllOrders_History(params)
      .then((res: any) => {
        console.log("list.........", res?.data);
        setList(res?.data?.orders);
      })
      .catch((err: any) => {
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // console.log("l.........", list);

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Order History
        </p>

        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Order History
          </p>
        </div>
      </div>
      <div className={styles.margin}>
        <DoubleButton
          tab1Label={"Laboratory"}
          tab2Label={"Pharmacy"}
          onTabChange={onChange}
        />
      </div>
      <div className={styles.orderContainer}>
        {list.map((order: any, index: any) => (
          <div key={index} className={styles.orderCard}>
            <div className={styles.logoContainer}>
              <img
                src={order?.vendorId?.logo || loginLogo}
                alt={order?.vendorId?.name}
                className={styles.orderLogo}
              />
            </div>
            <div className={styles.orderDetails}>
              <h3 className={styles.orderTitle}>{order?.vendorId?.name}</h3>
              <p className={styles.orderQuantity}>
                Quantity: {order?.items?.length || order?.medicineIds?.length}
              </p>
              <p className={styles.orderPrice}>
                RS. {order?.totalAmount || order?.amount}
              </p>
              <p
                className={classNames(styles.orderStatus, {
                  [styles.statusInProgress]: order?.status === "In-Progress",
                  [styles.statusPending]: order?.status === "pending",
                })}
              >
                {order?.status}
              </p>
            </div>
            <div className={styles.orderMeta}>
              <p className={styles.orderTime}>
                {moment(order?.createdAt).format("DD-MM-YYYY, h:mm a")}
              </p>
              {/* <p className={styles.orderId}>
                Order ID: {order?.vendorId?.vendorId}
              </p> */}

              <button
                onClick={() => handleGoToOrderHistoryDetail(order)}
                className={styles.detailsButton}
              >
                View Details
                <img
                  src={DownloadImagelogo}
                  alt="Logo"
                  className={styles.downloadButtonLogo}
                />
              </button>
            </div>
          </div>
        ))}
      </div>
      {loading && <LoadingModal showModal={loading} />}

      <Footerr />
    </div>
  );
});

export default MyOrderHome;
