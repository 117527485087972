import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  add_Appointment_Doctors,
  confirm_Booking,
  bookingTours,
  addPharmacyOrder,
  acceptAmbulanceReq,
  remaining_Appointment_Doctors,
  postDonationAmount,
  postDetails,
  addBookingRoom,
  confirmInsurance,
} from "../UserService";
import { setCart } from "shared/redux";

export const function_API = ({
  serviceName,
  paymentId,
  dispatch,
  paymentParams,
  paidAmount,
  confirmStripePayment,
  setModalVisible,
  setLoading,
  hospitalId,
  hotelDetails,
  secret,
  event,
  location,
  user,
  arryObj,
  userAge,
  appointmentId,
}: {
  serviceName?: any;
  paymentId?: any;
  paymentParams?: any;
  paidAmount?: any;
  confirmStripePayment?: any;
  hotelDetails: any;
  setModalVisible?: any;
  setLoading?: any;
  hospitalId: any;
  dispatch: any;
  secret?: any;
  event?: any;
  location?: any;
  user?: any;
  arryObj?: any;
  userAge?: any;
  appointmentId?: any;
}) => {
  console.log("function log.......", serviceName);

  if (
    serviceName === "doctor" ||
    serviceName === "physiotherapist" ||
    serviceName === "nutritionist" ||
    serviceName === "Hospital" ||
    serviceName === "departmentdoc"
  ) {
    let params = {
      paymentId: paymentId,
      amount: paymentParams?.actualAmount,
      isPaidFull:
        paymentParams?.paymentType == "Partial Payment" ? false : true,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
      ...(paymentParams?.appointmentType === "hospital" && {
        hospital: hospitalId,
      }),
      appointmentType: paymentParams?.appointmentType,
      gatewayName: "stripe",
    };

    add_Appointment_Doctors(params, paymentParams?.docId)
      .then(() => {
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log("Error:", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName == "remainingDoctor") {
    let params: any = {
      appointmentId: paymentParams?.appointmentId,
      paymentId: paymentId,
      paidByUserAmount: paymentParams?.paidByUserAmount,
      processingFee: paymentParams?.processingFee,
      gatewayName: "stripe",
    };
    console.log(".....Remaining Doctor Params......", params);

    remaining_Appointment_Doctors(params)
      .then((res: any) => {
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log("ErrorFromBackend", err?.response?.data);
      })
      .finally(() => setLoading(false));
  } else if (serviceName === "Lab") {
  } else if (serviceName == "donation") {
    setLoading(true);
    let body = {
      paymentId: paymentId,
      paidByUserAmount: paidAmount,
      donationAmount: arryObj?.requiredAmount,
      processingFee: paymentParams?.processingFee,
      gatewayName: "stripe",
    };
    let params = {
      packageId: arryObj?._id,
      companyId: arryObj?.donationId?._id,
    };
    postDonationAmount(body, params)
      .then(() => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          confirmStripePayment(secret, event);
        }, 2000);
      })
      .catch((err: any) => {
        console.log(err, "...error submit");
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName === "Lab") {
    console.log("Lab chala.........");
    let params = {
      paymentId: paymentId,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
      MR_NO: user?.mrNo,
      customerName: user?.name,
      prescription: "",
      currentLocation: {
        lat: location?.latitude,
        lng: location?.longitude,
        address: "",
        city: "",
      },
      vendorId: paymentParams?.labId,
      items: paymentParams?.item,
      // items: [
      //   {
      //     itemId: "66de8eac9638d0cbb02f7612",
      //     quantity: 1,
      //   },
      // ],
      preference: "visit",
      gatewayName: "stripe",
    };
    console.log(params, ".........Lab.....params,");
    confirm_Booking(params)
      .then((res: any) => {
        console.log("......res Laboratory", res?.data);
        setModalVisible(true);
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log("......err Laboratory", err?.response?.data);
      })
      .finally(() => setLoading(false));
  } else if (serviceName === "Ambulance") {
    let body: any = {
      paymentId: paymentId,
      paidByUserAmount: paidAmount,
      name: user?.name,
      email: user?.email,
      age: userAge,
      address: user?.addresses[0]?.address,
      phone: user?.phone,
      processingFee: paymentParams?.processingFee,
      gatewayName: "stripe",
    };
    acceptAmbulanceReq(paymentParams?.ambulanceId, body)
      .then((res: any) => {
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log(err?.response?.data, "...........errr");
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName == "pharmacy" || serviceName == "prescription") {
  } else if (serviceName == "pharmacy") {
    let items: any = [];

    arryObj?.map((i: any) => {
      items.push({
        id: i?._id,
        quantity: i?.quantity,
      });
    });

    setLoading(true);
    let params = {
      medicineIds: items,
      paymentId: paymentId,
      customerName: user?.name,
      amount: paymentParams?.actualAmount,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
      isPaidFull: "true",
      gatewayName: "stripe",
    };
    console.log(params, ".,...params prescription ");
    addPharmacyOrder(params)
      .then(() => {
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log("pharmacy order error.........", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
        // setRefreshing(false);
      });
  } else if (serviceName == "tour") {
    let params = {
      tourId: arryObj?.params?.tourId,
    };

    let body = {
      paymentId: paymentId,
      agencyId: arryObj?.item?.agencyId,
      from: arryObj?.item?.from,
      to: arryObj?.item?.to,
      email: user?.email,
      actualPrice: arryObj?.body?.actualPrice,
      packageName: arryObj?.item?.packageName,
      totalUser: Number(arryObj?.body?.totalUser),
      name: user?.name,
      age: arryObj?.body?.age,
      address: user?.addresses[0]?.address,
      gatewayName: "stripe",
      amount: paymentParams?.actualAmount,
      isPaidFull:
        paymentParams?.paymentType == "Partial Payment" ? false : true,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
    };
    console.log(params, body, ".....params, body");
    bookingTours(params, body)
      .then((res: any) => {
        confirmStripePayment(secret, event);
      })
      .catch((err: any) => {
        console.log("pharmacy order error.........", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName == "rentacar") {
    let requestData = {
      vehicleName: arryObj?.vehicleName,
      vehicleId: arryObj?.vehicleId,
      name: arryObj?.customerName,
      rentACarId: arryObj?.rentACarId,
      pickupLocation: arryObj?.pickupLocation,
      dropoffLocation: arryObj?.dropoffLocation,
      pickupDateTime: arryObj?.pickupDateTime,
      dropoffDateTime: arryObj?.dropoffDateTime,
      cnic: arryObj?.cnic,
      vehicleModel: arryObj?.vehicleModel,
      totalAmount: Number(arryObj?.totalAmount),
      phone: arryObj?.phone,
      age: arryObj?.age,
      withDriver: arryObj?.withDriver,
      paymentId: paymentId,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
      isPaidFull:
        paymentParams?.paymentType == "Partial Payment" ? false : true,
      gatewayName: "stripe",
    };
    postDetails(requestData)
      .then(() => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          confirmStripePayment(secret, event);
        }, 2000);
      })
      .catch((err: any) => {
        console.log(err.response.data.error, "........errorPosts");
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName == "insurance") {
    let params = {
      insuranceCompanyId: arryObj?.item?.insuranceId?._id,
      insuranceId: arryObj?.item?._id,
      userId: user?._id,
      userName: user?.name,
      mrNo: user?.mrNo,
      phone: user?.phone,
      location: {
        lat: user?.addresses[0]?.lat,
        lng: user?.addresses[0]?.lng,
        address: user?.addresses[0]?.address,
        city: user?.addresses[0]?.city,
      },
      cnic: arryObj?.cnic,
      insuranceKind: arryObj?.type?.toLowerCase(),
      insuranceFor: arryObj?.type?.toLowerCase(),
      cnicFile: arryObj?.cnicUrl,
      gatewayName: "stripe",
      paymentId: paymentId,
      amount: paymentParams?.actualAmount,
      isPaidFull:
        paymentParams?.paymentType == "Partial Payment" ? false : true,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
    };
    console.log(params, ".......insurance CHALA");
    confirmInsurance(params)
      .then(() => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          confirmStripePayment(secret, event);
        }, 2000);
      })
      .catch((err: any) => {
        console.log("error..", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (serviceName == "Hotel") {
    const params = {
      paymentId: paymentId,
      paidByUserAmount: paidAmount,
      processingFee: paymentParams?.processingFee,
      isPaidFull:
        paymentParams?.paymentType == "Partial Payment" ? false : true,
      gatewayName: "stripe",
      address: user?.addresses?.[0]?.address,
      age: arryObj?.params?.age,
      email: user?.email,
      hotelId: arryObj?.params.hotelId,
      name: user?.name,
      purpose: arryObj?.params?.purpose,
      ...(arryObj?.params?.serviceType == "apartment"
        ? { apartments: arryObj?.params?.rooms }
        : { rooms: arryObj?.params?.rooms }),
      arrivalDate: {
        from: hotelDetails?.fromDate,
        to: hotelDetails?.todate,
      },
      noOfGuest: hotelDetails.noOfGuest,
      serviceId: arryObj?.params?.serviceId,
      serviceType: arryObj?.params?.serviceType,
      totalAmount: arryObj?.params?.totalAmount,
    };

    console.log(".params Booking..........", params);

    addBookingRoom(params)
      .then((res: any) => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          confirmStripePayment(secret, event);
        }, 2000);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }
};
