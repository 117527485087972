import React, { useState } from "react";
import img from "assets/images/travelcard.png";
import img2 from "assets/images/hotelCard.jpg";

import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./CardStyle.module.css";
import { FaRegHeart } from "react-icons/fa";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoMdArrowForward,
} from "react-icons/io";
import { IoLocationSharp, } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import Upcoming from "./Upcoming";
import { useNavigate } from "react-router-dom";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";
import moment from "moment";
import { SlCalender } from "react-icons/sl";
// Sample card data array
// const cardData = [
//   {
//     id: "1",
//     title: "Malam Jabba Tour",
//     location: "Pakistan",
//     duration: "5 Days",
//     price: "15,500 PKR",
//     imgSrc: img,
//   },
//   {
//     id: "2",
//     title: "Kaghan Valley Tour",
//     location: "Pakistan",
//     duration: "4 Days",
//     price: "15,500 PKR",
//     imgSrc: img2,
//   },
//   {
//     id: "3",
//     title: "Hunza Valley Tour",
//     location: "Pakistan",
//     duration: "6 Days",
//     price: "15,500 PKR",
//     imgSrc: img,
//   },
//   {
//     id: "4",
//     title: "Swat Valley Tour",
//     location: "Pakistan",
//     duration: "3 Days",
//     price: "15,500 PKR",
//     imgSrc: img2,
//   },
//   {
//     id: "5",
//     title: "Fairy Meadows Tour",
//     location: "Pakistan",
//     duration: "7 Days",
//     price: "18,500 PKR",
//     imgSrc: img,
//   },
//   {
//     id: "6",
//     title: "Skardu Tour",
//     location: "Pakistan",
//     duration: "6 Days",
//     price: "17,500 PKR",
//     imgSrc: img2,
//   },
// ];

const TravelCards = ({ data }: { data?: any }) => {
  const [likedItemId, setLikedItemId] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleHeartClick = (id: string) => {
    setLikedItemId((prev) => (prev === id ? null : id));
  };

  // const handleNext = () => {
  //   if (currentIndex < cardData.length - 4) {
  //     setCurrentIndex(currentIndex + 1);
  //   }
  // };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const navigate = useNavigate();
  const handledetaile = (item:any) => {
    navigate("/services/travel/TravelDetail",{state:{item:item}});
  };
  const formatDateRange = (startDate: any, endDate: any) => {
    const start = moment(startDate).format('D MMM');
    const end = moment(endDate).format('D MMM YYYY');
    return `${start} - ${end}`;
  };
  return (
    <div>
      <div className={classNames()}>
        <div className={classNames(commonstyle.flx, commonstyle.flxBetween)}>
          <p
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.fs24,
              commonstyle.semiBold
            )}
            style={{ marginBottom: "16px" }}
          >
            Upcoming Schedule
          </p>
         
        </div>

        {/* Container for the cards */}
        {data?.length > 0 ? (
          <div className={classNames(style.cardContainer)}>
            {data.map(
              (item: any
              ) => {
                console.log(item,'............')
                return(
                <div
                  key={item.id}
                  className={classNames(style.card, style.roundedBackground)}
                  onClick={()=>handledetaile(item)}
                >
                  <div className={style.imgContainer}>
                    <img
                      src={
                        item.images[0]||
                        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"}
                      alt={item.title}
                      className={style.cardImage}
                    />
                    <div
                      className={style.heartIconContainer}
                      onClick={() => handleHeartClick(item.id)}
                    >
                      {likedItemId === item.id ? (
                        <FaHeart className={style.heartIcon} />
                      ) : (
                        <FaRegHeart className={style.heartIcon} />
                      )}
                    </div>
                  </div>
                  <div className={classNames(style.textcontainer)}>
                    <p
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16,
                        commonstyle.semiBold
                      )}
                    >
                      {item.packageName}
                    </p>
                    <div
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16,
                        commonstyle.semiBold
                      )}
                    >
                      <SlCalender
                        className={classNames(
                          commonstyle.colorBlue,
                          commonstyle.fs16,
                          commonstyle.semiBold
                        )}
                        style={{marginRight:"8px"}}
                      />
                      {formatDateRange(
                        item.departDate,
                        item.returnDate,
                      )}
                    </div>
                    <div>
                      <div
                        className={classNames(
                          commonstyle.flx,
                          commonstyle.flxBetween
                        )}
                      >

                        <div
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                        >
                          <IoLocationSharp
                            className={classNames(
                              commonstyle.colorGray,
                              commonstyle.fs16,
                              commonstyle.semiBold
                            )}
                          />
                          {item.from}
                        </div>
                        {/* <div
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs16,
                      commonstyle.semiBold
                    )}
                  >
                    {card.duration}
                  </div> */}
                      </div>
                      {/* <div className={style.showMoreContainer}>
                  <button className={style.showMoreButton}>
                    Details
                    <span className={style.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div> */}
                    </div>
                  </div>
                </div>
                )
              }
            )}
          </div>
        ) : (
          <div>{<PhysiotheristsEmpty />}</div>
        )
        }
      </div>
      {/* <Upcoming /> */}
    </div>
  );
};

export default TravelCards;
