import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import lstyle from "./Labdetail.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdHeartEmpty } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import img from "assets/images/ChughtaiLab logo 1.png";
import Footerr from "pages/Home/HomeNavBar/Footer";
import CardStyless from "../DoctarServices/Cards.module.css";
import { FaRegClock, FaRegCheckCircle } from "react-icons/fa";
import LaboratoriyProduct from "./LaboratoriyProduct";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getAll_AdminTestCategories,
  getAll_Tests,
  getSingle_UserLaboratory,
} from "shared/services/UserService";
import { LoadingModal } from "shared/components";

const LabortoryDetail = () => {
  const [activeButton, setActiveButton] = useState("about");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [productData, setProductData] = useState<any>([]);
  const [test, setTest] = useState<any>([]);

  const { location } = useSelector((state: any) => state?.root?.common);

  const { state }: any = useLocation();

  useEffect(() => {
    setLoading(true);
    getSingleLab();
  }, []);

  const getSingleLab = () => {
    let params = {
      labId: state?.id,
      lat: location?.latitude,
      long: location?.longitude,
    };

    getSingle_UserLaboratory(params)
      .then((res: any) => {
        setData(res?.data);
        allAdmin_Categories();
      })
      .catch((err: any) => {
        console.log("single lab err...", err?.response?.data);
      })
      .finally(() => setLoading(false));
  };

  const allAdmin_Categories = () => {
    getAll_AdminTestCategories()
      .then((res: any) => {
        setProductData(res?.data?.testCategories);
      })
      .catch((err: any) => {
        console.log("err...", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingModal showModal={loading} />;
  }

  const get_Test = (cat: any) => {
    let params = {
      labId: state?.id,
      categoryName: cat,
    };
    getAll_Tests(params)
      .then((res: any) => {
        setTest(res?.data?.tests);
      })
      .catch((err: any) => {
        // console.log('err...', err);
      })
      .finally(() => {
        // setLoading(false)
      });
  };

  return (
    <div>
      {/*  */}

      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Laboratory
        </p>
        {/* Breadcrumb Navigation */}
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Laboratory
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Product
          </p>
        </div>
      </div>

      <div className={classNames(commonstyles.container)}>
        <div className={classNames(lstyle.btnConatiner)}>
          <button
            className={classNames(
              lstyle.first,
              activeButton === "about" ? lstyle.active : lstyle.inactive
            )}
            onClick={() => setActiveButton("about")}
          >
            About
          </button>

          <button
            className={classNames(
              lstyle.secound,
              activeButton === "Product" ? lstyle.active : lstyle.inactive
            )}
            onClick={() => setActiveButton("Product")}
          >
            Product
          </button>
        </div>
        {activeButton === "about" && (
          <div style={{ display: "flex", width: "35%" }}>
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={data?.lab?.logo || img}
                  alt="card img"
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <div
                    className={classNames(
                      CardStyless.cardName,
                      commonstyles.colorBlue
                    )}
                  >
                    {data?.lab?.name}
                  </div>
                  <div>
                    <IoMdHeartEmpty
                      style={{ fontSize: 20, color: "#0d47a1" }}
                    />
                  </div>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegCheckCircle />
                  </span>
                  <span>Avaliable</span>
                </div>
                <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FaRegClock />
                  </span>
                  <span>
                    {data?.lab?.openTime}
                    {" - "}
                    {data?.lab?.closeTime}
                  </span>
                </div>
                {/* <div className={CardStyless.cardtime}>
                  <span className={CardStyless.timeIcon}>
                    <FiNavigation />
                  </span>
                  <span>500M from you</span>
                </div> */}
              </div>
            </div>
          </div>
        )}
        {activeButton === "about" && (
          <div>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Address
            </p>
            <p className={classNames(commonstyles.colorGray)}>
              {data?.lab?.location?.address}
            </p>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Description
            </p>
            <p className={classNames(commonstyles.colorGray)}>
              {data?.lab?.description}
            </p>
          </div>
        )}

        {/* {activeButton === "about" && (
                <ReviewComponent  />
         )} */}

        {/* Show LaboratoriyProduct only if "Product" button is active */}
        {activeButton === "Product" && (
          <LaboratoriyProduct
            productData={productData}
            get_Test={get_Test}
            test={test}
            labId={state?.id}
          />
        )}
      </div>
      <Footerr />
    </div>
  );
};

export default LabortoryDetail;
