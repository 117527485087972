import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./Detailstyle.module.css";
import parklane1 from "assets/images/parklane1.png";
import parklane2 from "assets/images/parklane2.png";
import parklane3 from "assets/images/parklane3.png";
import parklane4 from "assets/images/parklane4.png";
import parklane5 from "assets/images/parklane4.png";
import parklane6 from "assets/images/parklane4.png";
import hstyle from "./Hotel.module.css";
import { IoIosWifi } from "react-icons/io";
import { FaSpa, FaWater } from "react-icons/fa";
import { LuFlower2, LuClub } from "react-icons/lu";
import {
  FaUmbrellaBeach,
  FaPersonSwimming,
  FaBanSmoking,
} from "react-icons/fa6";
import { CgGym } from "react-icons/cg";
import { PiMosqueDuotone } from "react-icons/pi";
import ReviewComponent from "shared/components/DonationServices/ReviewComponent";
import { useLocation, useNavigate } from "react-router-dom";
import MainHeader from "shared/components/MainScreen/Index";
import { DetailsInfoHotel } from "shared/services";
import { useSelector } from "react-redux";

const images = [
  parklane1,
  parklane2,
  parklane3,
  parklane4,
  parklane5,
  parklane6,
];

interface Place {
  name: string;
  value: string;
}

const places: Place[] = [
  { name: "Vogue Towers", value: "0.6 mi" },
  { name: "Pace Shopping Mall", value: "0.6 mi" },
  { name: "Gaddafi Stadium", value: "0.6 mi" },
  { name: "Fortress Square", value: "0.6 mi" },
  { name: "Lahore Gymkhana", value: "0.6 mi" },
  { name: "Packages Mall", value: "0.6 mi" },
];


const amenityIcons: any = {
  'Free Wifi': <IoIosWifi />,
  Spa: <FaSpa />,
  // Garden: hotelPark,
  Beach: <FaUmbrellaBeach />,
  'Night Club': <LuClub />,
  Gym: <CgGym />,
  // 'Air Conditioning': photo1,
  // 'Flat Screen TV': photo2,
  // Balcony: photo3,
};
const icons = [
  { icon: <IoIosWifi />, name: "Wifi" },
  { icon: <FaSpa />, name: "Spa" },
  { icon: <LuFlower2 />, name: "Flower" },
  { icon: <FaUmbrellaBeach />, name: "Beach" },
  { icon: <LuClub />, name: "Club" },
  { icon: <CgGym />, name: "Gym" },
  { icon: <PiMosqueDuotone />, name: "Mosque" },
  { icon: <FaWater />, name: "Water" },
  { icon: <FaPersonSwimming />, name: "Swimming" },
  { icon: <FaBanSmoking />, name: "No Smoking" },
];
const HotelDetails = () => {
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false)
  const { hotelDetail } = useSelector((state: any) => state.root.common)
  let item = state ? state.item : null;
  console.log(item, "....item");
  const handledetails = () => {
    navigate("/services/hotel/HotelAvability",{state:{data}});
  };
  useEffect(() => {
    getHotelDetailsInfo()
  }, [])
  const getHotelDetailsInfo = () => {
    setLoading(true);
    const params = {
      id: item._id,
      serviceType: hotelDetail?.selectedOption?.toLowerCase(),
    };
    // console.log(params, '..............params');
    DetailsInfoHotel(params)
      .then((res: any) => {
        // console.log(res.data, '.......hotelDetails');
        setData(res.data.hotel);
      })
      .catch((err: any) => {
        console.log(err, '.........errorFromDetails');
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div>
      <MainHeader
        mainHeading="Hotel"
        breadcrumb={["Home", "Services", "Hotel", "Details"]}
      />

      <div
        className={classNames(
          commonstyles.container,
          commonstyles.flx,
          commonstyles.flxWrap,

          commonstyles.flxBetween
        )}
      >
        {/* Images Section */}
        <div className={classNames(commonstyles.col7, commonstyles.colsm12)}>
          {/* First Row - 2 images */}
          <p
            className={classNames(
              commonstyles.fs24,
              commonstyles.colorBlue,
              commonstyles.semiBold
            )}
          >
            <p
              className={classNames(
                commonstyles.fs24,
                commonstyles.colorBlue,
                commonstyles.semiBold
              )}
            >
              Park Lane Hotel Lahore
            </p>
          </p>
          <div className={classNames(dstyle.imgRow, dstyle.twoImagesRow)}>
            {data?.propertyphoto?.map((imgSrc: any, index: any) => (
              <div key={index} className={dstyle.imgContainer}>
                <img
                  src={imgSrc}
                  // alt={`Parklane ${index + 1}`}
                  className={dstyle.img}
                />
              </div>
            ))}
          </div>

          {/* <div className={classNames(dstyle.imgRow, dstyle.threeImagesRow)}>
            {images.slice(2, 5).map((imgSrc, index) => (
              <div
                key={index}
                className={classNames(dstyle.imgContainer, {
                  [dstyle.viewMoreContainer]: index === 2 && !showMore,
                })}
              >
                <img
                  src={imgSrc}
                  alt={`Parklane ${index + 3}`}
                  className={classNames(dstyle.img, {
                    [dstyle.viewMoreImg]: index === 2 && !showMore, // Hide 3rd image behind overlay
                  })}
                  onClick={() => setShowMore(true)} // Show more images on click
                />
                {index === 2 && !showMore && (
                  <div className={dstyle.viewMoreText}>View More</div> // Display "View More"
                )}
              </div>
            ))}
          </div> */}

          {/* Additional images (shown after clicking "View More") */}
          {/* {showMore && (
            <div className={classNames(dstyle.imgRow, dstyle.threeImagesRow)}>
              {images.slice(5).map((imgSrc, index) => (
                <div key={index} className={dstyle.imgContainer}>
                  <img
                    src={imgSrc}
                    alt={`Parklane ${index + 6}`}
                    className={dstyle.img}
                  />
                </div>
              ))}
            </div>
          )} */}
          <div className={dstyle.iconRow}>
            {data.amenities?.map((item: any, index: any) => (
              <div key={index} className={dstyle.iconCard}>
                <div className={dstyle.iconContainer}>{amenityIcons[item]}</div>
                <span className={dstyle.iconName}>{item}</span>
              </div>
            ))}
          </div>
          <div>
            <p
              className={classNames(commonstyles.fs14, commonstyles.colorGray)}
            >
              {data?.location?.address}
            </p>
          </div>
          <div className={classNames(commonstyles.mt16)}>
            <p
              className={classNames(
                commonstyles.fs14,
                commonstyles.colorBlue,
                commonstyles.semiBold
              )}
            >
              Staff Speaking
            </p>
            <p
              className={classNames(commonstyles.fs14, commonstyles.colorGray)}
            >
              {data?.language}
            </p>
            <div className={classNames(commonstyles.mt16)}>
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Property surroundings
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <div className={dstyle.placesContainer}>
                    {data.propertySurroundings?.map((place: any) => (
                      <div
                        key={place.value}
                        className={classNames(dstyle.placeItem)}
                      >
                        <span className={dstyle.placeName}>{place?.propertyName}</span>
                        <span className={dstyle.placeValue}>{place?.propertyDistance}</span>
                      </div>
                    ))}
                  </div>
                </ul>
              </div>
              <div>
                <p
                  className={classNames(
                    commonstyles.fs24,
                    commonstyles.colorBlue,
                    commonstyles.semiBold
                  )}
                >
                  Policy Against Accidental Booking
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorGray
                  )}
                >
                  {data?.advanceCancelfreeofCharge}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*  Content Section */}
        <div className={classNames(commonstyles.col4, commonstyles.colsm12)}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <p
              className={classNames(
                commonstyles.colorGreen,
                commonstyles.fs16,
                commonstyles.semiBold
              )}
            >
              Starting Price
            </p>
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs18,
                commonstyles.semiBold
              )}
            >
              PKR{' '}
              {item?.minRoomPrice
                ? item?.minRoomPrice
                : item?.minApartmentPrice
                  ? item?.minApartmentPrice
                  : item?.minHomePrice}
            </p>
            <p
              className={classNames(
                commonstyles.fs16,
                commonstyles.semiBold,
                commonstyles.colorBlue
              )}
            >
              Your Plan
            </p>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorGreen
              )}
            >
              {`${hotelDetail?.fromDate}-${hotelDetail?.fromDate}`}
            </p>
            <p
              className={classNames(
                commonstyles.fs18,
                commonstyles.semiBold,
                commonstyles.colorGreen
              )}
            >
              {`${hotelDetail?.roomsQuantity} room, ${hotelDetail?.adultsQuantity} adult`}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                marginTop: "210px ",
              }}
            >
              <div>
                <div
                  className={hstyle.showMoreContainer}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <p
                    className={classNames(
                      commonstyles.fs18,
                      commonstyles.semiBold,
                      commonstyles.colorGreen
                    )}
                  >
                    {`${data?.parkingAvailability}`}
                  </p>
                  <button
                    className={hstyle.showMoreButton}
                    onClick={handledetails}
                  >
                    See Availability
                    <span className={hstyle.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "32px",
              }}
            >
              <p
                className={classNames(
                  commonstyles.fs24,
                  commonstyles.colorBlue,
                  commonstyles.semiBold
                )}
              >
                Policies
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorGray
                )}
              >
                Check In From{' '}
                {`${data.policies?.checkInFrom} Until ${data.policies?.checkInTo}`} <br />
                Check Out From{' '}
                {`${data.policies?.checkOutFrom} Until ${data.policies?.checkOutTo}`}
              </p>
              <p
                className={classNames(
                  commonstyles.fs14,
                  commonstyles.colorGray,
                  commonstyles.mt16
                )}
              >
                {data?.advanceCancelfreeofCharge}
              </p>

              <div
                className={classNames(
                  commonstyles.flx,
                  commonstyles.flxBetween
                )}
              >
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue,
                    commonstyles.semiBold
                  )}
                >
                  Pets
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue
                  )}
                >
                  {data?.pets} Allowed
                </p>
              </div>
            </div>
            {data?.chargesOfPets &&
              <>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorGray,
                    commonstyles.mt16
                  )}
                >
                  Charges of Pets
                </p>
                <p
                  className={classNames(
                    commonstyles.fs14,
                    commonstyles.colorGray,
                    commonstyles.mt16
                  )}
                >
                  {data?.chargesOfPets}
                </p></>}
          </div>
        </div>
      </div>

      <div
        className={classNames(
          commonstyles.container,
          commonstyles.flx,
          commonstyles.flxWrap,

          commonstyles.flxBetween
        )}
      >
        {/* <ReviewComponent /> */}
      </div>

      <Footerr />
    </div>
  );
};

export default HotelDetails;
