import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./Appointment.module.css";
import Footerr from "../Footer";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../../assets/logo.png";
import DownloadImagelogo from "../../../../assets/images/DownloadImagelogo.png";
import { GetAll_Records, getUpcoming_Doctor } from "shared/services";
import moment from "moment";
import { useSelector } from "react-redux";

interface Appointment {
  id: number;
  name: string;
  specialization: string;
  qualifications: string[];
  clinic: string;
  date: string;
  status: string;
  imageUrl: string;
}

const appointments: Appointment[] = [
  {
    id: 1,
    name: "Dr. Wajahat Khan",
    specialization: "Gastroenterologist",
    qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
    clinic: "Fatima Memorial Clinic",
    date: "12/12/2023 11:48AM",
    status: "The remaining amount is pending for confirmation.",
    imageUrl: Avatar,
  },
  {
    id: 1,
    name: "Dr. Wajahat Khan",
    specialization: "Gastroenterologist",
    qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
    clinic: "Fatima Memorial Clinic",
    date: "12/12/2023 11:48AM",
    status: "The remaining amount is pending for confirmation.",
    imageUrl: Avatar,
  },
  {
    id: 1,
    name: "Dr. Wajahat Khan",
    specialization: "Gastroenterologist",
    qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
    clinic: "Fatima Memorial Clinic",
    date: "12/12/2023 11:48AM",
    status: "",
    imageUrl: Avatar,
  },
  {
    id: 1,
    name: "Dr. Wajahat Khan",
    specialization: "Gastroenterologist",
    qualifications: ["MBBS(Pb)", "FRCP (UK)", "MD (USA)", "MACP"],
    clinic: "Fatima Memorial Clinic",
    date: "12/12/2023 11:48AM",
    status: "",
    imageUrl: Avatar,
  },
  // Add more appointments as needed
];

const MyAppointment = React.memo((props) => {
  const navigate = useNavigate();
  const [visibleCount, setVisibleCount] = useState(2);
  const [activeTab, setActiveTab] = useState("Upcoming");
  const [data, setData] = useState([]);
  const [records, setRecords] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { token } = useSelector((state: any) => state?.root?.common);

  console.log(".......fcmToken", token);

  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "Upcoming") {
      UpcomingDoctor(1);
    } else {
      RecordDoctor(1);
      // handleGoToAppointmentRecord();
    }
  }, [activeTab]);

  const UpcomingDoctor = (pageNumber = 1) => {
    let params = {
      page: pageNumber,
    };
    getUpcoming_Doctor(params)
      .then((res: any) => {
        setData(res?.data?.latestAppointments);
      })
      .catch((err: any) => {
        console.log("err upcoming...", err.message.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RecordDoctor = (pageNumber = 1) => {
    let params = {
      page: pageNumber,
    };
    GetAll_Records(params)
      .then((res: any) => {
        setRecords(res?.data?.latestRecords);
        console.log("......res", res?.data);
      })
      .catch((err: any) => {
        console.log("err...", err?.response?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadMore = () => {
    setVisibleCount((prevCount) => prevCount + 2);
  };
  const handleGoToDetailAppointment = (i: any) => {
    if (activeTab == "Upcoming") {
      console.log("......i...", i);
      navigate("/services/Detail", {
        state: { data: i },
      });
    } else {
      navigate("/services/Prescription", {
        state: { id: i?._id },
      });
    }
  };
  let ApoointmentData = activeTab == "Upcoming" ? data : records;

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Appointments
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            My Appointments
          </p>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button
          className={
            activeTab == "Upcoming"
              ? `${styles.button}  ${styles.buttonUpcoming}
          `
              : styles.button
          }
          onClick={() => setActiveTab("Upcoming")}
        >
          Upcoming
        </button>
        <button
          className={
            activeTab == "Record"
              ? `${styles.button} ${styles.buttonUpcoming}`
              : styles.button
          }
          onClick={() => setActiveTab("Record")}
          style={{ cursor: "pointer" }}
        >
          Record
        </button>
      </div>

      <div className={styles.containerAppointment}>
        {ApoointmentData?.map((appointment: any, index: any) => (
          <div key={index}>
            <div
              className={styles.cardAppointment}
              onClick={() => handleGoToDetailAppointment(appointment)}
            >
              <img
                src={appointment?.doctorId?.doctorImage}
                alt={appointment?.doctorId?.name}
                className={styles.photoAppointment}
              />
              <div className={styles.infoAppointment}>
                <div className={styles.topInfo}>
                  <div className={styles.nameAppointment}>
                    {appointment?.doctorId?.name}
                  </div>
                  <div className={styles.dateAppointment}>
                    {moment(appointment?.appointmentDateAndTime).format(
                      "DD/MM/YYYY, hh:mm A"
                    )}
                  </div>
                </div>
                <div className={styles.specializationAppointment}>
                  {appointment?.doctorId?.speciality?.join(" ")}
                </div>
                <div className={styles.qualificationsAppointment}>
                  {appointment?.doctorId?.qualifications}
                </div>
                <div className={styles.clinicAppointment}>
                  {appointment?.doctorId?.clinicName}
                </div>
              </div>
            </div>
            <div className={styles.statusAppointment}>
              {appointment?.isPaidFull == false
                ? "The remaining amount is pending for confirmation."
                : null}
            </div>
          </div>
        ))}
      </div>

      {ApoointmentData?.length >= 10 && (
        <button onClick={loadMore} className={styles.loadMoreButton}>
          Load More
          <img
            src={DownloadImagelogo}
            alt="Logo"
            className={styles.downloadButtonLogo}
          />
        </button>
      )}

      <Footerr />
    </div>
  );
});

export default MyAppointment;
