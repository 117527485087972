import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { getUpcomingTours } from "shared/services";
import styles from "./paramedicStaff.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Datepicker from "shared/components/DatePicker";
import DownloadImagelogo from "../../../assets/images/DownloadImagelogo.png";
import CustomTimePicker from "shared/components/TimePicker/TimePICKER2";

const ParamedicStaff: React.FC = () => {
  const [activeTab, setActiveTab] = useState("tour");
  const [aptTime, setAptTime] = useState("");
  const [data, setData] = useState<any>([]);
  const [preferredDate, setPreferredDate] = useState<Date | null>(null);
  const [selectedSchedule, setSelectedSchedule] = useState<string>("");
  const handleScheduleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSchedule(event.target.value);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab === "tour") {
      allUpcoming();
    }
  }, [activeTab]);

  const allUpcoming = () => {
    getUpcomingTours(1)
      .then((res: any) => {
        console.log(res?.data);
        setData(res?.data?.upcomingSchedules);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        // Handle any additional logic
      });
  };

  return (
    <div>
      <MainHeader
        mainHeading="Paramedic Staff"
        breadcrumb={["Home", "Services", "Paramedic Staff"]}
      />
      <div className={styles.paramedicFormContainer}>
        <h2 className={styles.paramedicFormHeading}>Paramedic Form</h2>
        <form className={styles.paramedicForm}>
          <div className={styles.formSectionsContainer}>
            <div className={styles.leftFormSection}>
              <h3 className={styles.patientDetailsHeading}>
                Add Patient Details
              </h3>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Name</label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your name"
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Email</label>
                <input
                  type="email"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Contact
                </label>
                <input
                  type="tel"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your contact number"
                  required
                />
              </div>
              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>
                  Address
                </label>
                <input
                  type="text"
                  className={styles.inputFieldParamedicForm}
                  placeholder="Enter your address"
                  required
                />
              </div>
            </div>

            {/* Right Side: Additional Details */}
            <div className={styles.rightFormSection}>
              <div className={styles.inputGroupContainerParamedicForm}>
                <h4 className={styles.requirementsHeading}>
                  Paramedic Requirements
                </h4>
                <label className={styles.inputLabelParamedicForm}>Gender</label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="male"
                      className={styles.radioInputParamedicForm}
                      required
                    />
                    Male
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="gender"
                      value="female"
                      className={styles.radioInputParamedicForm}
                      required
                    />
                    Female
                  </label>
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicFormDate}>
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Preferred Date
                  </label>
                  <Datepicker
                    className={styles.timeInputParamedicForm}
                    placeholder="Select a date"
                  />
                </div>

                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Appointment Time
                  </label>
                  <div className={styles.timeInputParamedicFormTime}>
                    <CustomTimePicker placeholder="Time" setData={setAptTime} />
                  </div>
                </div>
              </div>

              <div className={styles.inputGroupContainerParamedicForm}>
                <label className={styles.inputLabelParamedicForm}>Area</label>
                <div className={styles.areaCityContainerParamedicForm}>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your area"
                    required
                  />
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your city"
                    required
                  />
                </div>
              </div>
              {/* Schedule Section */}
              <div
                className={`${styles.inputGroupContainerParamedicForm} ${styles.scheduleContainer}`}
              >
                <label className={styles.inputLabelParamedicForm}>
                  Schedule
                </label>
                <div className={styles.radioOptionsContainerParamedicForm}>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="day"
                      className={styles.radioInputParamedicForm}
                      onChange={handleScheduleChange}
                      required
                    />
                    Day
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="night"
                      className={styles.radioInputParamedicForm}
                      onChange={handleScheduleChange}
                      required
                    />
                    Night
                  </label>
                  <label className={styles.radioOptionLabelParamedicForm}>
                    <input
                      type="radio"
                      name="schedule"
                      value="other"
                      className={styles.radioInputParamedicForm}
                      onChange={handleScheduleChange}
                      required
                    />
                    Other
                  </label>
                </div>
              </div>

              {/* Conditionally render input for 'Other' schedule */}
              {selectedSchedule === "other" && (
                <div className={styles.inputGroupContainerParamedicForm}>
                  <label className={styles.inputLabelParamedicForm}>
                    Specify Schedule
                  </label>
                  <input
                    type="text"
                    className={styles.inputFieldParamedicForm}
                    placeholder="Enter your schedule"
                    required
                  />
                </div>
              )}
            </div>
          </div>
          <div className={styles.reviewContainerParamedicForm}>
            <label className={styles.inputLabelParamedicForm}>Remarks</label>
            <textarea
              className={styles.reviewTextAreaParamedicForm}
              placeholder="Write your Remarks here..."
              required
            />
          </div>

          <button type="submit" className={styles.submitButtonParamedicForm}>
            Submit
            <img
              src={DownloadImagelogo}
              alt="Logo"
              className={styles.downloadButtonLogo}
            />
          </button>
        </form>
      </div>

      <Footerr />
    </div>
  );
};

export default ParamedicStaff;
