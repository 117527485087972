import React, { useState } from "react";
import {
  IoCloudUploadOutline,
  IoClose,
  IoCheckmarkCircleOutline,
} from "react-icons/io5"; // Import icons
import { MdDeleteOutline } from "react-icons/md"; // Import delete icon
import { FaUpload } from "react-icons/fa"; // Import upload icon
import styles from "./index.module.css";
import classNames from "classnames";

interface FileUploadProps {
  allowedFileTypes?: string[];
  maxSizeInMB?: number;
  onFileSubmit?: (file: File) => void;
  onFileDelete?: () => void;
  customUploadText?: React.ReactNode;
}

const FileUpload: React.FC<FileUploadProps> = ({
  allowedFileTypes = ["image/jpeg", "image/png", "application/pdf"],
  maxSizeInMB = 10,
  onFileSubmit,
  onFileDelete,
  customUploadText,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false); // New state for success modal

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setError(
          `File type not allowed. Allowed types: ${allowedFileTypes.join(", ")}`
        );
        return;
      }
      if (file.size > maxSizeInMB * 1024 * 1024) {
        setError(`File size exceeds ${maxSizeInMB} MB.`);
        return;
      }
      setSelectedFile(file);
      setError("");
    }
  };

  const handleDeleteFile = () => {
    setIsModalOpen(true); // Open the modal instead of deleting the file directly
  };

  const confirmDeleteFile = () => {
    setSelectedFile(null);
    setIsModalOpen(false);
    if (onFileDelete) onFileDelete();
    setIsSuccessModalOpen(true); // Open success modal after deletion
  };

  return (
    <div>
      <div className={styles.fileUploadContainer}>
        {/* Upload Area */}
        {!selectedFile && (
          <label htmlFor="fileInput" className={styles.uploadArea}>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <IoCloudUploadOutline className={styles.uploadIcon} />
            {customUploadText || (
              <>
                <p>Select a file or drag and drop here</p>
                <p className={styles.uploadText}>
                  JPG, PNG or PDF, file size no more than {maxSizeInMB}MB
                </p>
              </>
            )}
            <button
              className={styles.selecttbtn}
              onClick={() => document.getElementById("fileInput")?.click()}
            >
              Select File
            </button>
          </label>
        )}

        {/* Error Message */}
        {error && <div className={styles.errorMessage}>{error}</div>}

        {/* File Details and Remove Button */}
        {selectedFile && (
          <div className={styles.fileDetails}>
            <FaUpload className={styles.uploadIcon} />
            <span>
              {selectedFile.name} - {Math.round(selectedFile.size / 1024)} kb
            </span>
            <button
              onClick={handleDeleteFile}
              className={styles.removeFileButton}
            >
              <MdDeleteOutline />
            </button>
          </div>
        )}
      </div>

      {/* Modal for Delete Confirmation */}
      {isModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <div className={styles.modalHeader}>
              <IoClose
                onClick={() => setIsModalOpen(false)}
                style={{ cursor: "pointer" }}
              />
            </div>

            <MdDeleteOutline className={styles.removeFileButton} />
            <p className={classNames(styles.sure)}>Are you sure</p>
            <span
              style={{
                color: "#00276D",
                fontSize: "16px",
              }}
            >
              you want to delete "{selectedFile?.name}"?
            </span>

            <div className={styles.modalButtons}>
              <button onClick={() => setIsModalOpen(false)}>Cancel</button>
              <button onClick={confirmDeleteFile}>Yes, Delete</button>
            </div>
          </div>
        </div>
      )}

      {/* Success Modal */}
      {isSuccessModalOpen && (
        <div className={styles.modalBackdrop}>
          <div className={styles.modalContainer}>
            <IoCheckmarkCircleOutline className={styles.tick} />
            <p
              style={{
                color: "#00276D",
                fontSize: "16px",
              }}
            >
              Your file has been successfully deleted!
            </p>
            {/* <button onClick={() => setIsSuccessModalOpen(false)}>Close</button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
