import AmbulanceSetting from "pages/HomeServices/AmbulanceServices/AmbulanceSetting";
import AmbulanceDashBoard from "pages/HomeServices/AmbulanceServices/AmbulanceDashBoard";
import AmbulanceDocuments from "pages/HomeServices/AmbulanceServices/AmbulanceDocument";
import AmbulancePayment from "pages/HomeServices/AmbulanceServices/AmbulancePayment";
import Ambulances from "pages/HomeServices/AmbulanceServices/Ambulances";
import AmbulaneDetail from "pages/HomeServices/AmbulanceServices/Ambulances/ambulaneDetail";
import AmbulanceRoutes from "pages/HomeServices/AmbulanceServices/OnRoute";
import AmbulanceRequest from "pages/HomeServices/AmbulanceServices/Request";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { SideBar } from "shared/components";
import PrivacyPolicy from "shared/components/PrivacyPolicy";
import { AmbulanceMenu } from "shared/utils";
import Chat from "shared/components/Chat";
import PaymentDescrition from "pages/Donation/DonationPayment/PaymentDescrition";
import Profile from "shared/components/Profile";
import RequestDetail from "pages/HomeServices/AmbulanceServices/Request/RequestDetail";
import { Ambulance_Payments_Details } from "pages";
const AmbulanceMainRoutes = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/ambulance/dashboard");
  }, []);
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        overflow: "auto",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div
        style={{
          position: "sticky",
          top: "0px",
          height: "100vh",
        }}
      >
        <SideBar menuItem={AmbulanceMenu} />
      </div>
      <Routes>
        <Route path="ambulance/dashboard" element={<AmbulanceDashBoard />} />
        <Route path="ambulance/ambulances" element={<Ambulances />} />
        <Route path="/ambulanceDetails/:id" element={<AmbulaneDetail />} />
        <Route path="ambulance/request" element={<AmbulanceRequest />} />
        <Route
          path="ambulance/request/Detail/:id"
          element={<RequestDetail />}
        />
        <Route path="ambulance/onroutes" element={<AmbulanceRoutes />} />
        <Route path="ambulance/documents" element={<AmbulanceDocuments />} />
        <Route path="ambulance/payments" element={<AmbulancePayment />} />
        <Route
          path="ambulance/paymentDetails" element={<Ambulance_Payments_Details />}
        />
        <Route path="/payment/Description" element={<PaymentDescrition />} />
        <Route path="ambulance/setting" element={<AmbulanceSetting />} />
        <Route path="ambulance/privacy" element={<PrivacyPolicy />} />
        <Route path="chat/message" element={<Chat />} />
        <Route path="/profile" Component={Profile} />
      </Routes>
    </div>
  );
};

export default AmbulanceMainRoutes;
