import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import hstyle from "./Hotel.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import LocationInput from "shared/components/LocationInput";
import Datepicker from "shared/components/DatePicker";
import { CustomInput, CustomModal } from "shared/components";
import { Radio } from "@mui/material";
import Hotelfirstpage from "./Hotelfirstpage";
import HotelProperty from "./HotelProperty";
import MainHeader from "shared/components/MainScreen/Index";
import { Navigate, useNavigate } from "react-router-dom";
import { setHotelDetail } from "shared/redux";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

const HotelServic = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [fromDate, setFromDate] = useState('')
  const [roomsQuantity, setRoomsQuantity] = useState(0);
  const [adultsQuantity, setAdultsQuantity] = useState(0);
  const [selectCity, setSelectCity] = useState('')
  const [todate, setToData] = useState('')
  const dispatch = useDispatch();
  const [childrenQuantity, setChildrenQuantity] = useState(0);
  const [appliedValues, setAppliedValues] = useState<AppliedValues | null>(
    null
  );
  const [error, setError] = useState<any>({
    city: '',
    fromDate: '',
    toDate: '',
    rooms: '',
    guests: '',
    selectedOption: '',
  })

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const handleOptionChange = (option: any) => {
    setSelectedOption(option);
  };
  const validateFields = () => {
    const newError = {
      city: selectCity ? '' : 'Please Select a city',
      fromDate: fromDate ? '' : 'Please Select a check-in date',
      toDate: todate ? '' : 'Please select a check-out date',
      rooms: roomsQuantity > 0 ? '' : 'Please select at least 1 room',
      guests: adultsQuantity > 0 ? '' : 'Please select at least 1 adult',
      selectedOption: selectedOption ? '' : 'Please select at least 1 accommodation type',
    };
    setError(newError);
    return Object.values(newError).every((error: any) => !error)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const handleShowMoreClick = () => {
    if (validateFields()) {
      let noOfGuest = adultsQuantity + childrenQuantity;
      dispatch(setHotelDetail({noOfGuest, selectedOption, selectCity, roomsQuantity, adultsQuantity, childrenQuantity, fromDate, todate }));
      navigate("/services/hotel/HotelProperty");
    }
  };

  const handleInputClick = () => {
    setShowDropdown(!showDropdown);
  };
  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setFromDate(formattedDate);
  };
  const handleTo = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setToData(formattedDate);
  };
  const handleDecrement = (type: string) => {
    if (type === "rooms" && roomsQuantity > 1) {
      setRoomsQuantity(roomsQuantity - 1);
    } else if (type === "adults" && adultsQuantity > 1) {
      setAdultsQuantity(adultsQuantity - 1);
    } else if (type === "children" && childrenQuantity > 0) {
      setChildrenQuantity(childrenQuantity - 1);
    }
  };

  const handleIncrement = (type: string) => {
    if (type === "rooms") {
      setRoomsQuantity(roomsQuantity + 1);
    } else if (type === "adults") {
      setAdultsQuantity(adultsQuantity + 1);
    } else if (type === "children") {
      setChildrenQuantity(childrenQuantity + 1);
    }
  };
  interface AppliedValues {
    rooms: number;
    adults: number;
    children: number;
  }
  const handleApply = () => {
    setAppliedValues({
      rooms: roomsQuantity,
      adults: adultsQuantity,
      children: childrenQuantity,
    });
    setShowDropdown(false);
  };
  const handleCitySelction = (value: any) => {
    setSelectCity(value?.city)
  }
  return (
    <div>
      <MainHeader
        mainHeading="Hotel"
        breadcrumb={["Home", "Services", "Hotel"]}
      />

      <div className={classNames(commonstyles.container)}>
        {/* {!showHotelProperty && ( */}
        <div className={classNames(hstyle.firstContainer)}>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              commonstyles.flxWrap
            )}
            style={{ marginTop: "20px", width: "100%" }}
          >
            <div
              className={classNames(
                commonstyles.colsm12,
                commonstyles.col3,
                commonstyles.colmd12
              )}
            >
              <LocationInput placeholder=" Address" setData={handleCitySelction} />
              {error.city && <span className={hstyle.errorText}>{error.city}</span>}
            </div>

            <div
              className={classNames(commonstyles.colsm12, commonstyles.colmd12)}
            >
              <Datepicker placeholder="Check-in date " setData={handleAptDate} />
              {error.fromDate && <span className={hstyle.errorText}>{error.fromDate}</span>}
            </div>

            <div
              className={classNames(commonstyles.colsm12, commonstyles.colmd12)}
            >
              <Datepicker placeholder="Check-out date " setData={handleTo} />
              {error.toDate && <span className={hstyle.errorText}>{error.toDate}</span>}
            </div>
            <div
              className={classNames(
                commonstyles.colsm12,
                commonstyles.col3,
                commonstyles.colmd12
              )}
            >
              <CustomInput
                placeholder={inputValue ? "" : "Guest's Room"}
                onClick={handleInputClick}
              />
              {appliedValues && (
                <span className={hstyle.appliedvaluestext}>
                  {appliedValues.rooms} Room(s), {appliedValues.adults}{" "}
                  Adult(s),
                  {appliedValues.children} Children
                </span>
              )}
              {error.rooms && <p className={hstyle.errorText}>{error.rooms}</p>}
              {error.adults && <p className={hstyle.errorText}>{error.adults}</p>}
              {showDropdown && (
                <div className={hstyle.dropdown}>
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs14,
                      commonstyles.demiBold
                    )}
                  >
                    Select rooms and guests
                  </p>
                  <div>
                    {/* Rooms section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Rooms
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("rooms")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>{roomsQuantity}</span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("rooms")}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Adults section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Adults
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("adults")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>
                          {adultsQuantity}
                        </span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("adults")}
                        >
                          +
                        </button>
                      </div>
                    </div>

                    {/* Children section */}
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs14,
                            commonstyles.demiBold
                          )}
                        >
                          Children
                        </p>
                      </div>
                      <div className={classNames(hstyle.viewQuantitybox)}>
                        <button
                          className={hstyle.decrementButton}
                          onClick={() => handleDecrement("children")}
                        >
                          -
                        </button>
                        <span className={hstyle.quantity}>
                          {childrenQuantity}
                        </span>
                        <button
                          className={hstyle.incrementButton}
                          onClick={() => handleIncrement("children")}
                        >
                          +
                        </button>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        commonstyles.flx,
                        commonstyles.flxCenter
                      )}
                    >
                      <button
                        className={classNames(hstyle.applybtn)}
                        onClick={handleApply}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxCenter,
              commonstyles.mt16
            )}
            style={{
              width: "100%",
            }}
          >
            <div
              className={classNames(commonstyles.flx, commonstyles.flxCenter)}
            >
              <div className={classNames()}>
                <span> Hotel</span>
                <span>
                  <Radio
                    checked={selectedOption === "Hotel"}
                    onChange={() => handleOptionChange("Hotel")}
                  />
                </span>
              </div>
              <div className={classNames()}>
                <span> Home</span>
                <span>
                  <Radio
                    checked={selectedOption === "Home"}
                    onChange={() => handleOptionChange("Home")}
                  />
                </span>
              </div>
              <div className={classNames()}>
                <span>Apartment</span>
                <span>
                  <Radio
                    checked={selectedOption === "Apartment"}
                    onChange={() => handleOptionChange("Apartment")}
                  />
                </span>
              </div>

            </div>

          </div>
          {error.selectedOption && <p className={hstyle.errorText} style={{alignSelf:'center'}}>{error.selectedOption}</p>}

          <div className={hstyle.showMoreContainer}>
            <button
              className={hstyle.showMoreButton}
              onClick={handleShowMoreClick}
            >
              Continue
              <span className={hstyle.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </div>
        {/* )} */}
        {/* {!showHotelProperty &&  */}

        <Hotelfirstpage />

        {/* {showHotelProperty &&  */}

        {/* <HotelProperty /> */}

        {/* } */}
      </div>
      <Footerr />
    </div>
  );
};

export default HotelServic;
