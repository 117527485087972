import DoctorServices from "pages/Services/DoctarServices";
import DoctorAppoinmentPay from "pages/Services/DoctarServices/DoctorAppoinment";
import DoctorDetail from "pages/Services/DoctarServices/DoctorDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";

const DoctorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<DoctorServices />} />
      <Route
        path="/doctor-services/:serviceName"
        element={<DoctorServices />}
      />

      {/* <Route path="doctor/:type" element={<DoctorServices />} /> */}
      <Route path="/DoctorDetail" element={<DoctorDetail />} />
      <Route
        path="/DoctorAppoinmentPay/:id"
        element={<DoctorAppoinmentPay />}
      />
    </Routes>
  );
};

export default DoctorRoutes;
