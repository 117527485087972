import React, { useState } from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./AmbulanceStyle.module.css";
import { MdDeleteOutline } from "react-icons/md";
import img from "assets/images/Edhilogo.png";
import { useNavigate } from "react-router-dom";
import { bidReqGet, declineAmbulance, deleteRequest } from "shared/services";
import { DeleteModal, LoadingModal, RingLoader } from "shared/components";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";

const Ambulance = ({ data }: { data?: any }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedAmbulanceIndex, setSelectedAmbulanceIndex] =
    useState<any>(null);
  const [bidData, setBidData] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState<any>(false);
  const [ambulanceReqId, setAmbulanceReqId] = useState<any>("");
  const [decline, setDecline] = useState<any>(false);
  const { isLoggedIn } = useSelector((state: any) => state?.root?.common);

  const handleBidsClick = async (index: any, id: any) => {
    await setBidData([]);
    setSelectedAmbulanceIndex(index);
    setLoading(true);
    let params: any = {
      requestId: id,
    };
    setTimeout(() => {
      bidReqGet(params)
        .then((res: any) => {
          // console.log(res?.data?.bidRequests, '................');
          const filteredBids = res.data?.bidRequests.filter(
            (bid: any) => bid.status !== "rejected"
          );
          setBidData(filteredBids);
        })
        .catch((err: any) => {})
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
  };

  // const handlepayment = () => {
  //   navigate("/services/myRequest/BookingPayments");
  // };

  const openModal = (id: any) => {
    setAmbulanceReqId(id);
    setModalVisible(true);
  };

  const onCanelModal = () => {
    setModalVisible(false);
  };

  const onDelete_AmbRequest = () => {
    setLoading(true);
    let params = {
      requestId: ambulanceReqId,
    };

    deleteRequest(params)
      .then((res: any) => {
        // console.log(res?.data);
        setBidData(bidData.filter((item: any) => item._id !== ambulanceReqId));
        setModalVisible(false);
      })
      .catch((err: any) => {
        console.log(err, "......error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAcceptBid = async (item: any) => {
    console.log(".iiiiiii", item);
    await dispatch(setObj(item));

    if (isLoggedIn) {
      navigate("/services/paymentDetail", {
        state: {
          serviceName: "Ambulance",
          actualAmount: item?.price,
          ambulanceId: item?._id,
        },
      });
    } else {
      navigate("/user/login");
    }
  };

  const declineRequest = (i: any) => {
    setDecline(true);
    let params = {
      requestId: i?._id,
    };
    declineAmbulance(params)
      .then((res: any) => {
        setBidData(bidData.filter((item: any) => item._id !== i?._id));
      })
      .catch((err: any) => {
        console.log(err, "........error");
      })
      .finally(() => {
        setDecline(false);
      });
  };

  if (decline) {
    return <LoadingModal showModal={decline} />;
  }

  return (
    <div className={classNames(commonstyle.container)}>
      {data?.map((ambulance: any, index: any) => {
        return (
          <div
            key={index}
            className={classNames(style.mainconatiner)}
            style={{
              backgroundColor:
                selectedAmbulanceIndex === index ? "#FDCB2E" : "transparent",
            }}
          >
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <div className={classNames(commonstyle.col5)}>
                <p className={classNames(style.pickdrop)}>Pickup Location</p>
                <p className={classNames(style.pickupdetail)}>
                  {ambulance?.pickUp?.address}
                </p>
              </div>
              <div className={classNames(commonstyle.col5)}>
                <p
                  className={classNames(style.pickdrop)}
                  style={{ whiteSpace: "nowrap" }}
                >
                  Drop off Location
                </p>
                <p className={classNames(style.pickupdetail)}>
                  {ambulance?.dropOff?.address}
                </p>
              </div>
              <div
                className={classNames(style.icon)}
                onClick={() => openModal(ambulance?._id)}
              >
                <MdDeleteOutline />
              </div>
            </div>
            <div className={classNames(style.bidcontainer)}>
              <button
                className={classNames(style.bidsBtn)}
                onClick={() => handleBidsClick(index, ambulance?._id)} // Pass index to handleBidsClick
              >
                {loading && selectedAmbulanceIndex === index ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  " Bids"
                )}
              </button>
            </div>
            {selectedAmbulanceIndex === index && ( // Only show bids for the selected ambulance
              <div>
                {bidData.map((ambulance: any, index: any) => (
                  <div key={index}>
                    <div className={classNames(style.detailconatiner)}>
                      <div className={classNames()}>
                        <div
                          className={classNames(
                            commonstyle.flx,
                            commonstyle.flxBetween
                          )}
                        >
                          <div className={classNames(commonstyle.flx)}>
                            <div className={classNames(style.imgcontainer)}>
                              <img
                                src={ambulance?.ambulanceId?.logo}
                                alt={`${ambulance?.name} logo`}
                                className={style.ambulanceimg}
                              />
                            </div>
                            <div>
                              <p
                                className={classNames(style.pickupdetail)}
                                style={{ whiteSpace: "nowrap" }}
                              >
                                {ambulance?.ambulanceId?.name}
                              </p>
                            </div>
                          </div>
                          <div>
                            <p className={classNames(style.pickupdetail)}>
                              {ambulance?.price}
                            </p>
                          </div>
                        </div>

                        <div
                          className={classNames(
                            commonstyle.flx,
                            commonstyle.flxBetween
                          )}
                        >
                          <div>
                            <p className={classNames(style.pickupdetail)}>
                              {ambulance?.ambulanceName}
                            </p>
                          </div>
                          <div>
                            <p className={classNames(style.pickupdetail)}>
                              {ambulance?.ambulanceNo}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Buttons placed outside of the .detailcontainer */}
                    <div className={classNames(style.btncontainer)}>
                      <button
                        className={classNames(style.firstbtn)}
                        onClick={() => declineRequest(ambulance)}
                      >
                        Decline
                      </button>
                      <button
                        className={classNames(style.secoundbtn)}
                        onClick={() => onAcceptBid(ambulance)}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                ))}
                {loading || bidData?.length !== 0 ? null : "No Bids Found"}
              </div>
            )}
          </div>
        );
      })}

      <DeleteModal
        modalVisible={modalVisible}
        handleCancel={onCanelModal}
        handleDelete={onDelete_AmbRequest}
        loading={loading}
      />
    </div>
  );
};

export default Ambulance;
