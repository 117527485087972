import React, { useState } from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./TravelStyle.module.css";
import { MdDeleteOutline } from "react-icons/md";
import img from "assets/images/Edhilogo.png";
import { Link, useNavigate } from "react-router-dom";
import logo from "assets/images/emirets.png";
import { BiSolidPlaneAlt } from "react-icons/bi";

const travelData = [
  {
    id: 1,
    name: "Ahan Travel & Tours",
    price: "PKR 624,410",
    travelName: "Fly Emirates",
    license: "LEA 5690",
    img: img, // Assume img is already imported or defined
    fromCity: "Lahore",
    toCity: "Dubai",
    travelTime: "5hr 20m",
  },
];

const TravelRequest = ({ data }: { data?: any }) => {
  const [showBids, setShowBids] = useState(false);

  const handleBidsClick = () => {
    setShowBids(true);
  };
  const navigate = useNavigate();
  const handledetail = () => {
    // navigate("services/myrequest/TravellDetail");
    navigate("/services/myRequest/TravellDetail");
  };

  console.log(".......Flight data.........", data);

  return (
    <div>
      {/* <div className={classNames(commonstyle.container)}> */}
      <div className={classNames(commonstyle.container)}>
        {data?.map((flight: any) => (
          <>
            <div
              className={classNames(style.mainconatiner)}
              style={{
                backgroundColor: showBids ? "#FDCB2E" : "transparent",
              }}
            >
              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div>
                  <p className={classNames(style.pickdrop)}>Flight Type</p>
                  <p className={classNames(style.pickupdetail)}>
                    {flight?.requestType}
                  </p>
                </div>

                <div className={classNames(style.icon)}>
                  <MdDeleteOutline />
                </div>
              </div>

              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div>
                  <p className={classNames(style.pickdrop)}>From</p>
                  <p className={classNames(style.pickupdetail)}>Lahore</p>
                </div>
                <div>
                  <p className={classNames(style.pickdrop)}>To</p>
                  <p className={classNames(style.pickupdetail)}>Dubai</p>
                </div>
                <div>
                  <p className={classNames(style.pickdrop)}>Deparatur</p>
                  <p className={classNames(style.pickupdetail)}>10/07/2024</p>
                </div>
                <div>
                  <p className={classNames(style.pickdrop)}>Class</p>
                  <p className={classNames(style.pickupdetail)}>Economy</p>
                </div>
                <div>
                  <p className={classNames(style.pickdrop)}>Traveler</p>
                  <p className={classNames(style.pickupdetail)}>4 Traveler</p>
                </div>
              </div>
            </div>

            {!showBids && (
              <div className={classNames(style.bidcontainer)}>
                <button
                  className={classNames(style.bidsBtn)}
                  onClick={handleBidsClick}
                >
                  Bids
                </button>
              </div>
            )}
          </>
        ))}

        {/* Display travel data when the Bids button is clicked */}
        {showBids && (
          <div>
            {travelData.map((travel) => (
              <div key={travel.id}>
                <div className={classNames(style.detailconatiner)}>
                  <div>
                    {/* Header: Travel Image, Name, and Price */}
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div className={classNames(commonstyle.flx)}>
                        {/* Travel Image */}
                        <div className={classNames(style.imgcontainer)}>
                          <img
                            src={travel.img}
                            alt={`${travel.name} logo`}
                            className={style.travelimg}
                          />
                        </div>
                        {/* Travel Name */}
                        <div>
                          <p
                            className={classNames(style.pickupdetail)}
                            style={{ whiteSpace: "nowrap" }}
                          >
                            {travel.name}
                          </p>
                        </div>
                      </div>
                      {/* Travel Price */}
                      <div>
                        <p className={classNames(style.pickupdetail)}>
                          {travel.price}
                        </p>
                      </div>
                    </div>

                    {/* Travel Information Section */}
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div className={classNames(commonstyle.flx)}>
                        {/* Travel Company Logo */}
                        <div className={classNames(style.imgcontainer)}>
                          <img
                            src={logo}
                            alt="not available"
                            style={{ width: "24px", height: "33px" }}
                          />
                        </div>
                        {/* Travel Name */}
                        <p
                          className={classNames(style.pickupdetail)}
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {travel.travelName}
                        </p>
                      </div>

                      {/* Travel Route and Time */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "40%",
                          position: "relative",
                          color: "#0e54a3",
                        }}
                      >
                        {/* Left City */}
                        <div style={{ marginRight: "10px", fontSize: "18px" }}>
                          {travel.fromCity}
                        </div>

                        {/* Plane Icon and Time */}
                        <div
                          style={{
                            position: "relative",
                            flexGrow: 1,
                            height: "1px",
                            borderTop: "2px dotted #0e54a3",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* Plane Icon */}
                          <BiSolidPlaneAlt
                            style={{
                              position: "absolute",
                              top: "-33px",
                              fontSize: "24px",
                            }}
                          />

                          {/* Travel Time */}
                          <div
                            style={{
                              position: "absolute",
                              top: "15px", // Position below the dotted line
                              fontSize: "14px",
                              color: "#0e54a3",
                            }}
                          >
                            {travel.travelTime}
                          </div>
                        </div>

                        {/* Right City */}
                        <div style={{ marginLeft: "10px", fontSize: "18px" }}>
                          {travel.toCity}
                        </div>
                      </div>

                      {/* License Placeholder (commented out) */}
                      <div>
                        {/* <p className={classNames(style.pickupdetail)}>
                      {travel.license}
                    </p> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      commonstyle.flx,
                      commonstyle.flxCenter,
                      commonstyle.mt16
                    )}
                  >
                    <div
                      className={classNames(
                        commonstyle.colorBlue,
                        commonstyle.fs16,
                        commonstyle.semiBold
                      )}
                      style={{
                        borderBottom: "1px solid #0e54a3",
                      }}
                      onClick={handledetail}
                    >
                      <a>View Detail</a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TravelRequest;
