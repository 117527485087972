import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IoMdArrowForward } from "react-icons/io";
import { useFormik } from "formik";
import classNames from "classnames";
import style from "./Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import PayStyle from "./Appointment.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import Vector from "assets/images/Vector.png";
import img from "assets/images/Avatar.png";
import CardStyless from "./Cards.module.css";
import CommonStyless from "shared/utils/common.module.css";
import { AppointmentSchema } from "shared/utils/constants";
import { useDispatch } from "react-redux";
import { setHospitalId, setObj, setPaymentParams } from "shared/redux";
import { get_Hospital_Price } from "shared/services/UserService";

const DoctorAppoinmentPay: React.FC = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();

  let serviceName = state.serviceName;
  let doc = state?.data;

  const [activeTab, setActiveTab] = useState<any>(null);
  const [activeHospital, setActiveHospital] = useState<any>("");
  const [underlineStyle, setUnderlineStyle] = useState<{
    left: number;
    width: number;
  }>({ left: 0, width: 0 });

  const navButtonsRef = useRef<HTMLDivElement>(null);
  const [actualAmount, setActualAmount] = useState<any>();
  const [hospError, setHospError] = useState<any>("");

  useEffect(() => {
    if (navButtonsRef.current) {
      const buttons = Array.from(
        navButtonsRef.current.children
      ) as HTMLElement[];
      const activeButton = buttons.find(
        (button) => button.innerText === activeTab?.type
      );

      if (activeButton) {
        setUnderlineStyle({
          left: activeButton.offsetLeft,
          width: activeButton.offsetWidth,
        });
      }
    }
  }, [activeTab?.type]);

  const handleTabClick = (item: any) => {
    // console.log("....item", item);
    if (item?.type !== "hospital") {
      setHospError("");
      setActiveHospital("");
    }
    if (item?.type == "hospital") {
      setActualAmount(0);
    }
    setActualAmount(item?.price);
    setActiveTab(item);
    formik.setFieldValue("appointmentType", item?.type);
  };

  // console.log("...serviceName", serviceName);

  const handleHospitalClick = (i: any) => {
    setHospError("");
    setActiveHospital(i);
    dispatch(setHospitalId(i?._id));
    get_Hospital_Price(i?._id, doc?.doctor?._id)
      .then((res: any) => {
        // console.log("....res?.data?.actualPrice", res?.data?.actualPrice);
        setActualAmount(res?.data?.actualPrice);
      })
      .catch((err: any) => {
        // console.log('.......', err?.response?.data?.message);
      })
      .finally(() => {});
  };

  const handleGoToPaymentNavbar = async (values: any) => {
    if (values?.appointmentType == "hospital" && activeHospital == "") {
      setHospError("Please select hospital");
    } else {
      await dispatch(setObj(doc?.doctor));

      navigate("/services/paymentDetail", {
        state: {
          serviceName: serviceName?.toLowerCase(),
          actualAmount: actualAmount,
          appointmentType: activeTab?.type,
          doctorId: doc?.doctor?._id,
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      appointmentType: "",
    },
    validationSchema: AppointmentSchema,
    onSubmit: (values) => {
      console.log(values);
      handleGoToPaymentNavbar(values);
    },
  });

  if (!doc) {
    return <div>Doctor not found</div>;
  }

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Doctor
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            {serviceName}
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Appointment
          </p>
        </div>
      </div>

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        {/* <div className={classNames(PayStyle.progressContainer)}>
          <div className={classNames(PayStyle.circleWrapper)}>
            <div className={classNames(PayStyle.progressCircle1)}>1</div>
            <span className={classNames(PayStyle.circleText)}>Booking</span>
          </div>

          <div className={classNames(PayStyle.line)}></div>

          <div className={classNames(PayStyle.circleWrapper)}>
            <div className={classNames(PayStyle.progressCircle2)}>2</div>
            <span className={classNames(PayStyle.circleText)}>payment</span>
          </div>
        </div> */}

        <div>
          <div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxBetween,
              PayStyle.container
            )}
          >
            <div>
              <div className={classNames(CardStyless.cardWrapper)}>
                <div className={CardStyless.cardImageWrapper}>
                  <img
                    src={
                      doc?.doctor?.doctorImage ? doc?.doctor?.doctorImage : img
                    }
                    alt={doc?.doctor?.name}
                    className={CardStyless.cardImage}
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div className={CommonStyless.flxBetween}>
                    <div className={CardStyless.cardName}>
                      {doc?.doctor?.name}
                    </div>
                    <div>
                      <img
                        src={Vector}
                        // alt="Vector icon"
                        className={CardStyless.vectorIcon}
                      />
                    </div>
                  </div>

                  <div className={CardStyless.cardTitle}>
                    {doc?.doctor?.qualifications}
                  </div>
                  <div className={CardStyless.cardDetail}>
                    {doc?.doctor?.speciality}
                  </div>
                  <div className={CardStyless.cardExperience}>
                    {doc?.doctor?.clinicExperience}
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <span className={CardStyless.ratingContainer}>
                      <span className={CardStyless.cardRating}>*****</span>
                    </span>
                    <span className={CardStyless.cardRatingValue}>
                      {doc?.doctor?.rating}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classNames(
            commonstyles.fs16,
            commonstyles.colorBlue,
            commonstyles.semiBold
          )}
        >
          <p>Appointment Type</p>
        </div>

        <div className={PayStyle.navBar} ref={navButtonsRef}>
          {doc?.appointmentType?.map((item: any, index: any) => (
            <button
              key={index}
              className={classNames(PayStyle.navButton, {
                [PayStyle.active]: activeTab?.type === item?.type,
              })}
              onClick={() => handleTabClick(item)}
            >
              {item?.type}
            </button>
          ))}
          <div
            className={PayStyle.underline}
            style={{
              left: `${underlineStyle.left}px`,
              width: `${underlineStyle.width}px`,
            }}
          ></div>
        </div>

        {formik.touched.appointmentType && formik.errors.appointmentType ? (
          <div className={classNames(commonstyles.error)}>
            *{formik.errors.appointmentType}
          </div>
        ) : null}

        {hospError && (
          <div className={classNames(commonstyles.error)}>*{hospError}</div>
        )}

        {activeTab?.type == "hospital" && (
          <div className={classNames(PayStyle.hospitalBar, commonstyles.mt16)}>
            {doc?.hospitals?.map((item: any, index: any) => (
              <button
                key={index}
                className={classNames(PayStyle.hospitalButton, {
                  [PayStyle.active]: activeHospital?.name === item?.name,
                })}
                onClick={() => handleHospitalClick(item)}
              >
                {item?.name}
              </button>
            ))}
          </div>
        )}

        <form onSubmit={formik.handleSubmit}>
          <div className={PayStyle.showMoreContainer}>
            <button
              className={CardStyless.showMoreButton}
              style={{
                marginBottom: "20px",
              }}
            >
              Continue
              <span className={CardStyless.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </form>
      </div>

      <Footerr />
    </div>
  );
};

export default DoctorAppoinmentPay;
