import React from 'react'
import { IoMdArrowForward } from 'react-icons/io'
import hstyle from './styles.module.css'
import classNames from 'classnames'
import commonstyles from "shared/utils/common.module.css";
// import astyle from "./styles.module.css";

const RoomComponent = ({ onClick, selectedCard, name, rooms, price, value1, item, dinningRoom, size }: { onClick?: any, selectedCard?: any, name?: any, rooms?: any, price?: any, value1?: any, item?: any, dinningRoom?: any, size?: any }) => {
    return (
        <div className={hstyle.cardContainer}>
            <div
                style={{
                    border:
                        selectedCard === name
                            ? "1px solid blue"
                            : "transparent",
                }}
                className={classNames(hstyle.card, {
                })}
                onClick={onClick}
            >
                <p
                    className={classNames(
                        commonstyles.fs24,
                        commonstyles.colorBlue,
                        commonstyles.semiBold
                    )}
                >
                    {name}
                </p>
                <p
                    className={classNames(
                        commonstyles.fs14,
                        commonstyles.colorGray
                    )}
                >
                    {value1}
                </p>

                <div className={classNames(commonstyles.mt16)}>
                    <p
                        className={classNames(
                            commonstyles.fs14,
                            commonstyles.colorGreen
                        )}
                    >
                        Fecilities
                    </p>
                    {item?.facilities.map((feature: any, i: any) => (
                        <p
                            key={i}
                            className={classNames(
                                commonstyles.fs14,
                                commonstyles.colorGray
                            )}
                        >
                            {feature}
                        </p>
                    ))}
                </div>
                <div>
                    <p
                        className={classNames(
                            commonstyles.colorGreen,
                            commonstyles.fs16,
                            commonstyles.semiBold
                        )}
                    >
                        {dinningRoom}
                    </p>
                </div>
                <div>
                    <p
                        className={classNames(
                            commonstyles.colorGreen,
                            commonstyles.fs16,
                            commonstyles.semiBold
                        )}
                    >
                        {size}
                    </p>
                </div>
                {/* <div className={dstyle.iconRow}>
      {icons.map((item, idx) => (
        <div key={idx} className={dstyle.iconCard}>
          <div className={dstyle.iconContainer}>{item.icon}</div>
          <span className={dstyle.iconName}>{item.name}</span>
        </div>
      ))}
    </div> */}
                <div>
                    <p
                        className={classNames(
                            commonstyles.colorOrange,
                            commonstyles.fs16,
                            commonstyles.semiBold
                        )}
                    >
                        {rooms}
                    </p>
                </div>
                <div>
                    <p
                        className={classNames(
                            commonstyles.colorGreen,
                            commonstyles.fs16,
                            commonstyles.semiBold
                        )}
                    >
                        Price Per Night
                    </p>
                    <p
                        className={classNames(
                            commonstyles.colorBlue,
                            commonstyles.fs16,
                            commonstyles.semiBold
                        )}
                    >
                        {price}
                    </p>
                </div>


                <div className={hstyle.showMoreContainer}>
                    <button
                        className={hstyle.showMoreButton}
                        onClick={onClick}
                    >
                        Select number of Rooms.
                        <span className={hstyle.icon}>
                            <IoMdArrowForward />
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RoomComponent