import React from "react";
import style from "./index.module.css"; // Ensure you import necessary styles
import commonstyle from "shared/utils/common.module.css";
import { FaHeart } from "react-icons/fa6";
import RatingStar from "shared/RatingStar";
import { IoMdTime } from "react-icons/io";
import { IoLocationOutline } from "react-icons/io5";
import cardimg from "assets/images/RentaCarcard.png";
// Define the props for the Card component
interface CardProps {
  title: string;
  subtitle: string;
  details: string;
  rating?: number;
  imgSrc: string; // Ensure that imgSrc is a required prop
}

const Card: React.FC<CardProps> = ({ title, subtitle, details, rating, imgSrc }) => {
  return (
    <div className={style.cardWrapper}>
      <div className={style.cardBody}>
        <div className={`${style.imgContainer} ${commonstyle.col3} ${commonstyle.colsm3}`}>
          <img src={imgSrc} alt={title} className={style.cardImg} />
        </div>

        <div className={`${style.textContainer} ${commonstyle.col9} ${commonstyle.colsm9}`}>
          {/* Title and Favorite Icon */}
          <div className={`${commonstyle.flx} ${commonstyle.flxBetween}`}>
            <p className={`${commonstyle.colorBlue} ${commonstyle.fs24} ${commonstyle.semiBold}`}>
              {title}
            </p>
            <FaHeart className={style.hearticon} />
          </div>

          {/* Subtitle with Icon */}
          <div className={commonstyle.flx}>
            <IoMdTime className={style.icons} />
            <p className={style.subtitle}>{subtitle}</p>
          </div>

          {/* Details with Location Icon */}
          <div className={commonstyle.flx}>
            <IoLocationOutline className={style.icons} />
            <p className={style.details}>{details}</p>
          </div>

          {/* Footer with Rating */}
          <div className={`${commonstyle.flx} ${style.footer}`}>
            {rating && <RatingStar rating={rating} />}
            {rating && (
              <span style={{ marginLeft: "10px" }}>
                {rating}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
