import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import RingLoader from "../RingLoader";
import style from "./DeleteModalStyle.module.css";

interface Props {
  modalVisible?: any;
  handleDelete?: any;
  handleCancel?: any;
  loading?: any;
}

const DeleteModal = (props: Props) => {
  const { modalVisible, handleDelete, handleCancel, loading } = props;

  return (
    modalVisible && (
      <div className={classNames(style.modalBackdrop)}>
        <div className={classNames(style.modalContainer)}>
          <div className={classNames(commonstyle.flx, commonstyle.flxCol)}>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs24,
                commonstyle.semiBold
              )}
            >
              Are You sure?
            </p>
            <p
              className={classNames(
                commonstyle.colorGray,
                commonstyle.fs16,
                commonstyle.semiBold
              )}
            >
              You want to delete this "Request"
            </p>
            <div
              className={classNames(commonstyle.flx, commonstyle.flxBetween)}
            >
              <button className={style.cancelbtn} onClick={handleCancel}>
                No Cancel
              </button>
              <button className={style.dltbtn} onClick={handleDelete}>
                {loading ? (
                  <RingLoader color={"#fff"} size={30} />
                ) : (
                  "Yes Delete"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DeleteModal;
