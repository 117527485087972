import React from "react";
import { useNavigate } from "react-router-dom";
import InsuranceForm from "shared/components/Insuranceuser/Insuranceform";

const InsuranceHealth = () => {
  const handleSelectPlan = (selectedOption: string) => {
    console.log("Plan selected in InsuranceHealth:", selectedOption);
  };

  const insuranceOptions = ["Family Plan", "Individual Plan", "Parents Plan"];
  const navigate = useNavigate();

  return (
    <div>
      <InsuranceForm
        insuranceOptions={insuranceOptions}
        onSelectPlan={handleSelectPlan}
        title="Best Health Plan for"
        subtitle="Myself, Family and Parents"
        formtitle="health"
        formType="health" // Ensure formType is passed as 'health'
      />
    </div>
  );
};

export default InsuranceHealth;
