import React, { useState } from "react";
import classNames from "classnames";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { Radio } from "@mui/material";
import bstyle from "./Booking.module.css";
import pstyle from "../../../shared/components/DonationServices/pay.module.css";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import {
  IoIosArrowForward,
  IoIosCheckboxOutline,
  IoMdArrowForward,
} from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";
import img from "assets/images/parklane3.png";
import { IoLocationOutline } from "react-icons/io5";
import RatingStar from "shared/RatingStar";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";
const HotelBooking = () => {
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const { state } = useLocation()
  const item = state?.item
  const [selectedValue, setSelectedValue] = useState("");
  const { user } = useSelector((state: any) => state.root.common)
  console.log(user, '...user')
  const navigate = useNavigate();
  // const handlepay = () => {
  //   navigate(`/services/rentacar/DonationPay/`);
  // };
  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return "Date of birth not provided";
    const [day, month, year] = dateOfBirthString.split("/").map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  let params = {

    hotelId: item?.hotelId,
    serviceId: item?._id,
    serviceType: state?.Type?.toLowerCase(),
    rooms: state?.selectedRooms,
    name: user?.name,
    email: user?.email,
    purpose: selectedValue?.toLowerCase(),
    totalAmount: state?.actualAmount,
    age: calculateAge(user?.dateOfBirth)
  };
  const inputData = [
    { label: "Name", type: "text", placeholder: user?.name, readOnly: true },
    { label: "Email", type: "text", placeholder: user?.email, readOnly: true },
    {
      label: "Phone number",
      type: "text",
      placeholder: user?.phone,
      readOnly: true,
    },
    {
      label: "Phone number",
      type: "text",
      placeholder: user?.mrNo,
      readOnly: true,
    },
    {
      label: "Location",
      type: "text",
      placeholder: user?.addresses?.[0]?.address,
      readOnly: true,
    },
  ];
  const cartData = [
    {
      name: "Panadol CF",
      size: "1x",
      price: 250,
    },
  ];
  const handlepay = async () => {
    if (!selectedValue) {
      setError("Please select the primary purpose of your trip.");
    } else {
      await dispatch(setObj({ item, params }));

      navigate("/services/paymentDetail", {
        state: {
          serviceName: 'Hotel',
          actualAmount: state?.actualAmount,

        },
      });
    }
  };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Rent a Car
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Rent a Car
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Details
          </p>
        </div>
      </div>
      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <div className={classNames(pstyle.mainprogressContainer)}>
          <div className={classNames(pstyle.progressContainer)}>
            <div className={classNames(pstyle.circleWrapper)}>
              <div className={classNames(pstyle.progressCircle1)}>1</div>
              <span className={classNames(pstyle.circleText)}>Booking</span>
            </div>

            <div className={classNames(pstyle.line)}></div>

            <div className={classNames(pstyle.circleWrapper)}>
              <div className={classNames(pstyle.progressCircle2)}>2</div>
              <span className={classNames(pstyle.circleText)}>payment</span>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap
          )}
        >
          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{
              marginTop: "10px",
            }}
          >
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs24,
                commonstyles.semiBold
              )}
            >
              Your Info
            </p>
            <div className={classNames(bstyle.card)}>
              {inputData.map((input, index) => (
                <div key={index}>
                  {input.label && (
                    <label className={bstyle.label}>{input.label}</label>
                  )}
                  <input
                    className={bstyle.input}
                    type={input.type}
                    placeholder={input.placeholder}
                    readOnly={input.readOnly}
                  />
                </div>
              ))}
              <div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                  style={{
                    margin: "10px 0",
                  }}
                >
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16
                    )}
                  >
                    Save your details for future booking
                  </p>
                  <Checkbox
                    sx={{
                      padding: "0px",
                      "&.Mui-checked": {
                        color: "#0D47A1",
                      },
                    }}
                  />
                </div>
                <hr
                  style={{
                    color: "#0E54A3",
                  }}
                />
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16
                    )}
                  >
                    Leisure
                  </p>
                  <div>
                    <Radio
                      value="leisure"
                      checked={selectedValue === "leisure"}
                      onChange={handleRadioChange}
                    />
                  </div>
                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16
                    )}
                  >
                    Business
                  </p>
                  <div>
                    <Radio
                      value="business"
                      checked={selectedValue === "business"}
                      onChange={handleRadioChange}
                    />
                  </div>
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}
                {/* <hr
                  style={{
                    color: "#0E54A3",
                  }}
                />

                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      commonstyles.colorBlue,
                      commonstyles.fs16
                    )}
                  >
                    Request Business Invoice
                  </p>
                  <div>
                    <Checkbox
                      sx={{
                        padding: "0px",
                        "&.Mui-checked": {
                          color: "#0D47A1",
                        },
                      }}
                    />
                  </div>
                </div>
                <p
                  className={classNames(
                    commonstyles.colorGray,
                    commonstyles.fs14
                  )}
                >
                  I’d like the hotel to create a business invoice with my
                  company address details
                </p> */}
              </div>
            </div>
          </div>
          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{
              marginTop: "10px",
            }}
          >
            {" "}
            <p
              className={classNames(
                commonstyles.colorBlue,
                commonstyles.fs24,
                commonstyles.semiBold
              )}
            >
            Details
            </p>
            <div className={classNames(bstyle.card)}>
              <div className={classNames(bstyle.imgcontainer)}>
                <img src={item?.propertyphoto?.[0]} alt="" />
              </div>
              <div className={classNames(bstyle.content)}>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                   Breakfast Included:{item?.rooms?.[0]?.breakfast}
                </p>
                <div>starrrrr
                  <RatingStar rating={3} />
                </div>
                <div className={classNames(commonstyles.flx)}>
                  <div>
                    <IoLocationOutline
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16
                      )}
                    />
                  </div>
                  <div
                    className={classNames(
                      commonstyles.colorGray,
                      commonstyles.fs16
                    )}
                  >
                    {item?.customName || item?.serviceId?.propertyName}
                  </div>
                </div>
                <hr />
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue
                  )}
                >
                  {item?.location?.address &&
            `${item?.location?.address} ${','} ${item?.location?.city}`}
          {item?.serviceId?.location?.address &&
            `${item?.serviceId?.location?.address} ${','} ${
              item?.serviceId?.location?.city
            }`}
             </p>
                {/*
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorGray
                  )}
                >
                  Mon, Jan 13 - Fri, Jan 24
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorBlue
                  )}
                >
                  You selected
                </p>
                <p
                  className={classNames(
                    commonstyles.fs16,
                    commonstyles.colorGray
                  )}
                >
                  10 night, 1 room for adults
                </p>
                <div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue
                    )}
                  >
                    1 Executive King Room
                  </p>
                  <div
                    className={classNames(
                      commonstyles.flx,
                      commonstyles.flxBetween
                    )}
                  >
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.colorGray
                      )}
                    >
                      2 Sigle Beds
                    </p>
                    <p
                      className={classNames(
                        commonstyles.fs16,
                        commonstyles.colorGray
                      )}
                    >
                      Size: 269 ft
                    </p>
                  </div>
                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorBlue
                    )}
                  >
                    Room and guests
                  </p>

                  <p
                    className={classNames(
                      commonstyles.fs16,
                      commonstyles.colorGray
                    )}
                  >
                    1 room, 1 adult, 0 children
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className={pstyle.showMoreContainer}>
          <button className={pstyle.showMoreButton} onClick={handlepay}>
            Continue
            <span className={pstyle.icon}>
              <IoMdArrowForward />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HotelBooking;
