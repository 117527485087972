import React, { useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./index.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import Cards from "./Crads"; // Ensure the correct path to the Cards component
import cardimg from "assets/images/RentaCarcard.png"; // Replace with appropriate images

// Define the card type
interface Card {
  title: string;
  subtitle: string;
  details: string;
  rating: number;
  imgSrc: string;
}

const Favorites: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("Doctor");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  // Define cards for each tab
  const cardsData: Record<string, Card[]> = {
    Doctor: [
      {
        title: "Dr. John Doe",
        subtitle: "Cardiology",
        details: "MBBS(Pb), FRCP (UK), MD(USA), MACP",
        rating: 4,
        imgSrc: cardimg,
      },
      {
        title: "Dr. Jane Smith",
        subtitle: "Neurology",
        details: "MD, PhD",
        rating: 5,
        imgSrc: cardimg,
      },
    ],
    Hospital: [
      {
        title: "City Hospital",
        subtitle: "General Healthcare",
        details: "Providing 24/7 healthcare services.",
        rating: 4.5,
        imgSrc: cardimg,
      },
      {
        title: "Starlight Hospital",
        subtitle: "Specialized Care",
        details: "Expert in cardiology and orthopedics.",
        rating: 4.8,
        imgSrc: cardimg,
      },
    ],
   
   
  };

  return (
    <div>
      <MainHeader
        mainHeading="My Favorite"
        breadcrumb={["Home", "My Favorite"]}
      />
      <div className={classNames(commonstyle.container)}>
        <div
          className={classNames(
            style.tabContainer,
            commonstyle.flx,
            commonstyle.col6,
            commonstyle.colsm12
          )}
        >
          {[
            "Doctor",
            "Hospital",
            "Laboratories",
            "Rent a Car",
            "Hotel",
            "Travel Agency",
            "Insurance",
            "Donation",
          ].map((tab) => (
            <button
              key={tab}
              className={classNames(
                style.tab,
                activeTab === tab && style.activeTab
              )}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className={style.cardContainer}>
          {cardsData[activeTab]?.map((card, index) => (
            <Cards
              key={index}
              title={card.title}
              subtitle={card.subtitle}
              details={card.details}
              rating={card.rating}
              imgSrc={card.imgSrc}
            />
          ))}
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default Favorites;
