import React, { useEffect, useState } from "react";
import classNames from "classnames";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import dstyle from "./DonationStyle.module.css";
import HelpCards from "shared/components/DonationServices/HelpCards";
import Explore from "shared/components/DonationServices/Explore";
import DonnersCards from "shared/components/DonationServices/DonnersCards";
import {
  getDonation_Companies,
  getPeople_Donation,
  getRecentDonor,
} from "shared/services";
import img from "assets/images/Avatar.png";
import MainHeader from "shared/components/MainScreen/Index";
import { LoadingModal } from "shared/components";

const DonationServices: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [donorData, setDonor] = useState<any>([]);
  const [companyData, setCompanyData] = useState<any>([]);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchAllData();
  }, []);

  const fetchAllData = () => {
    setloading(true);
    Promise.all([
      getDonation_Companies(),
      getPeople_Donation(),
      getRecentDonor({ page: 1 }),
    ])
      .then((responses) => {
        const [companiesRes, criterionRes, donorsRes] = responses;

        setData(companiesRes?.data?.companies);
        setCompanyData(criterionRes?.data?.criteria);
        setDonor(donorsRes?.data?.donations);
      })
      .catch((error) => {
        console.log(error, "Error fetching data");
      })
      .finally(() => {
        setloading(false);
      });
  };

  return (
    <div>
      <MainHeader
        mainHeading="Donation"
        breadcrumb={["Home", "Services", "Donation"]}
      />

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <div>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Need to Help
          </p>
        </div>
        <HelpCards cardsData={data} />
        <>
          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Explore Packages
          </p>
          <Explore companyData={companyData} />

          <p
            className={classNames(
              commonstyles.fs32,
              commonstyles.semiBold,
              commonstyles.colorBlue
            )}
          >
            Recent Donors
          </p>
          <DonnersCards donorData={donorData} />
        </>
        {loading && <LoadingModal showModal={loading} />}
      </div>

      <Footerr />
    </div>
  );
};

export default DonationServices;
