import React, { useState } from 'react';
import classNames from 'classnames'; // Ensure you have this package installed
import styles from './Style.module.css';
import commonstyle from 'shared/utils/common.module.css';

interface DoubleButtonProps {
  tab1Label: string; // Label for the first tab
  tab2Label: string; // Label for the second tab
  onTabChange: (activeTab: string) => void; // Callback for when the tab changes
}

const DoubleButton: React.FC<DoubleButtonProps> = ({ tab1Label, tab2Label, onTabChange }) => {
  const [activeTab, setActiveTab] = useState<string>(tab1Label); // Default active tab

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    onTabChange(tab); // Call the callback prop with the active tab
  };

  return (
    <div>
      <div className={styles.tabContainer}>
        <div
          className={classNames(styles.tab, {
            [styles.tabActive]: activeTab === tab1Label,
            [styles.tabInactive]: activeTab !== tab1Label,
          })}
          onClick={() => handleTabClick(tab1Label)}
        >
          {tab1Label}
        </div>
        <div
          className={classNames(styles.tab, {
            [styles.tabActive]: activeTab === tab2Label,
            [styles.tabInactive]: activeTab !== tab2Label,
          })}
          onClick={() => handleTabClick(tab2Label)}
        >
          {tab2Label}
        </div>
      </div>
    </div>
  );
};

export default DoubleButton;
