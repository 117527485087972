import React, { useState } from "react";
import style from "./TravelFlight.module.css";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import Datepicker from "shared/components/DatePicker";
import CustomDropdown from "shared/components/DropDown";
import LocationInput from "shared/components/LocationInput";
import { IoMdArrowForward } from "react-icons/io";
import { MdOutlineArrowDropDown, MdOutlineClose } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import ThankyouModel from "shared/components/ThankyouModel";
import { Console } from "console";
import { red } from "@mui/material/colors";
import { Link, useNavigate } from "react-router-dom";
import { flightsRequest } from "shared/services";
import dayjs from "dayjs";

interface CartItem {
  name: string;
  quantity: number;
}

const TravelFlight = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [multiCityData, setMultiCityData] = useState([
    { from: "", to: "", departure: "" },
  ]);
  console.log(multiCityData, "............................");
  const [cartData, setCartData] = useState<any>([
    { name: "Adult", quantity: 0 },
    { name: "Children", quantity: 0 },
    { name: "Infant", quantity: 0 },
  ]);
  const [locationDataFrom, setLocationFrom] = useState<any>('');
  const [locationDataTo, setLocationTo] = useState<any>('');
  const [showCard, setShowCard] = useState(false);
  const navigate=useNavigate()
  const [tripType, setTripType] = useState<string>("oneWay");
  const [departureDate, setDepartureDate] = useState<any>("");
  const [returnDate, setreturnDate] = useState<any>("");
  const [errors, setErrors] = useState({
    locationFrom: "",
    locationTo: "",
    departureDate: "",
    returnDate: "",
    multiCity: "",
    passengers: "",
    travler:'',
  });
  
  const handleTripTypeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTripType(event.target.value);
  };
  const handleAptDate = (date: any) => {
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setDepartureDate(formattedDate);
  };
  const handleReturnDate=(date:any)=>{
    const selectedDate = dayjs(date);
    const formattedDate = selectedDate.format("YYYY-MM-DD");
    setreturnDate(formattedDate);
  }
  const dropdownOptions = [
    { value: "option1", label: "Economy" },
    { value: "option2", label: "Premium Economy" },
    { value: "option3", label: "Business" },
    { value: "option4", label: "First Class" },
  ];

  const handleDropdownChange = (event: any) => {
    setSelectedValue(event.target.value);
  };
  const handleIncrement = (index: number) => {
    const updatedCart = [...cartData];
    updatedCart[index].quantity += 1;
    setCartData(updatedCart);
  };

  const handleDecrement = (index: number) => {
    const updatedCart = [...cartData];
    if (updatedCart[index].quantity > 1) {
      updatedCart[index].quantity -= 1;
      setCartData(updatedCart);
    }
  };

  const toggleDropdown = () => {
    setShowCard((prev) => !prev);
  };

 

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAddMoreCities = () => {
    setMultiCityData([...multiCityData, { from: "", to: "", departure: "" }]);
  };

  const handleCityInputChange = (
    index: number,
    field: "from" | "to" | "departure",
    value: string
  ) => {
    const updatedMultiCityData = [...multiCityData];
    updatedMultiCityData[index][field] = value;
    setMultiCityData(updatedMultiCityData);
  };
  const handleDeleteCity = (index: any) => {
    const updatedCities = multiCityData.filter((_, i) => i !== index);
    setMultiCityData(updatedCities);
  };
  console.log(selectedValue, '............selectedValue')
  const requestFlights = () => {
    let data: any = {
      flights: [],
      flightClass: selectedValue,
      adult: cartData.find((item: CartItem) => item.name === "Adult")?.quantity,
      children: cartData.find((item: CartItem) => item.name === "Children")?.quantity,
      infant: cartData.find((item: CartItem) => item.name === "Infant")?.quantity,
      requestType: tripType,
    };
  
    if (tripType === "multiCity") {
      data.flights = multiCityData.map((city:any) => ({
        from: city.from.label, 
        to: city.to.label,     
        departure: city.departure, 
      }));
    } else {
      data.flights = [
        {
          from: locationDataFrom?.label, 
          to: locationDataTo?.label, 
          departure: departureDate,  
        },
      ];
  
      if (tripType === "round") {
        data.returnFlight = returnDate; 
      }
    }
  
    console.log(data, '.....data');
  
    flightsRequest(data)
      .then((res: any) => {
        setModalVisible(true);
        setTimeout(() => {
          setModalVisible(false);
          navigate(-1);
        }, 5000);
      })
      .catch((err: any) => {
        console.log(err?.response?.data, '..................errr flights');
      })
      .finally(() => {});
  };
  const handleButtonClick = () => {
  // Reset errors
  setErrors({
    locationFrom: "",
    locationTo: "",
    departureDate: "",
    returnDate: "",
    multiCity: "",
    passengers: "",
    travler:'',
  });

  // Validation for OneWay and Round Trip
  if (
    (tripType === "oneWay" || tripType === "round") &&
    (!locationDataFrom || !locationDataTo || !departureDate || !selectedValue)
  ) {
    if (!locationDataFrom) setErrors((prev) => ({ ...prev, locationFrom: "From location is required." }));
    if (!locationDataTo) setErrors((prev) => ({ ...prev, locationTo: "To location is required." }));
    if (!departureDate) setErrors((prev) => ({ ...prev, departureDate: "Departure date is required." }));
    if (!selectedValue) setErrors((prev) => ({ ...prev, passengers: "Flight class is required." }));
    return; // Stop the function execution
  }

  // Validation for MultiCity Trip
  if (tripType === "multiCity") {
    let isMultiCityDataValid = true;
    multiCityData.forEach((city) => {
      if (!city.from || !city.to || !city.departure) {
        isMultiCityDataValid = false;
      }
    });

    if (!isMultiCityDataValid || !selectedValue) {
      setErrors((prev) => ({ ...prev, multiCity: "Please fill all required fields for multi-city." }));
      return; // Stop the function execution
    }
  }

  // Validation for cartData (at least one selection in Adult, Children, or Infant)
  const totalPassengers = cartData.reduce(
    (acc: number, item: CartItem) => acc + item.quantity,
    0
  );

  if (totalPassengers === 0) {
    setErrors((prev) => ({ ...prev, travler: "Please select at least one Traveler." }));
    return; // Stop the function execution
  }

  requestFlights();
};

  
  
  return (
    <div className={classNames(style.maincontainer)}>
      <div className={classNames(commonstyle.flx)}>
        <div className={classNames(commonstyle.col2)}>
          <div className={style.selectContainer}>
            <select
              className={style.select}
              defaultValue=""
              onChange={handleTripTypeChange}
            >
              <option value="" disabled hidden>
              OneWay
              </option>
              <option value="oneWay">OneWay</option>
              <option value="round">round</option>
              <option value="multiCity">multiCity</option>
            </select>
          </div>
        </div>
        <div
          className={classNames(commonstyle.col2)}
          style={{ position: "relative" }}
        >
          <div className={style.dropdownContainer}>
            <button
              onClick={toggleDropdown}
              className={style.dropdownButton}
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                color: "#1e5da3",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "18px" }}>Travelers</span>
              {showCard ? <IoMdArrowDropup /> : <MdOutlineArrowDropDown />}
            </button>

            {showCard && (
              <div className={classNames(style.card)}>
                {cartData.map((item: any, index: any) => (
                  <div key={index} className={classNames()}>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                          style={{ margin: "0 10px" }}
                        >
                          {item.name}
                        </p>
                      </div>
                      <div className={classNames(commonstyle.flx)}>
                        <div className={classNames(style.viewQuantitybox)}>
                          <button
                            className={style.decrementButton}
                            onClick={() => handleDecrement(index)}
                          >
                            -
                          </button>
                          <span className={style.quantity}>
                            {item.quantity}
                          </span>
                          <button
                            className={style.incrementButton}
                            onClick={() => handleIncrement(index)}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
               
              </div>
            )}
          </div>
          {errors.travler && <p className={style.errorMessage}>{errors.travler}</p>}
        </div>
      </div>

      {(tripType === "oneWay" || tripType === "round") && (
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div className={classNames(style.inputContainer, commonstyle.col2)}>
            <LocationInput placeholder="From"
              setData={setLocationFrom}
            />
            {errors.locationFrom && <p className={style.errorMessage}>{errors.locationFrom}</p>}
          </div>
          <div className={classNames(style.inputContainer)}>
            <LocationInput placeholder="To" setData={setLocationTo} />
            {errors.locationTo && <p className={style.errorMessage}>{errors.locationTo}</p>}
          </div>
          <div
            className={classNames(style.inputContainer)}
            style={{
              margin: "1px 20px",
            }}
          >
            <Datepicker placeholder="Departure Date" setData={handleAptDate} />
            {errors.departureDate && <p className={style.errorMessage}>{errors.departureDate}</p>}
          </div>

          {tripType === "round" && (
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "1px 0",
              }}
            >
              <Datepicker placeholder="Return Date" setData={handleReturnDate}  />
              {errors.returnDate && <p className={style.errorMessage}>{errors.returnDate}</p>}
            </div>
          )}
          <div className={classNames(style.inputContainer)}>
            <CustomDropdown
              id="my-dropdown"
              name="dropdownField"
              value={selectedValue}
              onChange={handleDropdownChange}
              options={dropdownOptions.map(option => ({ value: option.label, label: option.label }))}
            />
             {errors.passengers && <p className={style.errorMessage}>{errors.passengers}</p>}
          </div>
        </div>
      )}

      {tripType === "multiCity" && (
        <>
          <div className={classNames(commonstyle.flx)}>
            <div className={classNames(style.inputContainer, commonstyle.col2)}>
              <LocationInput
                placeholder="From"
                setData={(data) => handleCityInputChange(0, "from", data)}
              />
            </div>
            <div className={classNames(style.inputContainer)}>
              <LocationInput
                placeholder="To"
                setData={(data) => handleCityInputChange(0, "to", data)}
              />
            </div>
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "10px 0",
              }}
            >
              <Datepicker
                placeholder="Departure Date"
                onChange={(date) =>
                  handleCityInputChange(
                    0,
                    "departure",
                    date ? date.format("YYYY-MM-DD") : ""
                  )
                }
              />
            </div>
            <div
              className={classNames(style.inputContainer)}
              style={{
                margin: "10px 0",
              }}
            >
              <CustomDropdown
                id="my-dropdown"
                name="dropdownField"
                value={selectedValue}
                onChange={handleDropdownChange}
                options={dropdownOptions}
              />
            </div>
          </div>

          {multiCityData.slice(1).map((city, index) => (
            <div key={index + 1} className={classNames(commonstyle.flx)}>
              <div
                className={classNames(style.inputContainer, commonstyle.col2)}
              >
                <LocationInput
                  placeholder="From"
                  setData={(data) =>
                    handleCityInputChange(index + 1, "from", data)
                  }
                />
              </div>
              <div className={classNames(style.inputContainer)}>
                <LocationInput
                  placeholder="To"
                  setData={(data) =>
                    handleCityInputChange(index + 1, "to", data)
                  }
                />
              </div>
              <div className={classNames(style.inputContainer)}>
                <Datepicker
                  placeholder="Departure Date"
                  onChange={(date) =>
                    handleCityInputChange(
                      index + 1,
                      "departure",
                      date ? date.format("YYYY-MM-DD") : ""
                    )
                  }
                />
              </div>
              <div className={classNames(style.inputContainer)}>
                <MdOutlineClose
                  onClick={() => handleDeleteCity(index + 1)}
                  style={{
                    cursor: "pointer",
                    color: "#0e54a3;",
                    fontSize: "13px",
                  }}
                />
              </div>
            </div>
          ))}
 {errors.multiCity && <p className={style.errorMessage}>{errors.multiCity}</p>}
          <div className={style.addMoreContainer}>
            <Link
              to="#"
              className={style.addMoreButton}
              onClick={(e) => {
                e.preventDefault();
                handleAddMoreCities();
              }}
            >
              Add More
            </Link>
          </div>
        </>
      )}

      <div>
        <div className={style.showMoreContainer}>
          <button className={style.showMoreButton} onClick={handleButtonClick}>
            Request
            <span className={style.icon}>
              <IoMdArrowForward />
            </span>
          </button>
        </div>

        {isModalVisible && (
          <ThankyouModel
            mainMessageLine1="Your request has been"
            mainMessageLine2="successfully shared"
            subMessage="We will notify you in a few minutes."
            footerMessage="Thank you."
            onClose={handleCloseModal}
          />
        )}
      </div>
    </div>
  );
};

export default TravelFlight;
