import React, { useState } from "react";
import classNames from "classnames";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { Radio } from "@mui/material";
import bstyle from "./Booking.module.css";
import pstyle from "../../../shared/components/DonationServices/pay.module.css";
import style from "../DoctarServices/Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward, IoMdArrowForward } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";
const RentaCarBooking = () => {
  const { user, } = useSelector(
    (state: any) => state.root.common
  );
  const [cnicError, setCnicError] = useState("");
  const [cnic, setCnic] = useState("");
  const { state } = useLocation();
  const { newData, calculate_amountPerDay,items } = state || {};
  console.log(newData, '......newData')
  const navigate = useNavigate();
  const [cnc, setCNC] = useState<any>('');
const dispatch=useDispatch();
const handlepay = async () => {
  if (!cnic) {
    setCnicError("CNIC is required.");
  } else {
    setCnicError("");
    
    let requestData: any = {
      vehicleName: items?.vehicleName,
      vehicleId: items?._id,
      doctorImage:items?.vehicleImages[0],
      rentACarId: items?.rentACarId,
      vehicleModel: items?.vehicleModel,
      totalAmount: calculate_amountPerDay,
      pickupLocation: newData?.pickupLocation?.label,
      dropoffLocation: newData?.dropoffLocation?.label,
      pickupDateTime: new Date(`${newData?.pickDate}T${newData?.picKupTime}`).toLocaleString(),
      dropoffDateTime: new Date(`${newData?.dropDate}T${newData?.DropOffTime}`).toLocaleString(),
      cnic: cnic,
      phone: newData?.phoneNo,
      customerName:newData?.name,
      age:newData?.age,
      withDriver: newData?.withDriver,
    };
console.log(requestData,'.......requestData')
    await dispatch(setObj(requestData));
    
    navigate("/services/paymentDetail", {
      state: {
        serviceName: 'rentacar',
        actualAmount: calculate_amountPerDay,
      },
    });
  }
};

  const handleCnicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 5 && value.length <= 12) {
      value = `${value.slice(0, 5)}-${value.slice(5)}`;
    } else if (value.length > 12) {
      value = `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(12)}`;
    }
    setCnic(value);
  };
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return 'Date of birth not provided';
    const [day, month, year] = dateOfBirthString.split('/').map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  const inputData = [
    { label: "Name", type: "text", placeholder: user?.name, readOnly: true },
    { label: "Age", type: "text", placeholder: calculateAge(user?.dateOfBirth), readOnly: true },
    {
      label: "Phone number",
      type: "text",
      placeholder: user?.phone,
      readOnly: true,
    },
    // {
    //   label: "Identity Number",
    //   type: "text",
    //   placeholder: "Please Enter Identity Number",
    //   readOnly: false,
    //   value:cnc,
    // },
  ];
  console.log(cnc, '.......value')
  const rentalData = [
    {
      label: "Pickup Location",
      type: "text",
      placeholder: newData?.pickupLocation?.label,
      readOnly: true,
    },
    {
      label: "Pickup Date & Time",
      type: "text",
      placeholder: `${newData?.pickDate}-${newData?.picKupTime}`,
      readOnly: true,
    },
    {
      label: "Drop-off Location ",
      type: "text",
      placeholder: newData?.dropoffLocation?.label,
      readOnly: true,
    },
    {
      label: "Drop-off Date & Time",
      type: "text",
      placeholder: `${newData?.dropDate}-${newData?.DropOffTime}`,
      readOnly: true,
    },
  ];
  console.log(user, '......user')
  // const handleViewMoreClick  => {
  //   navigate (`/services/rentacar/DonationPay/`);
  // };
  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          Rent a Car
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Rent a Car
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Details
          </p>
        </div>
      </div>
      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        {/* <div className={classNames(pstyle.mainprogressContainer)}>
          <div className={classNames(pstyle.progressContainer)}>
            <div className={classNames(pstyle.circleWrapper)}>
              <div className={classNames(pstyle.progressCircle1)}>1</div>
              <span className={classNames(pstyle.circleText)}>Booking</span>
            </div>

            <div className={classNames(pstyle.line)}></div>

            <div className={classNames(pstyle.circleWrapper)}>
              <div className={classNames(pstyle.progressCircle2)}>2</div>
              <span className={classNames(pstyle.circleText)}>payment</span>
            </div>
          </div>
        </div> */}
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap
          )}
        >
          <div className={classNames(commonstyles.col4, commonstyles.colsm12)}>
            <div>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs24,
                  commonstyles.semiBold
                )}
              >
                Customer Details
              </p>
              {inputData.map((input: any, index: any) => (
                <div key={index}>
                  {input.label && (
                    <label className={bstyle.label}>{input.label}</label>
                  )}
                  <input
                    className={bstyle.input}
                    type={input.type}
                    placeholder={input.placeholder}
                    style={input.style || {}}
                    value={input.label === "Identity Number" ? cnc : input?.value} // Bind 'cnc' value for the ID card input
                    onChange={(text: any) => input.label === "Identity Number" ? setCNC(text.target.value) : null}
                  />
                </div>
              ))}
              <div>
                <label className={bstyle.label}>CNIC</label>
                <input
                  className={bstyle.input}
                  type="text"
                  value={cnic}
                  placeholder="33303-1234567-1"
                  onChange={handleCnicChange}
                  maxLength={15}
                />
                {cnicError && <p style={{color:'red'}}>{cnicError}</p>} {/* Show error message */}
              </div>
            </div>
          </div>
          <div className={classNames(commonstyles.col4, commonstyles.colsm12)}>
            <div>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs24,
                  commonstyles.semiBold
                )}
              >
                Rental Details
              </p>
              {rentalData.map((rental, index) => (
                <div key={index}>
                  {rental.label && (
                    <label className={bstyle.label}>{rental.label}</label>
                  )}
                  <input
                    className={bstyle.input}
                    type={rental.type}
                    placeholder={rental.placeholder}
                    readOnly={rental.readOnly}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%'
        }}>
          <div className={classNames(commonstyles.flx, commonstyles.flxBetween)}>
            <p className={classNames(
              commonstyles.colorBlue,
              commonstyles.fs24,
              commonstyles.semiBold



            )}

              style={{
                margin: '0 20px'
              }}

            >Total price</p>
            <p className={classNames(
              commonstyles.colorBlue,
              commonstyles.fs24,
              commonstyles.semiBold
            )} >{calculate_amountPerDay}</p>

          </div>
        </div>

        <div className={pstyle.showMoreContainer}>
          <button className={pstyle.showMoreButton} onClick={handlepay}>
            Continue
            <span className={pstyle.icon}>
              <IoMdArrowForward />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RentaCarBooking;
