import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fff",
  borderRadius: "16px!important",
  boxShadow: 24,
  p: 4,
};

interface Props {
  showModal: any;
  children: any;
  close:any;
}

export default function CustomModal(props: Partial<Props>) {
  const { showModal, children ,close} = props;
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Modal
        open={showModal}
        onClose={close?close:() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <>
 {/* <p
                    className={classNames(
                      commonstyles.fs24,
                      commonstyles.semiBold,
                      commonstyles.colorBlue
                    )}
                  >
                    Appointment
                  </p> */}
        <Box sx={style}
        >{children}</Box>
        </>
      </Modal>
    </div>
  );
}
