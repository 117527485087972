import React, { useEffect, useState } from "react";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import hstyle from "./Hotel.module.css";
import cardimg from "assets/images/parklane.png"; // Make sure the path is correct
import { FaRegCheckCircle, FaRegHeart } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoMdArrowForward } from "react-icons/io";
import { Radio } from "@mui/material";

import GoogleMap from "shared/components/GoogleMap/GoogleMap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { addAllSearchHotel } from "shared/services";
import { useSelector } from "react-redux";
import { BnbHotel, BnbMap, LoadingModal } from "shared/components";
import PhysiotheristsEmpty from "shared/components/PhsiotheristEmpty";


const SortCheckBoxData = [
  {id: 1, title: 'Price (High to Low)'},
  {id: 2, title: 'Price (Low to High)'},
];

const HotelProperty = () => {
  const [visibleCount, setVisibleCount] = useState(1);
  const location = useLocation();
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState<any>([]);
  const { hotelDetail } = useSelector((state: any) => state.root?.common)
  const [loading, setLoading] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState<string>('');
  const handledetails = (item: any) => {
    navigate("/services/hotel/HotelDetails", { state: { item: item } });
  };
  console.log(hotelDetail, '.............hotelDetail')
  useEffect(() => {
    addFilterSearch()
  }, [])
  const addFilterSearch = () => {
    setLoading(true);
    const data = {
      page: 1,
    };
    const params = {
      serviceType: hotelDetail?.selectedOption?.toLowerCase(),
      city: hotelDetail?.selectCity,
      rooms: hotelDetail?.roomsQuantity,
      adults: hotelDetail?.adultsQuantity,
      filters: {
        sort:
          selectedSortOption === 'Price (High to Low)'
            ? 'descending'
            : 'ascending',
      },


    };
    addAllSearchHotel(data, params)
      .then((res: any) => {
        console.log(res?.data?.hotels, '............searchResponse');
        setData(res?.data?.hotels);
      })
      .catch((err: any) => {
        console.log(err?.response?.data, 'Error fetching filtered data');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const sortData = () => {
    if (data?.length === 0) return;
  
    let sorted = [...data];
  
    if (selectedSortOption === 'Price (High to Low)') {
      sorted.sort((a: any, b: any) => {
        const priceA =
          hotelDetail?.selectedOption === 'Hotel'
            ? a.minRoomPrice
            : hotelDetail?.selectedOption === 'Apartment'
            ? a.minApartmentPrice
            : a.minHomePrice;
  
        const priceB =
          hotelDetail?.selectedOption === 'Hotel'
            ? b.minRoomPrice
            : hotelDetail?.selectedOption === 'Apartment'
            ? b.minApartmentPrice
            : b.minHomePrice;
  
        return (priceB || 0) - (priceA || 0);
      });
    } else if (selectedSortOption === 'Price (Low to High)') {
      sorted.sort((a: any, b: any) => {
        const priceA =
          hotelDetail?.selectedOption === 'Hotel'
            ? a.minRoomPrice
            : hotelDetail?.selectedOption === 'Apartment'
            ? a.minApartmentPrice
            : a.minHomePrice;
  
        const priceB =
          hotelDetail?.selectedOption === 'Hotel'
            ? b.minRoomPrice
            : hotelDetail?.selectedOption === 'Apartment'
            ? b.minApartmentPrice
            : b.minHomePrice;
  
        return (priceA || 0) - (priceB || 0);
      });
    }
  
    setSortedData(sorted);
    setLoading(false)
  };
  
  const handleSelect = (item: any) => {
    setLoading(true)
    setSelectedSortOption(item.title);
  };
  useEffect(() => {
    
      sortData();
  }, [selectedSortOption, data]);
  return (
    <div>
      <MainHeader
        mainHeading="Hotel"
        breadcrumb={["Home", "Services", "Hotel"]}
      />

      <div className={classNames(commonstyles.container, commonstyles.mb32)}>
        <div>
          <p
            className={classNames(
              commonstyles.colorBlue,
              commonstyles.fs24,
              commonstyles.semiBold
            )}
          >
            {sortedData?.length} Properties
          </p>
          {sortedData?.length > 0 ? (<div
            className={classNames(
              commonstyles.flx,
              commonstyles.flxWrap,
              commonstyles.flxBetween
            )}
          >
            <div
              className={classNames(commonstyles.col6, commonstyles.colsm12)}
            >
              {   sortedData?.map((item: any) => {

                return hotelDetail?.selectedOption == 'Hotel' ? (
                  <BnbHotel location={item?.location?.address} cancel={item?.advanceCancelfreeofCharge} name={item?.hotelId?.name} CarImage={item?.hotelId?.logo || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU'} price={item?.minRoomPrice} handledetails={() => handledetails(item)} />
                ) : hotelDetail?.selectedOption == 'Apartment' ? (
                  <BnbHotel location={item?.location?.address} cancel={item?.advanceCancelfreeofCharge} name={item?.propertyName} CarImage={item?.propertyphoto?.[0] || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU'} price={item?.minApartmentPrice} handledetails={() => handledetails(item)} />
                ) : (
                  <BnbHotel location={`${item?.location?.address}-${item?.location?.city}`} cancel={item?.advanceCancelfreeofCharge} name={item?.propertyName} CarImage={item?.propertyphoto?.[0] || 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU'} price={item?.minHomePrice} handledetails={() => handledetails(item)} />
                )
              })

              }
            </div>
            <div
              className={classNames(commonstyles.col4, commonstyles.colsm12)}
            >
              <div>
                <p
                  className={classNames(
                    commonstyles.colorBlue,
                    commonstyles.fs16,
                    commonstyles.semiBold
                  )}
                >
                  Show on Map
                </p>
                <div>
                  <div>
                    <BnbMap data={data} />
                   
                  </div>

                  <div className={classNames(hstyle.fiterbox)}>
                    <p
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.fs16,

                        commonstyles.semiBold
                      )}
                    >
                      Sort by
                    </p>

                    {SortCheckBoxData?.map((item?:any)=>(
                      <div
                      className={classNames(
                        commonstyles.colorBlue,
                        commonstyles.flx,
                        commonstyles.flxBetween
                      )}
                    >
                      <p>{item?.title}</p>
                      <Radio className={classNames(commonstyles.colorBlue)} onClick={() => handleSelect(item)} />
                    </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>) : (
            <div>{!loading && <PhysiotheristsEmpty />}</div>
          )}
        </div>
        {loading && <LoadingModal showModal={loading} />}
      </div>
      <Footerr />
    </div>
  );
};

export default HotelProperty;
