import classNames from "classnames";
import { useSelector } from "react-redux";
import { IoMdHeartEmpty } from "react-icons/io";
import { LoadingModal } from "shared/components";
import { IoMdArrowForward } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CommonStyless from "shared/utils/common.module.css";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import CardStyless from "../DoctarServices/Cards.module.css";
import { IoLocationOutline, IoTimeOutline } from "react-icons/io5";
import { getUser_Laboratory } from "shared/services/UserService";

const LaboratoryCards = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const { location } = useSelector((state: any) => state?.root?.common);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getList();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      getList(); // Fetch the list when Enter is pressed
    }
  };

  const handleViewMoreClick = (id: any) => {
    navigate(`/services/laboratory/LabortoryDetail`, {
      state: { serviceName: "Lab", id: id },
    });
  };

  const getList = () => {
    let params = {
      page: 1,
      search: searchValue,
      lat: location?.latitude,
      long: location?.longitude,
    };
    getUser_Laboratory(params)
      .then((res: any) => {
        setData(res.data.labs);
      })
      .catch((err: any) => {
        console.log("err...", err);
      })
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <LoadingModal showModal={loading} />;
  }

  return (
    <div className={classNames(CommonStyless.container, CommonStyless.mt32)}>
      <div style={{ backgroundColor: "FDFDFD" }}>
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxWrap,
            CommonStyless.flxBetween
          )}
        >
          <div className={classNames(CommonStyless.flx)}></div>
          <div className={CardStyless.searchBarContainer}>
            <MdOutlineYoutubeSearchedFor className={CardStyless.searchIcon} />
            <input
              type="text"
              placeholder="Search..."
              className={CardStyless.searchInput}
              value={searchValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          <div className={CardStyless.cardContainer}>
            {data?.map((card: any, index: any) => (
              <div key={index} className={CardStyless.cardWrapper}>
                <div className={CardStyless.cardImageWrapper}>
                  <img
                    src={card?.logo}
                    alt="card img"
                    className={CardStyless.cardImage}
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  >
                    <div
                      className={classNames(
                        CardStyless.cardName,
                        CommonStyless.colorBlue
                      )}
                    >
                      {card?.name}
                    </div>
                    <div>
                      <div>
                        <IoMdHeartEmpty
                          style={{
                            fontSize: 20,
                            color: "#0d47a1",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoTimeOutline />
                    </span>
                    <span>{card?.openTime}</span>
                  </div>

                  <div className={CardStyless.cardtime}>
                    <span className={CardStyless.timeIcon}>
                      <IoLocationOutline />
                    </span>
                    <span>{card?.location?.address}</span>
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <span className={CardStyless.cardRating}>*****</span>
                    <span className={CardStyless.cardRatingValue}>
                      {/* {card?.rating} */}
                    </span>
                    <span
                      className={classNames(
                        CommonStyless.flx,
                        CardStyless.viewMore
                      )}
                      onClick={() => handleViewMoreClick(card?._id)}
                    >
                      <Link to="#" className={CardStyless.cardViewMore}>
                        Details
                      </Link>
                      <span className={CardStyless.cardArrow}>
                        <IoMdArrowForward />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {data?.length >= 10 && (
            <div className={CardStyless.showMoreContainer}>
              <button className={CardStyless.showMoreButton}>
                Load More
                <span className={CardStyless.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default LaboratoryCards;
