import React from "react";
import classNames from "classnames";
import commonstyle from "shared/utils/common.module.css";
import style from "./Accept.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { CustomInput } from "shared/components";
import FileUpload from "shared/components/FileUpload/Fileupload";

const TravelAccept = () => {
  // Handle file submission logic
  const handleFileSubmit = (file: File) => {
    console.log("File submitted:", file);
    // Add your file submission logic here, e.g., sending to the server
  };

  // Handle file deletion logic
  const handleFileDelete = () => {
    console.log("File deleted");
    // Add your file deletion logic here
  };

  return (
    <div>
      <MainHeader mainHeading="Request" breadcrumb={["Home", "Request"]} />

      <div
        className={classNames(
          commonstyle.container,
          commonstyle.col7,
          commonstyle.mb32
        )}
      >
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <p
            className={classNames(
              commonstyle.colorBlue,
              commonstyle.semiBold,
              commonstyle.fs32
            )}
          >
            Traveler Identity
          </p>
          <p className={classNames(commonstyle.colorBlue, commonstyle.fs14)}>
            Total Traveler Information
            <span
              style={{
                margin: "0 10px",
              }}
            >
              0/4
            </span>
          </p>
        </div>

        <div className={classNames(style.maincontainer, commonstyle.colsm12)}>
          <div>
            <p
              className={classNames(
                commonstyle.fs14,
                commonstyle.colorBlue,
                commonstyle.semiBold
              )}
            >
              Person 1
            </p>
          </div>
          <div
            className={classNames(
              commonstyle.flx,
              commonstyle.flxBetween,
              commonstyle.flxWrap
            )}
          >
            <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
              <CustomInput placeholder="Name" />
            </div>
            <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
              <CustomInput placeholder="Passport Number" />
            </div>
          </div>

          <div className={classNames(commonstyle.flx, commonstyle.flxBetween)}>
            <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
              <p
                className={classNames(commonstyle.colorBlue, commonstyle.fs14,commonstyle.semiBold)}
              >
                Passport Image Upload
              </p>
              <FileUpload
                allowedFileTypes={[
                  "image/jpeg",
                  "image/png",
                  "application/pdf",
                ]}
                maxSizeInMB={10}
                onFileSubmit={handleFileSubmit}
                onFileDelete={handleFileDelete}
              />
            </div>
            <div className={classNames(commonstyle.col5, commonstyle.colsm12)}>
             <div className={classNames(commonstyle.flxBetween,commonstyle.flx,commonstyle.flxWrap)}>
             <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs14,
                  commonstyle.semiBold
                )}
              >
                Passport Image Upload
              </p>
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs14,
                  commonstyle.semiBold
                )}
              >
              For International
              </p>
             </div>
              <FileUpload
                allowedFileTypes={[
                  "image/jpeg",
                  "image/png",
                  "application/pdf",
                ]}
                maxSizeInMB={10}
                onFileSubmit={handleFileSubmit}
                onFileDelete={handleFileDelete}
              />
            </div>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default TravelAccept;
