import React from "react";
import InsuranceForm from "shared/components/Insuranceuser/Insuranceform";

const InsuranceTravel = () => {
  const handleSelectPlan = (selectedOption: string) => {
  };

  const insuranceOptions = ["Single Trip", "Multiple Trips"];

  return (
    <div>
      <InsuranceForm
        insuranceOptions={insuranceOptions}
        onSelectPlan={handleSelectPlan}
        title="Travel Insurance Plan"
        subtitle="Please Select Best Plan"
        formtitle="travel"
        id={1} 
        formType="travel" 
      />
    </div>
  );
};

export default InsuranceTravel;
