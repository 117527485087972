import React, { useMemo, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import classNames from "classnames";
import commonstyles from "shared/utils/common.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import style from "./BookingStyle.module.css";
import { MdDeleteOutline } from "react-icons/md"; // Import delete icon
import { IoMdArrowForward } from "react-icons/io";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setObj } from "shared/redux";
import toast from "react-hot-toast";
import moment from "moment";

interface InputData {
  label?: string;
  type: string;
  placeholder: string;
  readOnly: boolean;
  style?: React.CSSProperties;
}
const TravelBooking: React.FC = () => {
  const { user } = useSelector((state: any) => state.root.common);
  console.log(user, '......user.name')
  const [cnic, setCnic] = useState("");
  const handleCnicChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length > 5 && value.length <= 12) {
      value = `${value.slice(0, 5)}-${value.slice(5)}`;
    } else if (value.length > 12) {
      value = `${value.slice(0, 5)}-${value.slice(5, 12)}-${value.slice(12)}`;
    }
    setCnic(value);
  };
  const { state } = useLocation();
  let item = state.item;
  const dispatch = useDispatch();
  const [headCount, setHeadCount] = useState(0);
  const [coupleCount, setCoupleCount] = useState(0);
  const [error, setError] = useState<string | null>(null); // Add error state
  function calculateAge(dateOfBirthString: string) {
    if (!dateOfBirthString) return 'Date of birth not provided';
    const [day, month, year] = dateOfBirthString.split('/').map(Number);
    const dateOfBirth = new Date(year, month - 1, day);
    const ageDate = new Date(Date.now() - dateOfBirth.getTime());
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  const inputData: InputData[] = [
    { label: "Name", type: "text", placeholder: user.name, readOnly: true },
    { label: "Age", type: "text", placeholder: calculateAge(user.dateOfBirth), readOnly: true },
    {
      label: "Phone number",
      type: "text",
      placeholder: user.phone,
      readOnly: true,
    },
    {
      label: "Email",
      type: "text",
      placeholder: user.email,
      readOnly: true,
    },
    {
      label: "Address",
      type: "text",
      placeholder: user?.addresses[0]?.address,
      readOnly: true,
    },

  ];
  const navigate = useNavigate();
  const formatDepartDate = useMemo(
    () => moment(item?.departDate).format('DD/MM/YYYY'),
    [item?.departDate],
  );
  const formatReturnDate = useMemo(
    () => moment(item?.returnDate).format('DD/MM/YYYY'),
    [item?.returnDate],
  );
  const handlePayment = async () => {
    setError(null);  // Clear the error message when the user tries to proceed

    if (totalPersons === 0) {
      setError("Please select at least one person or couple.");
      return;
    } else if (totalPersons > item?.remainingSeats) {
      setError(`Only ${item?.remainingSeats} seats available.`);
      return;
    }

    let params: any = {
      tourId: item?._id,
    };
    let body: any = {
      agencyId: item?.agencyId,
      from: item?.from,
      to: item?.to,
      actualPrice: totalPrice,
      packageName: item?.packageName,
      totalUser: totalPersons,
      departDate: formatDepartDate,
      returnDate: formatReturnDate,
      age: calculateAge(user.dateOfBirth),
    };

    const payload = {
      params,
      body,
      item,
    };

    dispatch(setObj(payload));
    navigate("/services/paymentDetail", {
      state: { actualAmount: totalPrice, serviceName: 'tour' },
    });
  };

  const totalPersons = headCount + coupleCount * 2;
  const totalPrice =
    headCount * item.pricePerHead + coupleCount * item.pricePerCouple;
  return (
    <div>
      <MainHeader
        mainHeading="Travel Agencies"
        breadcrumb={["Home", "Services", "Travel Agencies", "Tour", "Details"]}
      />
      <div
        className={classNames(commonstyles.container)}
        style={{ marginBottom: "32px" }}
      >
        <div
          className={classNames(
            commonstyles.flx,
            commonstyles.flxBetween,
            commonstyles.flxWrap
          )}
        >
          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{ marginTop: "10px" }}
          >
            <div className={classNames(style.card)}>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs24,
                  commonstyles.semiBold
                )}
              >
                Your Info
              </p>
              {inputData.map((input, index) => (
                <div key={index}>
                  {input.label && (
                    <label className={style.label}>{input.label}</label>
                  )}
                  <input
                    className={style.input}
                    type={input.type}
                    placeholder={input.placeholder}
                    style={input.style || {}}
                    readOnly={input.readOnly}
                  />
                </div>
              ))}
              {/* <div>
                <label className={style.label}>CNIC</label>
                <input
                  className={style.input}
                  type="text"
                  value={cnic}
                  placeholder="33303-1234567-1"
                  onChange={handleCnicChange}
                  maxLength={15}
                />
              </div> */}
            </div>
          </div>

          <div
            className={classNames(commonstyles.col4, commonstyles.colsm12)}
            style={{ marginTop: "10px" }}
          >
            <div className={classNames(style.card)}>
              <p
                className={classNames(
                  commonstyles.colorBlue,
                  commonstyles.fs24,
                  commonstyles.semiBold
                )}
              >
                Total Seats
              </p>
              <div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >
                  <Incriment
                    value={headCount}
                    // restrict={'0'}
                    setError={setError}
                    name={'Per Head'}
                    price={item?.pricePerHead}
                    setValue={setHeadCount} />

                </div>
                <div
                  className={classNames(
                    commonstyles.flx,
                    commonstyles.flxBetween
                  )}
                >

                  <Incriment
                    value={coupleCount}
                    // restrict={'0'}
                    setError={setError}
                    name={'Per Couple'}
                    price={item?.pricePerCouple}
                    setValue={setCoupleCount} />
                </div>

                <hr
                  style={{
                    margin: "1rem 0",
                    color: "#DCDCDC",
                  }}
                />
              </div>
              <div className={classNames(style.totalAmount)}>
                <strong>PKR {totalPrice} . {totalPersons} Seats</strong>
              </div>
            </div>
            <div >
              {error && <p className={style.error} style={{ color: 'red' }} >{error}</p>} {/* Display error */}
            </div>
          </div>
        </div>

        <div className="">
          <div className={style.showMoreContainer}>
            <button onClick={handlePayment} className={style.showMoreButton}>
              Continue
              <span className={style.icon}>
                <IoMdArrowForward />
              </span>
            </button>
          </div>
        </div>
      </div>

      <Footerr />
    </div>
  );
};
interface Props {
  setValue?: any;
  value?: any;
  name?: any;
  price?: any;
  setError?: any;
}
const Incriment = (props: Props) => {
  const { setValue, value, name, price, setError } =
    props;

  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    if (value === 0) {
      setError(null);
    }
    setValue(value + 1);
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1);
      setCount(count - 1);
    }
  };
  return (
    <>
      <div>
        <p
          className={classNames(
            commonstyles.colorBlue,
            commonstyles.fs16,
            commonstyles.semiBold
          )}
        >
          {name}

        </p>
        <div>
          <p
            className={classNames(
              commonstyles.colorBlue,
              commonstyles.fs16,
              commonstyles.semiBold
            )}
          >
            <span>Rs.{price}</span> <span>/-</span>
          </p>
        </div>
      </div>
      <div
        className={classNames(
          commonstyles.flx,
          commonstyles.flxBetween
        )}
      >
        <div className={classNames(style.viewQuantitybox)}>
          <button
            className={style.decrementButton}
            onClick={handleDecrement}
          >
            -
          </button>
          <span className={style.quantity}>{value}</span>
          <button
            className={style.incrementButton}
            onClick={handleIncrement}
          >
            +
          </button>
        </div>
      </div>
    </>

  )
}
export default TravelBooking;
