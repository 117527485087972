import DonationPayment from "pages/Services/DonationServices/DonationPayment";
import TravelServices from "pages/Services/TravelAgency";
import TravelBooking from "pages/Services/TravelAgency/TravelBooking";
import TravelDetail from "pages/Services/TravelAgency/TravelDetail";
import React from "react";
import { Routes, Route } from "react-router-dom";

const TravelRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<TravelServices />} />
      <Route path="/TravelDetail" element={<TravelDetail />} />
      <Route path="/TravelBooking" element={<TravelBooking />} />
      <Route path="/Travelpayment" element={<DonationPayment />} />
      
    </Routes>
  );
};

export default TravelRoutes;
