import React, { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import style from "./Doctor.module.css";
import img from "assets/images/Avatar.png";
import { IoMdArrowForward } from "react-icons/io";
import Vector from "assets/images/Vector.png";
import detailStyle from "./Deatil.module.css";
import CardStyless from "./Cards.module.css";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import CommonStyless from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import classNames from "classnames";
import { CiClock2 } from "react-icons/ci";
// import DoctorServices from ".";
import { PiHouseThin } from "react-icons/pi";
import { CiCalendar } from "react-icons/ci";
import { CiVideoOn } from "react-icons/ci";
import Footerr from "pages/Home/HomeNavBar/Footer";
import { CiHospital1 } from "react-icons/ci";
import UserLoginmodal from "shared/components/UserLogin";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AvailabilityCard, LoadingModal, RingLoader } from "shared/components";
import { setObj } from "shared/redux";
import {
  getAvailability_Doctors,
  getSignle_Doctor,
} from "shared/services/UserService";
const DoctorDetail: React.FC = () => {
  const [visibleCards, setVisibleCards] = useState<number>(3);
  const [isModalVisible, setModalVisible] = useState(false);
  const [loading, setLoadig] = useState(false);
  const [data, setData] = useState<any>(null);
  const [docAvail, setDocAvail] = useState<any>(null);
  const { state }: any = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, hospitalId, user } = useSelector(
    (state: any) => state.root.common
  );

  console.log(".......user", user);

  let serviceName = state?.serviceName;
  let doc = state?.doc;

  const video_Availability = docAvail && docAvail[0]?.videoAvailability;
  const clinic_Availability = docAvail && docAvail[0]?.clinicAvailability;
  const inHouse_Availablity = docAvail && docAvail[0]?.inHouseAvailability;
  const hospital_Availablity = docAvail && docAvail[0]?.hospitalAvailability;

  const navigate = useNavigate();

  useEffect(() => {
    setLoadig(true);
    signle_Doctor();
    doctor_Availability();
  }, []);

  const signle_Doctor = () => {
    const params = {
      doctorId: doc?._id,
      type: serviceName == "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
      patientId: user?._id || "",
    };

    console.log("....params", params);

    getSignle_Doctor(params)
      .then((res: any) => {
        console.log("res......Doctor......", res?.data);
        setData(res?.data);
      })
      .catch((err: any) => {
        console.log("res......", err?.response?.data);
      });
  };

  const doctor_Availability = () => {
    const params = {
      doctorId: doc?._id,
      type: serviceName == "Hospital" ? "hospital" : "doctor",
      doctorType: serviceName?.toLowerCase(),
      hospitalId: state?.hospitalId || "",
    };
    getAvailability_Doctors(params)
      .then((res: any) => {
        setDocAvail(res?.data?.availability);
      })
      .catch((err: any) => {
        console.log("err......", err?.response);
      })
      .finally(() => setLoadig(false));
  };

  const handleViewMoreClick = async () => {
    if (isLoggedIn) {
      if (serviceName == "Hospital") {
        await dispatch(setObj(data?.doctor));
        navigate("/services/paymentDetail", {
          state: {
            serviceName,
            actualAmount: hospital_Availablity[0]?.price?.actualPrice,
            appointmentType: "hospital",
            doctorId: doc?._id,
          },
        });
      } else {
        navigate(`/services/doctor/DoctorAppoinmentPay/${doc?._id}`, {
          state: { serviceName, data },
        });
      }
    } else {
      navigate("/user/login");
    }
  };
  const handleButtonClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleShowMore = () => {
    setVisibleCards((prevCount) => prevCount + 3);
  };
  const reviews = [
    {
      name: "Muneeb Nasir",
      icon: <CiVideoOn />, // Use the video cam icon
      title: "Video consultancy",
      date: "5 months ago",
      review:
        "With my experience, I am glad to say that Dr. Ayesha is very honest and humble. She is a true human being, excellent for her, Masha Allah. I discussed overall skin problems, and she is very nice to explain everything. Thanks, Dr. Ayesha.",
    },

    {
      name: "John Doe",
      icon: <CiVideoOn />,
      title: "Video consultancy",
      date: "3 months ago",
      review:
        "Dr. Ayesha was very attentive and thorough during my consultation. I appreciate her expertise and willingness to take the time to explain everything.",
    },
    {
      name: "Jane Smith",
      icon: <CiVideoOn />,
      title: "Video consultancy",
      date: "1 month ago",
      review:
        "I had a great experience with Dr. Ayesha. She was professional and caring, addressing all my concerns effectively.",
    },
    {
      name: "Jane Smith",
      icon: <CiVideoOn />,
      title: "Video consultancy",
      date: "1 month ago",
      review:
        "I had a great experience with Dr. Ayesha. She was professional and caring, addressing all my concerns effectively.",
    },

    {
      name: "Jane Smith",
      icon: <CiVideoOn />,
      title: "Video consultancy",
      date: "1 month ago",
      review:
        "I had a great experience with Dr. Ayesha. She was professional and caring, addressing all my concerns effectively.",
    },
    {
      name: "Jane Smith",
      icon: <CiVideoOn />,
      title: "Video consultancy",
      date: "1 month ago",
      review:
        "I had a great experience with Dr. Ayesha. She was professional and caring, addressing all my concerns effectively.",
    },
  ];
  if (doc?._id === undefined) {
    return <div>Doctor ID is missing</div>;
  }

  const cardId = parseInt(doc?._id);

  if (isNaN(cardId)) {
    return <div>Invalid Doctor ID</div>;
  }

  const cardData = [
    {
      id: 0,
      name: "Savannah Nguyen",
      vector: Vector,
      title: "Pediatrics",
      detail: "MBBS(Pb), FRCP (UK), MD (USA), MACP",
      experience: "2 Years Experience",
      rating: "4.5",
    },
    {
      id: 1,
      name: "Jerome Bell",
      vector: Vector,
      title: "Cardiology",
      detail: "MBBS(Pb), FRCP (UK), MD (USA), MACP",
      experience: "5 Years Experience",
      rating: "4.7",
    },
    {
      id: 2,
      name: "Jerome Bell",
      vector: Vector,
      title: "Dermatology",
      detail: "MBBS(Pb), FRCP (UK), MD (USA), MACP...",
      experience: "8 Years Experience",
      rating: "4.8",
    },
    {
      id: 3,
      name: "Leslie Alexander",
      vector: Vector,
      title: "Neurology",
      detail: "MBBS(Pb), FRCP (UK), MD (USA), MACP...",
      experience: "10 Years Experience",
      rating: "4.9",
    },
  ];
  const card = doc;

  if (!card) {
    return <div>Doctor not found</div>;
  }

  return (
    <>
      <div>
        <div className={style.navIMG}>
          <NavBarr />
          <p
            className={classNames(
              CommonStyless.fs48,
              CommonStyless.semiBold,
              style.mianheading
            )}
          >
            {serviceName}
          </p>
          <div className={style.title}>
            <p
              className={classNames(
                CommonStyless.fs16,
                CommonStyless.semiBold,
                style.mianheading22
              )}
            >
              Home
            </p>
            <IoIosArrowForward
              className={classNames(CommonStyless.fs16, style.mianheading)}
            />
            <p
              className={classNames(
                CommonStyless.fs16,
                CommonStyless.semiBold,
                style.mianheading22
              )}
            >
              Services
            </p>
            <IoIosArrowForward
              className={classNames(CommonStyless.fs16, style.mianheading)}
            />
            <p
              className={classNames(
                CommonStyless.fs16,
                CommonStyless.semiBold,
                style.mianheading22
              )}
            >
              {serviceName}
            </p>
            <IoIosArrowForward
              className={classNames(CommonStyless.fs16, style.mianheading)}
            />
            <p
              className={classNames(
                CommonStyless.fs16,
                CommonStyless.semiBold,
                style.mianheading
              )}
            >
              Details
            </p>
          </div>
        </div>
      </div>
      {/* // main conatiner */}
      <div
        className={classNames(
          detailStyle.mainconatiner,
          CommonStyless.container
        )}
      >
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxBetween,
            detailStyle.conatiner
          )}
        >
          <div className="">
            <div className={CardStyless.cardWrapper}>
              <div className={CardStyless.cardImageWrapper}>
                <img
                  src={
                    data?.doctor?.doctorImage ||
                    doc?.doctorImage ||
                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                  }
                  alt={data?.doctor?.doctorImage}
                  className={CardStyless.cardImage}
                />
              </div>
              <div className={CardStyless.cardBody}>
                <div className={CommonStyless.flxBetween}>
                  <div className={CardStyless.cardName}>
                    {data?.name || doc?.name}
                  </div>
                  <div>
                    <img
                      src={Vector}
                      alt="Vector icon"
                      className={CardStyless.vectorIcon}
                    />
                  </div>
                </div>
                <div className={CardStyless.cardDetail}>
                  {data?.doctor?.qualifications || doc?.qualifications}
                </div>
                <div className={CardStyless.cardExperience}>
                  {data?.doctor?.clinicExperience || doc?.clinicExperience}
                </div>
                <div className={CardStyless.cardFooter}>
                  <span className={CardStyless.ratingContainer}>
                    <span className={CardStyless.cardRating}>*****</span>
                  </span>
                  <span className={CardStyless.cardRatingValue}>
                    {card.rating}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <button
                className={detailStyle.showMoreButton}
                onClick={handleViewMoreClick}
              >
                Appointment
                <span className={CardStyless.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
              <UserLoginmodal
                isVisible={isModalVisible}
                onClose={handleCloseModal}
              />
            </div>
          </div>
        </div>
        {/* box start */}

        {serviceName !== "Hospital" && (
          <div className={classNames(CommonStyless.mt24)}>
            <p className={classNames(CardStyless.cardName)}>Availability</p>
          </div>
        )}

        {clinic_Availability?.price?.actualPrice && clinic_Availability && (
          <AvailabilityCard
            subTitle="Clinic Availability"
            source={<CiHospital1 />}
            data={clinic_Availability}
          />
        )}
        {video_Availability?.price?.actualPrice && video_Availability && (
          <AvailabilityCard
            subTitle="Video Availability"
            source={<CiVideoOn />}
            data={video_Availability}
          />
        )}

        {inHouse_Availablity?.price?.actualPrice && inHouse_Availablity && (
          <AvailabilityCard
            source={<PiHouseThin />}
            data={inHouse_Availablity}
            subTitle="In-House Availability"
          />
        )}

        {hospital_Availablity &&
          hospital_Availablity?.map((item: any) => (
            <AvailabilityCard
              source={<CiHospital1 />}
              subTitle={item?.hospitalId?.name}
              data={item}
            />
          ))}

        {/* <div className={classNames(detailStyle.conatiner)}>
          <div
            className={classNames(CommonStyless.flx, CommonStyless.flxEvenly)}
          >
            <p
              className={classNames(
                detailStyle.reviews,
                CommonStyless.semiBold,
                CommonStyless.fs28
              )}
            >
              All Reviews About Wade Warren
            </p>
            <span className={classNames(CardStyless.cardRating)}>
              * <span className={classNames(detailStyle.reviews)}>199</span>
            </span>
          </div>

          <div className={classNames(CommonStyless.flxAround)}>
            <span className={classNames(detailStyle.ratingBox)}>4.5</span>
            <p className={classNames(detailStyle.reviews, CommonStyless.fs16)}>
              Average rating based on 5,661 reviews
            </p>
          </div>
        </div>
        <div
          className={classNames(
            detailStyle.cardContainer,
            CommonStyless.flx,
            CommonStyless.flxBetween
          )}
        >
          {reviews.slice(0, visibleCards).map((review, index) => (
            <div key={index} className={classNames(detailStyle.reviewcard)}>
              <div className={classNames(detailStyle.cardHeader)}>
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxBetween
                  )}
                >
                  <span
                    className={classNames(
                      CommonStyless.fs14,
                      CommonStyless.semiBold
                    )}
                  >
                    {review.name}
                  </span>
                  <span className={classNames(detailStyle.icon)}>
                    {review.icon}
                  </span>
                </div>
              </div>
              <span className={classNames(detailStyle.title)}>
                {review.title}
              </span>
              <div className={classNames(detailStyle.cardDate)}>
                <span>{review.date}</span>
              </div>
              <div className={classNames(detailStyle.cardBody)}>
                <p>{review.review}</p>
              </div>
            </div>
          ))}
          {visibleCards < cardData.length && (
            <div className={CardStyless.showMoreContainer}>
              <button
                onClick={handleShowMore}
                className={CardStyless.showMoreButton}
              >
                Show More
                <span className={CardStyless.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            </div>
          )}
        </div> */}
      </div>

      {/* {loading && <LoadingModal showModal={loading} />} */}

      <Footerr />
    </>
  );
};

export default DoctorDetail;

{
  /* <div className={detailStyle.scheduleWrapper}>
          {scheduleData.map((item, index) => (
            <div key={index}>
              <div className={detailStyle.header}>
                <div className={detailStyle.title}>
                  {item.icons.titleIcon}
                  <span>{item.title}</span>
                </div>
                <div className={detailStyle.price}>
                  <p>{item.price}</p>
                </div>
              </div>
              <hr />
              <div className={detailStyle.scheduleContainer}>
                {item.days.map((day, idx) => (
                  <div key={idx} className={detailStyle.card}>
                    <div className={detailStyle.cardHeader}>
                      {item.icons.dayIcon}
                      <span>{day.day}</span>
                    </div>
                    <div className={detailStyle.cardBody}>
                      {item.icons.timeIcon}
                      <span>{day.time}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div> */
}
