import React, { useEffect, useState } from "react";
import NavBarr from "../NavBarr";
import classNames from "classnames";
import style from "../AboutUs/aboutUs.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import styles from "./MyAppointmentRecord.module.css";
import Footerr from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar from "../../../../assets/logo.png";
import SumbitRecordFile from "../../../../assets/images/SubmitRecordFile.png";
import CheckoutButton from "../../../../assets/images/CheckoutButton.png";
import DownloadImagelogo from "../../../../assets/images/DownloadImagelogo.png";
import {
  add_File,
  getReferralDoctor,
  getUser_Laboratory,
  Preception_Details,
  saveUploadResulsUser,
} from "shared/services";
import { LoadingModal, PdfDownload } from "shared/components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setObj, setPaymentParams } from "shared/redux";
import toast from "react-hot-toast";
import jsPDF from "jspdf";

interface FileState {
  name: string;
}

const MyAppointmentPrescription = React.memo((props) => {
  const [showReferral, setShowReferral] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [showMedicines, setShowMedicines] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(false);

  const [showDiagnosticCenters, setShowDiagnosticCenters] = useState(false);
  const [data, setData] = useState<any>([]);
  const [labData, setLabData] = useState<any>([]);
  const [refer, setRefer] = useState<any>([]);
  const [itemPrices, setItemPrices] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { location } = useSelector((state: any) => state?.root?.common);

  useEffect(() => {
    document.title = "MediTour Global | About Us";
    window.scrollTo(0, 0);
    setLoading(true);
    getPatientData();
    referralDoctor();
  }, []);

  useEffect(() => {
    if (data?.ePrescription?.medicines) {
      const total = data?.ePrescription?.medicines?.reduce(
        (acc: any, item: any) => {
          const itemPrice = item?.quantity * item?.medicineId?.tpPrice || 0;
          return acc + itemPrice;
        },
        0
      );
      setItemPrices(total);
    }
  }, [data?.ePrescription?.medicines]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowMedicines(event.target.value === "yes");
  };

  const handleRadioChangeLab = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowDiagnosticCenters(event.target.value === "yes");
  };

  const handleDeleteFile = () => {
    setSelectedFile(null);
  };

  const Submit_File = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (selectedFile) {
        formData.append("file", selectedFile);
      }
      const response = await add_File(formData);
      saveTest(response?.data?.fileUrl);
      setLoading(false);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const saveTest = (url: any) => {
    let params = {
      resultUrl: url,
    };
    saveUploadResulsUser(state?.id, params)
      .then((res: any) => {
        toast.success(res?.data?.message);
      })
      .catch((err: any) => {
        toast.success(err?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPatientData = () => {
    let params = {
      appointmentId: state.id,
    };
    Preception_Details(params)
      .then(async (res: any) => {
        await setData(res?.data?.patientAppointments);
        getList();
      })
      .catch((err: any) => {
        console.log("err...", err.response);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getList = () => {
    let params = {
      page: 1,
      search: "",
      lat: location?.latitude,
      long: location?.longitude,
    };
    getUser_Laboratory(params)
      .then(async (res: any) => {
        await setLabData(res.data.labs);
      })
      .catch((err: any) => {
        console.log("err...", err);
      });
  };

  const medicineCheckout = () => {
    dispatch(setObj(data?.ePrescription?.medicines));
    navigate("/services/paymentDetail", {
      state: {
        serviceName: "prescription",
        actualAmount: itemPrices,
      },
    });
  };

  const handleLabClick = (id: any) => {
    navigate(`/services/laboratory/LabortoryDetail`, {
      state: { serviceName: "Lab", id: id },
    });
  };

  const referralDoctor = () => {
    let params = {
      appointmentId: state.id,
    };
    getReferralDoctor(params)
      .then((res: any) => {
        setRefer(res?.data?.referrals);
      })
      .catch((err: any) => {
        console.log(err?.response?.data, ".......err");
      })
      .finally(() => {});
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.text("Hello World!", 10, 10);
    doc.text(
      "This is a sample PDF document created using jsPDF in React.",
      10,
      20
    );
    doc.save("sample.pdf");
  };

  const handleDoctorClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName: "doctor", doc },
    });
  };

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          My Appointments
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            My Appointments
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            Prescription
          </p>
        </div>
      </div>
      <div></div>

      <div className={styles.containerPrescription}>
        <div className={styles.headerPrescription}>
          <img
            src={data?.doctorId?.doctorImage}
            alt="Dr. Wajahat Khan"
            className={styles.avatarPrescription}
          />
          <div className={styles.doctorInfoPrescription}>
            <div className={styles.nameAndDetails}>
              <h1>{data?.doctorId?.name}</h1>
              <div className={styles.timeAndCall}>
                <p className={styles.dateInfo}>
                  {moment(data?.appointmentDateAndTime).format(
                    "DD/MM/YYYY, hh:mm A"
                  )}
                </p>
              </div>
            </div>
            <div className={styles.professionalDetails}>
              <p>{data?.doctorId?.speciality}</p>
            </div>
            <p>{data?.doctorId?.qualifications}</p>
            <p>{data?.doctorId?.clinicName}</p>
          </div>
          <div>
            <h2 className={styles.PrescriptionDescription}>
              Download the Prescription
            </h2>
            <button
              className={styles.downloadButtonPrescription}
              onClick={generatePDF}
            >
              Download
              <img
                src={DownloadImagelogo}
                alt="Logo"
                className={styles.downloadButtonLogo}
              />
            </button>
          </div>
        </div>

        <div className={styles.bodyPrescription}>
          <div className={styles.sectionPrescriptionTop}>
            <div className={styles.sectionPrescription}>
              <h2>Symptoms</h2>
            </div>
            <div className={styles.tagsPrescription}>
              <span>{data?.history?.symptoms}</span>
            </div>
          </div>
          <div className={styles.sectionPrescription}>
            <h2>Medicine</h2>
            {data?.ePrescription?.medicines?.map((med: any) => (
              <p>{med?.medicineName}</p>
            ))}
          </div>
          <div className={styles.sectionPrescription}>
            <h2>Test</h2>
            {data?.ePrescription?.test?.map((test: any) => (
              <p>{test?.testName}</p>
            ))}
            <div className={styles.radioGroupPrescription}>
              <div className={styles.recordPrescriptionQ}>
                <h2>
                  Do you want to buy medicine from{" "}
                  <span className={styles.mediTourText}>MediTour?</span>
                </h2>
                <div>
                  <div className={styles.radioGroupPrescriptionInside}>
                    <label className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="buyMedicine"
                        value="yes"
                        onChange={handleRadioChange}
                        checked={showMedicines}
                      />{" "}
                      Yes
                    </label>
                    <label className={styles.radioLabel}>
                      <input
                        type="radio"
                        name="buyMedicine"
                        value="no"
                        onChange={handleRadioChange}
                        checked={!showMedicines}
                      />{" "}
                      No
                    </label>
                  </div>
                  {showMedicines && (
                    <TestList
                      data={data}
                      itemPrices={itemPrices}
                      medicineCheckout={medicineCheckout}
                    />
                  )}
                </div>
              </div>

              <div className={styles.recordPrescriptionQ}>
                <h2>
                  Would you like to get your test done at{" "}
                  <span className={styles.mediTourText}>MediTour?</span>
                </h2>
                <div className={styles.radioGroupPrescriptionInside}>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name="testDone"
                      value="yes"
                      onChange={handleRadioChangeLab}
                      checked={showDiagnosticCenters}
                    />{" "}
                    Yes
                  </label>
                  <label className={styles.radioLabel}>
                    <input
                      type="radio"
                      name="testDone"
                      value="no"
                      onChange={handleRadioChangeLab}
                      checked={!showDiagnosticCenters}
                    />{" "}
                    No
                  </label>
                </div>
              </div>
              {showDiagnosticCenters && (
                <div className={styles.diagnosticCenterList}>
                  {labData?.map((lab: any, index: any) => (
                    <div
                      key={index}
                      className={styles.diagnosticCard}
                      onClick={() => handleLabClick(lab?._id)}
                    >
                      <img
                        src={lab?.logo || Avatar}
                        alt="Lab Logo"
                        className={styles.labLogo}
                      />

                      <div className={styles.labDetails}>
                        <p>
                          <strong>{lab?.name}</strong>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {!showDiagnosticCenters && (
            <div className={styles.sectionPrescription}>
              <div className={styles.sectionPrescription}>
                <h2>Test Result File</h2>
                <p>When you get test file please upload here</p>
                <div className={styles.fileUploadContainer}>
                  {!selectedFile && (
                    <label htmlFor="fileInput" className={styles.uploadArea}>
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <div className={styles.uploadIcon}></div>
                      Drag & Drop or choose file to upload
                      <p>Select zip, image, pdf or ms.word</p>
                      <p>File Size 10 MB</p>
                    </label>
                  )}
                  {selectedFile && (
                    <div className={styles.fileDetails}>
                      <span>{selectedFile.name} - 92 kb - Complete</span>
                      <button
                        onClick={handleDeleteFile}
                        className={styles.removeFileButton}
                      >
                        &times;
                      </button>
                    </div>
                  )}
                  {selectedFile && (
                    <button
                      onClick={Submit_File}
                      className={styles.submitButton}
                    >
                      <img src={SumbitRecordFile} alt="Submit File" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          {refer && (
            <div className={styles.sectionPrescription}>
              <h2>I referred you to someone else.</h2>
              <button
                onClick={() => setShowReferral(!showReferral)}
                className={styles.loadMoreButtonRecordPrescription}
              >
                Show Referral
              </button>
              {showReferral &&
                refer?.map((ref: any, index: any) => (
                  <div
                    className={styles.referralDetail}
                    key={index}
                    onClick={() => handleDoctorClick(ref?.doctorId)}
                  >
                    <img
                      src={ref?.doctorId?.doctorImage || Avatar}
                      alt="Annette Black"
                      className={styles.referralAvatar}
                    />
                    <div className={styles.referralInfo}>
                      <strong>{ref?.doctorId?.name}</strong>
                      <p>{ref?.doctorId?.speciality}</p>
                      <p>{ref?.doctorId?.qualifications}</p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingModal showModal={loading} />}
      <Footerr />
    </div>
  );
});

const TestList = ({
  data,
  itemPrices,
  medicineCheckout,
}: {
  data: any;
  itemPrices: any;
  medicineCheckout: any;
}) => {
  return (
    <div className={styles.medicineListContainer}>
      <div className={styles.medicineList}>
        {data?.ePrescription?.medicines.map((item: any, index: any) => {
          return (
            <div key={index} className={styles.medicineCard}>
              <p>
                <strong>Name:</strong> {item?.medicineId?.productName}
              </p>

              <p>
                <strong>Generic:</strong> {item?.medicineId?.generic}
              </p>
              <p>
                <strong>Days:</strong> {item?.days}
              </p>
              <p>
                <strong>Quantity:</strong> {item?.quantity}
              </p>
              <p>
                <strong>Price:</strong> {item?.medicineId?.tpPrice}/-
              </p>
            </div>
          );
        })}
      </div>
      <div className={styles.totalPrice}>
        <span>Total Price: {itemPrices}/-</span>
        <button className={styles.checkoutButton} onClick={medicineCheckout}>
          <img src={CheckoutButton} alt="Proceed to checkout" />
        </button>
      </div>
    </div>
  );
};

export default MyAppointmentPrescription;
