import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import style from "./Doctor.module.css";
import commonstyles from "shared/utils/common.module.css";
import { IoIosArrowForward } from "react-icons/io";
import NavBarr from "pages/Home/HomeNavBar/NavBarr";
import Footerr from "pages/Home/HomeNavBar/Footer";
import DoctorCards from "./DoctorCards";
import { MainHospitalCard } from "shared/components";

const DoctorServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Use params instead of state for service identification

  const serviceName =
    location.state?.serviceName || (id === "hospital" ? "Hospital" : "Doctor");

  useEffect(() => {
    if (!location.state?.serviceName && !id) {
      console.error("Service name or id is missing");
      navigate("/services/doctor");
    }
  }, [location.state, id, navigate]);

  return (
    <div>
      <div className={style.navIMG}>
        <NavBarr />
        <p
          className={classNames(
            commonstyles.fs48,
            commonstyles.semiBold,
            style.mianheading
          )}
        >
          {serviceName}
        </p>
        <div className={style.title}>
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Home
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading22
            )}
          >
            Services
          </p>
          <IoIosArrowForward
            className={classNames(commonstyles.fs16, style.mianheading)}
          />
          <p
            className={classNames(
              commonstyles.fs16,
              commonstyles.semiBold,
              style.mianheading
            )}
          >
            {serviceName}
          </p>
        </div>
        {serviceName === "Hospital" ? (
          <div>
            <MainHospitalCard serviceName={serviceName} />
          </div>
        ) : (
          <DoctorCards serviceName={serviceName} />
        )}
        <Footerr />
      </div>
    </div>
  );
};

export default DoctorServices;
