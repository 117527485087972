import React, { useEffect, useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./Style.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import img1 from "assets/images/protection.png";
import img2 from "assets/images/travel-insurance.png";
import trust from "assets/images/trust 1.png";
import InsuranceHealth from "./Insurancehealth/InsuranceHealth";
import Insurancetravel from "./Insurancetravel/Insurancetravel";

type ShowState = "travel" | "health" | null;

const InsuranceServices: React.FC = () => {
  const [show, setShow] = useState<ShowState>(null);

  const handleShow = (type: ShowState) => {
    setShow(type);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <MainHeader
        mainHeading="Insurance"
        breadcrumb={["Home", "Services", "Insurance"]}
      />
      <div className={classNames(commonstyle.container)}>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxCenter,
            commonstyle.flxCol
          )}
        >
          <div>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs36,
                commonstyle.semiBold
              )}
            >
              Find the best insurance
            </p>
          </div>
          <div>
            <p
              className={classNames(
                commonstyle.colorBlue,
                commonstyle.fs14,
                commonstyle.semiBold
              )}
            >
              Protecting What Matters Most.
            </p>
          </div>

          {!show && (
            <>
              <div
                className={classNames(commonstyle.flx, commonstyle.flxBetween)}
              >
                <div
                  className={classNames(style.imgcontainer)}
                  onClick={() => handleShow("travel")}
                >
                  <img
                    src={img1}
                    alt="Travel Insurance"
                    className={classNames(style.cardimg)}
                  />
                  <div className={classNames(style.textOverlay)}>Travel</div>
                </div>
                <div
                  className={classNames(style.imgcontainer)}
                  onClick={() => handleShow("health")}
                >
                  <img
                    src={img2}
                    alt="Health Insurance"
                    className={classNames(style.cardimg)}
                  />
                  <div className={classNames(style.textOverlay)}>Health</div>
                </div>
              </div>

              <div
                className={classNames(
                  commonstyle.flx,
                  commonstyle.flxBetween,
                  commonstyle.flxWrap,
                  commonstyle.mt16
                )}
              >
                <div
                  className={classNames(commonstyle.col5, commonstyle.colsm12)}
                >
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs36,
                      commonstyle.semiBold
                    )}
                  >
                    Providing the best insurance in Health & Travel
                  </p>
                </div>
                <div
                  className={classNames(commonstyle.col5, commonstyle.colsm12)}
                >
                  <div
                    className={classNames(style.barcontainer, commonstyle.flx)}
                  >
                    <div>
                      <img src={trust} alt="Trust" className={style.trustimg} />
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.colorBlue,
                          commonstyle.fs14,
                          commonstyle.semiBold
                        )}
                      >
                        More than 15 insurance companies that partner with us
                      </p>
                    </div>
                  </div>

                  <div
                    className={classNames(style.barcontainer, commonstyle.flx)}
                  >
                    <div>
                      <img src={trust} alt="Trust" className={style.trustimg} />
                    </div>
                    <div>
                      <p
                        className={classNames(
                          commonstyle.colorBlue,
                          commonstyle.fs14,
                          commonstyle.semiBold
                        )}
                      >
                        Trusted by 10,000+ valued customers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {show === "travel" && <Insurancetravel />}
        {show === "health" && <InsuranceHealth />}
      </div>

      <Footerr />
    </div>
  );
};

export default InsuranceServices;
