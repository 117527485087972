import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import style from "./Details.module.css";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import Footer from "pages/Home/HomeNavBar/Footer";
import { IoMdArrowForward } from "react-icons/io";
import { LoadingModal } from "shared/components";
import { getInsuranceDetails } from "shared/services";
import InsuranceGeneric from "shared/components/InsuranceGeneric";
const InsuranceDetails = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const { formType, item, passengerType, selectedPlan } = location.state || {};
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false)
 


  useEffect(() => {
    get_Insurance();
  }, []);

  const get_Insurance = () => {
    setLoading(true);
    let params: any = {
      insuranceId: item?._id,
      type: formType === 'health' ? selectedPlan?.toLowerCase() : passengerType?.toLowerCase()
    };
    console.log(params, '.......v')
    getInsuranceDetails(params)
      .then((res: any) => {
        console.log(res.data.insurance, '.......res.data.insurance')
        setData(res.data.insurance);
      })
      .catch((err: any) => {
        console.log(err, '.......error');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGoTo = () => {
    navigate("/services/insurance/Insurancepay",{state:{data,selectedPlan,passengerType,formType}});
  };





  return (
    <>
      <MainHeader
        mainHeading="Insurance"
        breadcrumb={["Home", "Services", "Insurance", "Detail"]}
      />
      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <p
          className={classNames(
            commonstyle.colorBlue,
            commonstyle.fs24,
            commonstyle.semiBold
          )}
          style={{ marginBottom: '16PX' }}
        >
          {item?.packageName}
        </p>

        {formType === 'travel' ? (
          <InsuranceGeneric
           data={data} 
           value1={'Medical Cover'}
          labl1={data?.medicalCover}
          value2={'Flight Delay'}
          lable2={data?.flightDelay}
          value3={'Cover upto'}
          lable3={data?.coveringUpto}
          value4={'Loss of Baggage'}
          lable4={data?.baggageLoss}
          value5={'Loss of PassPort'}
          lable5={data?.baggageLoss}
          tab1={'Medical Benefits'}
          tab2={'Policy Documents'}
          tab3={'Travel Benefits'}
          m1={'Accidental Death & Disability'}
          m1Value={data?.adndCoverage}
          m2={'Medical Expenses & Hospitalization'}
          m2Value={data?.medExpensesHospitalizationCoverage}
          m3={'Repatriation of Mortal Remains'}
          m3Value={data?.repatriationCoverage}
          m4={'Emergencies Return Home'}
          m4Value={data?.emergencyReturnHomeCoverage}
        />) : (
          <InsuranceGeneric data={data} value1={'Hospitalization Cover'}
          labl1={`${data?.hospitalizationLimit?.startLimit}-${data?.hospitalizationLimit?.endLimit}`}
          value3={'Claim Payout Ratio'}
          lable3={data?.claimPayoutRatio}
          value5={'Daily Room & Board Limit'}
          lable5={data?.dailyRoomAndBoardLimit}
          tab1={'Medical Benefits'}
          tab2={'Policy Documents'}
          tab3={'Claim Process'}
          m1={'ICU / CCU'}
          m1Value={data?.icuCcuLimits}
          m2={'Coverage of Specialized Investigation'}
          m2Value={data?.ambulanceCoverage}
          m3={'Ambulance Services Coverage'}
          m3Value={data?.ambulanceCoverage}
          m4={'Additional Limits for Accidental Emergencies'}
          showtab
          m4Value={data?.accidentalEmergencyLimits}
          tab4={'More Features'}

        />

        )}
        <div>
          <div
            className={style.showMoreContainer}>
            <div
              className={classNames(commonstyle.flx)}
              style={{ margin: "0 10px" }}
            >
              <p
                className={classNames(
                  commonstyle.colorBlue,
                  commonstyle.fs32,
                  commonstyle.semiBold
                )}
              >
                Price {data?.actualPrice}
              </p>
            </div>
            <div
              className={classNames(commonstyle.flx)}
              style={{ margin: "0 10px" }}
            >
              <button
                onClick={handleGoTo}
                className={style.showMoreButton}
              >
                Buy
                <span className={style.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <LoadingModal showModal={loading} />}
      <Footer />
    </>
  );
};

export default InsuranceDetails;
