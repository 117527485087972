import React, { useState } from "react";
import MainHeader from "shared/components/MainScreen/Index";
import commonstyle from "shared/utils/common.module.css";
import classNames from "classnames";
import style from "./DetailStyle.module.css";
import Footerr from "pages/Home/HomeNavBar/Footer";
import Fly from "assets/images/flys.png";
import { BiSolidPlaneAlt } from "react-icons/bi";
import { FaDownload } from "react-icons/fa6";
import ThankyouModel from "shared/components/ThankyouModel";

const flightsData = [
  {
    flightName: "Fly Emirates 104",
    imgSrc: Fly,
    departureCity: "Lahore",
    departureTime: "16:30",
    arrivalCity: "Dubai",
    arrivalTime: "18:30",
    travelTime: "16:00",
    date: "20 Dec, Wed",
    amenities: [
      "Winglets",
      "Basic Web Browsing (Free)",
      "Streaming Entertainment",
      "Light meal",
    ],
    baggageDetails: {
      handbags: 2,
      baggageWeight: "20 KG",
    },
  },
  {
    flightName: "Fly Emirates 104",
    imgSrc: Fly,
    departureCity: "Lahore",
    departureTime: "16:30",
    arrivalCity: "Dubai",
    arrivalTime: "18:30",
    travelTime: "16:00",
    date: "20 Dec, Wed",
    amenities: [
      "Winglets",
      "Basic Web Browsing (Free)",
      "Streaming Entertainment",
      "Light meal",
    ],
    baggageDetails: {
      handbags: 2,
      baggageWeight: "20 KG",
    },
  },
];
const BookingTravelDetail = () => {
  const [showModal, setShowModal] = useState(false);

  const handleFileClick = () => {
    setShowModal(true);
  };
  return (
    <div>
      <MainHeader
        mainHeading="Travel Agency"
        breadcrumb={["Home", "My Booking", "Details"]}
      />

      <div className={classNames(commonstyle.container, commonstyle.mb32)}>
        <div
          className={classNames(
            commonstyle.flx,
            commonstyle.flxBetween,
            commonstyle.flxWrap
          )}
        >
          <div
            className={classNames(
              style.mainConatiner,
              commonstyle.col5,
              commonstyle.colsm12
            )}
            style={{ marginBottom: "20px" }}
          >
            {flightsData.map((flight, index) => (
              <>
                <div key={index} className={classNames(style.flightcontainer)}>
                  <div className={classNames(style.imgconatiner)}>
                    <img src={flight.imgSrc} alt="" className={style.flyimg} />
                    <p
                      className={classNames(
                        commonstyle.fs16,
                        commonstyle.colorBlue
                      )}
                    >
                      {flight.flightName}
                    </p>
                  </div>
                  <div
                    className={classNames(
                      commonstyle.flx,
                      commonstyle.flxCenter
                    )}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "90%",
                        position: "relative",
                        color: "#0e54a3",
                      }}
                    >
                      {/* Left City */}
                      <div style={{ marginRight: "10px", fontSize: "18px" }}>
                        <p>{flight.departureCity}</p>
                        <p>{flight.departureTime}</p>
                      </div>

                      {/* Plane Icon and Time */}
                      <div
                        style={{
                          position: "relative",
                          flexGrow: 1,
                          height: "1px",
                          borderTop: "2px dotted #0e54a3",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <BiSolidPlaneAlt
                          style={{
                            position: "absolute",
                            top: "-33px",
                            fontSize: "24px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "15px", // Position below the dotted line
                            fontSize: "14px",
                            color: "#0e54a3",
                          }}
                        >
                          {flight.travelTime}
                        </div>
                      </div>

                      {/* Right City */}
                      <div style={{ marginLeft: "10px", fontSize: "18px" }}>
                        <p>{flight.arrivalCity}</p>
                        <p>{flight.arrivalTime}</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className={classNames(
                        commonstyle.flx,
                        commonstyle.flxBetween
                      )}
                    >
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold
                          )}
                        >
                          {flight.departureCity}
                        </p>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs14
                          )}
                        >
                          {flight.date}
                        </p>
                      </div>
                      <div>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs16,
                            commonstyle.semiBold,
                            commonstyle.flx,
                            commonstyle.flxEnd
                          )}
                        >
                          {flight.arrivalCity}
                        </p>
                        <p
                          className={classNames(
                            commonstyle.colorBlue,
                            commonstyle.fs14
                          )}
                        >
                          {flight.date}
                        </p>
                      </div>
                    </div>
                    <div
                      style={{
                        margin: "10px 0",
                      }}
                    >
                      <p
                        className={classNames(
                          commonstyle.fs16,
                          commonstyle.colorBlue,
                          commonstyle.semiBold
                        )}
                      >
                        Amenities
                      </p>
                    </div>
                    <div>
                      {flight.amenities.map((amenity, i) => (
                        <p
                          key={i}
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {amenity}
                        </p>
                      ))}
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        No. of Handbag:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight.baggageDetails.handbags}
                        </span>
                      </p>
                      <p
                        className={classNames(
                          commonstyle.fs14,
                          commonstyle.colorGray
                        )}
                      >
                        Baggage Weight:{" "}
                        <span
                          className={classNames(
                            commonstyle.fs14,
                            commonstyle.colorBlue
                          )}
                        >
                          {flight.baggageDetails.baggageWeight}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    position: "relative",
                    flexGrow: 1,
                    height: "1px",
                    borderTop: "2px dotted #0e54a3",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "15px 0",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      padding: "0 10px",
                      fontSize: "14px",
                      color: "#0e54a3",
                      backgroundColor: "#FFFFFF",
                      zIndex: 1,
                      borderRadius: "6px",
                    }}
                  >
                    Stay 1 hr 20 m in Karachi
                  </div>
                </div>
              </>
            ))}
          </div>
          <div
            className={classNames(
              style.mainConatiner,
              commonstyle.col5,
              commonstyle.colsm12
            )}
          >
            <div className={style.policyDetails}>
              <p
                className={classNames(commonstyle.colorBlue, commonstyle.fs16)}
              >
                Insurance Duration: 1 year
              </p>
              <p
                className={classNames(commonstyle.colorBlue, commonstyle.fs16)}
              >
                Total Amount: 12,504
              </p>

              <div className={style.downloadSection}>
                <label>
                  <p
                    className={classNames(
                      commonstyle.colorBlue,
                      commonstyle.fs16
                    )}
                  >
                    Insurance File
                  </p>
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "90%",
                    fontSize: "14px",
                    color: "#0e54a3",
                    backgroundColor: "#E3EBED",
                    padding: "5px",
                    border: "1px dotted #0e54a3",
                    borderRadius: "8px",
                    cursor: "pointer",
                  }}
                  onClick={handleFileClick}
                >
                  <p>file name.pdf</p>
                  <FaDownload />
                </div>
              </div>
            </div>
          </div>

          {showModal && (
            <ThankyouModel
              mainMessageLine1="Your File has been"
              mainMessageLine2="Successfully Downloaded"
            />
          )}
        </div>
      </div>

      <Footerr />
    </div>
  );
};

export default BookingTravelDetail;
