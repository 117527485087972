import classNames from "classnames";
import { cityData } from "shared/utils";
import React, { useEffect, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import CardStyless from "./Cards.module.css";
import CommonStyless from "shared/utils/common.module.css";
import { MdOutlineYoutubeSearchedFor } from "react-icons/md";
import Vector from "assets/images/Vector.png";
import { Link, useNavigate } from "react-router-dom";
import dstyle from "../DonationServices/DonationPackeg.module.css";
import { IoClose } from "react-icons/io5";
import RatingStar from "shared/RatingStar";
import { getAll_Doctors } from "shared/services/UserService";
import { LoadingModal } from "shared/components";

const DoctorCards: React.FC<{ serviceName: string }> = ({ serviceName }) => {
  const navigate = useNavigate();
  const [modelVisible, setModelVisible] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("ALL");
  const [doctors, setDoctors] = useState<any>([]);
  const [applyCity, setApplyCity] = useState<any>(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
    error: null,
  });

  useEffect(() => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            error: null,
          });
        },
        (error) => {
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  }, []);

  useEffect(() => {
    fetch_All_Doc();
  }, [activeTab, applyCity]);

  const fetch_All_Doc = async () => {
    let params = {
      doctorType:
        serviceName == "Paramedic Staff"
          ? "paramedic"
          : serviceName.toLowerCase(),
      filter:
        activeTab == "Near by me"
          ? "nearby"
          : activeTab == "Search by city"
          ? "city"
          : activeTab?.toLowerCase(),
      page: 1,
      lat: activeTab == "Near by me" ? location?.latitude : "",
      long: activeTab == "Near by me" ? location?.longitude : "",
      search: searchValue,
      city: activeTab == "Search by city" ? selectedIndex : "",
      speciality: "",
    };
    try {
      const res = await getAll_Doctors(params);
      console.log("......res?.data?.doctors", res?.data?.doctors);
      setDoctors(res?.data?.doctors);
    } catch (err: any) {
      console.log("err all doctor Api......", err?.response);
    } finally {
      setLoading(false);
      setApplyCity(false);
    }
  };

  const handleViewMoreClick = (doc: any) => {
    navigate(`/services/doctor/DoctorDetail`, {
      state: { serviceName, doc },
    });
  };

  const handleTabClick = (tab: string) => {
    if (tab == "Search by city") {
      setModelVisible(true);
    }
    setActiveTab(tab);
  };

  const handleShowMore = () => {
    console.log("For pagination");
  };

  const handleClick = (index: any) => {
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setModelVisible(false);
  };

  const handleApply = () => {
    setApplyCity(true);
    setModelVisible(false);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    console.log("Search Input Value:", value); // Log the search input value
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      fetch_All_Doc(); // Fetch the list when Enter is pressed
    }
  };

  const filteredCities = cityData?.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={classNames(CommonStyless.container)}>
      <div
        className={CommonStyless.mt32}
        style={{ backgroundColor: "#FDFDFD" }}
      >
        <div
          className={classNames(
            CommonStyless.flx,
            CommonStyless.flxWrap,
            CommonStyless.flxBetween,
            CardStyless.main
          )}
        >
          <div
            className={classNames(CardStyless.tabContainer, CommonStyless.flx)}
          >
            {["ALL", "Near by me", "Search by city", "Recommended"].map(
              (tab) => (
                <button
                  key={tab}
                  className={classNames(
                    CardStyless.tab,
                    activeTab === tab && CardStyless.activeTab
                  )}
                  onClick={() => handleTabClick(tab)}
                >
                  {tab}
                </button>
              )
            )}
          </div>

          {modelVisible && (
            <div className={classNames(dstyle.modal)}>
              <div className={classNames(dstyle.drmodalContent)}>
                <div
                  className={classNames(
                    CommonStyless.flx,
                    CommonStyless.flxBetween
                  )}
                >
                  <p
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  >
                    Search by city
                  </p>
                  <IoClose
                    onClick={handleClose}
                    className={classNames(
                      CommonStyless.colorBlue,
                      CommonStyless.fs24,
                      CommonStyless.semiB
                    )}
                  />
                </div>
                <div>
                  <input
                    style={{
                      width: "100%",
                      border: "none",
                      borderBottom: "2px solid black",
                      outline: "none",
                      padding: "8px",
                      boxSizing: "border-box",
                    }}
                    placeholder="Search here"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>

                <ul>
                  {filteredCities?.map((item, index) => {
                    const isSelected = item === selectedIndex;
                    return (
                      <li
                        key={index}
                        onClick={() => handleClick(item)}
                        style={{
                          color: isSelected ? "white" : "#1e5da3",
                          backgroundColor: isSelected
                            ? "#1e5da3"
                            : "transparent",
                          padding: "10px",
                          cursor: "pointer",
                          transition: "background-color 0.3s",
                        }}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
                <div className={dstyle.showMoreContainer}>
                  <button
                    onClick={() => {
                      handleApply();
                      // handleShowMore();
                      // handleContinueClick();
                    }}
                    className={dstyle.showMoreButton}
                    style={{ marginTop: "10px" }} // Add margin if needed
                  >
                    Apply
                    <span className={dstyle.icon}>
                      <IoMdArrowForward />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className={CardStyless.searchBarContainer}>
            <MdOutlineYoutubeSearchedFor className={CardStyless.searchIcon} />
            <input
              type="text"
              placeholder="Search...."
              className={CardStyless.searchInput}
              value={searchValue} // Controlled input
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            />
          </div>
        </div>
        <div className={classNames(CommonStyless.mb28, CommonStyless.mt28)}>
          {/* {activeTab === "ALL" ||
            (activeTab == "Near by me" && ( */}
          <div className={CardStyless.cardContainer}>
            {doctors?.map((doc: any, index: number) => (
              <div key={index} className={CardStyless.cardWrapper}>
                <div
                  className={CardStyless.cardImageWrapper}
                  style={{
                    backgroundColor: "gray",
                  }}
                >
                  <img
                    src={
                      doc?.doctorImage ||
                      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0t6_9TNVL-wX1SZhfif9Umhe3kfz7OluS6QmspjqUeGmzhG_18svSJIFvxXmaXEl1Z3I&usqp=CAU"
                    }
                    alt={doc?.name}
                    className={CardStyless.cardImage}
                    style={
                      {
                        // backgroundColor: "black",
                      }
                    }
                  />
                </div>
                <div className={CardStyless.cardBody}>
                  <div
                    className={classNames(
                      CommonStyless.flx,
                      CommonStyless.flxBetween
                    )}
                  >
                    <div
                      className={classNames(
                        CardStyless.cardName,
                        CommonStyless.colorBlue
                      )}
                    >
                      {doc?.name}
                    </div>
                    <div>
                      <img
                        src={Vector}
                        alt="Vector icon"
                        className={CardStyless.vectorIcon}
                      />
                    </div>
                  </div>
                  <div className={classNames(CommonStyless.flx)}>
                    {doc?.speciality?.map((s: string, ind: number) => (
                      <div key={ind} className={CardStyless.cardTitle}>
                        {s},&nbsp;
                      </div>
                    ))}
                  </div>

                  <div className={CardStyless.cardDetail}>
                    {doc?.qualifications}
                  </div>
                  <div className={CardStyless.cardExperience}>
                    {doc?.clinicExperience}
                  </div>
                  <div className={CardStyless.cardFooter}>
                    <RatingStar rating={4} />
                    <span className={CardStyless.cardRatingValue}>
                      {doc.rating}
                    </span>
                    <span
                      className={classNames(
                        CommonStyless.flx,
                        CardStyless.viewMore
                      )}
                      onClick={() => handleViewMoreClick(doc)}
                    >
                      <Link to="#" className={CardStyless.cardViewMore}>
                        Details
                      </Link>
                      <span className={CardStyless.cardArrow}>
                        <IoMdArrowForward />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* ))} */}
          {doctors?.length >= 10 && (
            <div className={CardStyless.showMoreContainer}>
              <button
                onClick={handleShowMore}
                className={CardStyless.showMoreButton}
              >
                Show More
                <span className={CardStyless.icon}>
                  <IoMdArrowForward />
                </span>
              </button>
            </div>
          )}
          {/* {activeTab === "Near by me" && <div>Services Near by Me</div>}
          {activeTab === "Search by city" && <div>Search by City</div>}
          {activeTab === "Recommended" && <div>Recommended Services</div>} */}
        </div>
      </div>
      {loading && <LoadingModal showModal={loading} />}
    </div>
  );
};

export default DoctorCards;
